<div class="navigation-bar" [class.is-open]="isNavbarOpen">
  <div class="navigation-bar__group-menu" [ngClass]="{ 'padding-right-0 padding-left-0': isNavbarOpen }">
    <div class="width-100" [class.padding-left]="!isNavbarOpen && isMobileView">
      <div class="navigation-bar__panel" [class.position-relative]="isNavbarOpen">
        <div class="navigation-bar__wrapper">
          <button class="navigation-bar__toggle-button" (click)="toggleNavbar()">
            <i class="icon-burger"></i>
          </button>
          <div class="navigation-bar__app-identifier" [translate]="'LIFENET_INSTANCE'"></div>
        </div>
      </div>
      <div [class.mobile-view-layout]="isNavbarOpen">
        @if (arePermissionsLoaded && (!isMobileView || isNavbarOpen)) {
        <hl-tabs
          [noMarginBottom]="true"
          [navigationMenu]="true"
          [moreEnabled]="!isMobileView"
          [moreAdditionalStyle]="'navbar-menu-font-size'"
          [burgerMenuMobileLayout]="true"
          [isBurgerMenuOpen]="isNavbarOpen"
          data-cy="navigation-bar">
          @if (authorization.showDashboardTab | async) {
          <a
            class="tabs__item navbar-menu-font-size"
            [routerLink]="['/dashboard']"
            [class.is-active]="currentStateName === 'dashboard'"
            [translate]="'MY_DASHBOARD'"
            data-cy="dashboard-link"
            role="presentation"
            href="#"></a>
          } @if (authorization.showEquipmentTab | async) {
          <a
            class="tabs__item navbar-menu-font-size"
            [routerLink]="['/equipment']"
            [class.is-active]="currentBaseStateName === 'equipment'"
            [translate]="'ALL_SIEMENS_EQUIPMENTS'"
            data-cy="equipment-link"
            role="presentation"></a>
          }
          <hl-tabs
            class="tabs__item navbar-menu-font-size padding-0"
            data-cy="main-menu-service-group"
            [moreEnabled]="true"
            [subTabsView]="true"
            [subTabsCollectionTabName]="'LABEL_MAIN_MENU_SERVICE_GROUP'"
            [class.is-active]="isGroupTabActive('service')">
            @if (authorization.showOpenTicketsTab | async) {
            <a
              class="tabs__item navbar-menu-font-size"
              [routerLink]="['/tickets']"
              [class.is-active]="currentStateName === 'tickets'"
              [translate]="'OPEN_TICKETS'"
              data-cy="tickets-link"
              role="presentation"></a>
            } @if (authorization.showPlannedActivityTab | async) {
            <a
              class="tabs__item navbar-menu-font-size"
              [routerLink]="['/activities']"
              [class.is-active]="currentStateName === 'activities'"
              [translate]="'GEPLANTE_TERMINE'"
              data-cy="activities-link"
              role="presentation"></a>
            } @if (authorization.showPlannedAppointmentsTab | async) {
            <a
              class="tabs__item navbar-menu-font-size"
              [routerLink]="['/appointments']"
              [class.is-active]="currentStateName === 'appointments'"
              [translate]="'APPOINTMENTS_TITLE'"
              data-cy="appointments-link"
              role="presentation"></a>
            } @if (authorization.showContractsTab | async) {
            <a
              class="tabs__item navbar-menu-font-size"
              [routerLink]="['/contracts']"
              [class.is-active]="currentStateName === 'contracts'"
              [translate]="'CONTRACT_TITLE'"
              data-cy="contracts-link"
              role="presentation"></a>
            } @if ((authorization.showReportingTab | async)) {
            <div role="presentation">
              @if (checkPermissionOrRoleService.showReportingLink && !(authorization.showSprTab | async)) {
              <a
                class="tabs__item navbar-menu-font-size"
                [href]="checkPermissionOrRoleService.reportingLink"
                target="_blank"
                rel="noopener noreferrer"
                [translate]="'REPORTING'"></a>
              } @if (checkPermissionOrRoleService.showReportingComponent && !(authorization.showSprTab | async)) {
              <a
                class="tabs__item navbar-menu-font-size"
                [routerLink]="['/reporting']"
                [class.is-active]="currentStateName === 'reporting'"
                [translate]="'REPORTING'"></a>
              } @if (checkPermissionOrRoleService.showServiceMetricsComponent && !(authorization.showSprTab | async)) {
              <a
                class="tabs__item navbar-menu-font-size"
                [routerLink]="['/metrics']"
                [class.is-active]="currentStateName === 'metrics'"
                [translate]="'REPORTING'"></a>
              } @if (authorization.showSprTab | async) {
              <a
                class="tabs__item navbar-menu-font-size"
                [routerLink]="['/service-performance-metrics']"
                [class.is-active]="currentStateName === 'service-performance-metrics'"
                [translate]="'REPORTING'"
                data-cy="spr-link"></a>
              } @if (!checkPermissionOrRoleService.showReportingLink &&
              !checkPermissionOrRoleService.showReportingComponent &&
              !checkPermissionOrRoleService.showServiceMetricsComponent && !(authorization.showSprTab | async)) {
              <a
                class="tabs__item navbar-menu-font-size"
                href="#"
                (click)="reportModal.show($event)"
                [translate]="'REPORTING'"></a>
              }
            </div>
            } @if (authorization.showPartnerOrderTab | async) {
            <a
              class="tabs__item navbar-menu-font-size"
              [routerLink]="['/partnerOrders']"
              [class.is-active]="currentStateName === 'partnerOrders'"
              [translate]="'PARTNER_ORDERS'"
              role="presentation"></a>
            } @if (authorization.showInvoicesTab | async) {
            <a
              class="tabs__item navbar-menu-font-size"
              [routerLink]="['/invoices']"
              [class.is-active]="currentBaseStateName === 'invoices'"
              [translate]="'MAIN_MENU_INVOICES'"
              data-cy="invoices-link"
              role="presentation"></a>
            }
          </hl-tabs>
          <hl-tabs
            class="tabs__item navbar-menu-font-size padding-0"
            data-cy="main-menu-cybersecurity-group"
            [moreEnabled]="true"
            [subTabsView]="true"
            [subTabsCollectionTabName]="'LABEL_MAIN_MENU_CYBERSECURITY_GROUP'"
            [class.is-active]="isGroupTabActive('cybersecurity')">
            @if (authorization.showCybersecurityOverview | async) {
            <a
              class="tabs__item navbar-menu-font-size"
              [routerLink]="['/overview']"
              [class.is-active]="currentStateName === 'overview'"
              [translate]="'LABEL_CYBERSECURITY_OVERVIEW'"
              data-cy="overview-link"
              role="presentation"></a>
            } @if (authorization.showSecurityTab | async) {
            <a
              class="tabs__item navbar-menu-font-size"
              [routerLink]="['/security']"
              [class.is-active]="currentStateName === 'security'"
              [translate]="'SECURITY_VULNERABILITY_ASSESSMENTS'"
              data-cy="vulnerability-link"
              role="presentation"></a>
            } @if (authorization.showAdvisoriesTab | async) {
            <a
              class="tabs__item navbar-menu-font-size"
              [routerLink]="['/advisories']"
              [class.is-active]="currentStateName === 'advisories'"
              [translate]="'SECURITY_ADVISORIES'"
              data-cy="advisories-link"
              role="presentation"></a>
            } @if (authorization.showSystemUpdatesTab | async) {
            <a
              class="tabs__item navbar-menu-font-size"
              [routerLink]="['/updates']"
              [class.is-active]="currentStateName === 'updates'"
              [translate]="'SYSTEM_UPDATES'"
              data-cy="updates-link"
              role="presentation"></a>
            }
          </hl-tabs>
          @if (isEvolutionGroupLoaded) {
          <hl-tabs
            class="tabs__item navbar-menu-font-size padding-0"
            data-cy="main-menu-evolution-group"
            [moreEnabled]="true"
            [subTabsView]="true"
            [subTabsCollectionTabName]="'LABEL_MAIN_MENU_EVOLUTION_GROUP'"
            [class.is-active]="isGroupTabActive('evolution')">
            @if (showDigitalCompassTab) {
            <a
              class="tabs__item navbar-menu-font-size"
              [routerLink]="['/clinical-performance-companion']"
              [class.is-active]="currentStateName === 'clinical-performance-companion'"
              [translate]="'DIGITAL_COMPASS'"
              data-cy="digital-compass-link"
              role="presentation"></a>
            } @if (showPSRTab) {
            <a
              class="tabs__item navbar-menu-font-size"
              [routerLink]="[checkPermissionOrRoleService.psrLink]"
              [class.is-active]="currentBaseStateName === 'psr'"
              [translate]="'TAB_PSR'"
              data-cy="psr-link"
              role="presentation"></a>
            }
          </hl-tabs>
          } @if (authorization.showImpersonationTab | async) {
          <a
            class="tabs__item navbar-menu-font-size"
            [routerLink]="['/impersonation']"
            [class.is-active]="currentBaseStateName === 'impersonation'"
            [translate]="'CUSTOMER_IMPERSONATION'"
            data-cy="customer-impersonation-link"
            role="presentation"></a>
          } @if (authorization.showCustAdminTab | async) {
          <a
            class="tabs__item navbar-menu-font-size"
            [routerLink]="['/administration']"
            [class.is-active]="currentStateName === 'administration'"
            [translate]="'ADMINISTRATION'"
            data-cy="administration-navigation-link"
            role="presentation"></a>
          }
        </hl-tabs>
        }
      </div>
    </div>

    <div class="navigation-bar__additional-functions">
      <button
        *hlItemShowHideHandler="'role'; role: 'createTicketRole'"
        class="button button--small button--primary icon-plus margin-bottom-0"
        [class.button--control]="isMobileView"
        data-cy="create-ticket-btn"
        (click)="createTicketModal.show(); createTicketModal.showSelectedEquipment()">
        {{ isMobileView ? '' : ('CREATE_NEW_TICKET' | translate) }}
      </button>
    </div>
  </div>
</div>

<!-- Reporting modal showing no customers -->
@defer (on immediate) {
<hl-create-ticket #createTicketModal [equipmentToSelect]="selectedEquipment"></hl-create-ticket>
}
<hl-reporting-modal #reportModal></hl-reporting-modal>
