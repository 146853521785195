import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { isEqual } from 'lodash-es';
import { combineLatest, merge, Observable, of, Subject, switchMap } from 'rxjs';
import { CountryConfigRestService } from '../core/rest-services/country-config-rest.service';
import { BrowserStateService } from '../core/services/browser-state.service';
import { MessagesRestService } from '../core/rest-services/messages-rest.service';
import { AdvisoryCybersecurityNotificationMessageType, DocumentNotificationMessageType, PreliminaryInvoicesNotificationMessageType, SecurityAdvisoryNotificationMessageType, SubprocessorNotificationHQMessageType, SubprocessorNotificationMessageType, SurveyNotificationMessageType, SystemUpdateMessageType } from '../core/models/message';
import { UserUtilService } from '../core/services/user/user-util.service';
import { roles } from '../core/core-constants.service';
import { MessageViewModel } from '../core/view-models/message-view-model';
import { catchError, filter, takeUntil, tap } from 'rxjs/operators';
import { User } from '../core/models/user';
import { MessagingCenterService } from '../core/services/messaging-center/messaging-center.service';
import { MyFiltersAdapterService } from 'app/core/services/my-filters-adapter.service';
import { SecurityAdvisoriesService } from 'app/core/services/security-advisories/security-advisories-service';
import { OverlayComponent } from 'app/shared/components/overlay/overlay.component';
import { WindowService } from '../core/window.service';
import { SubprocessorListRestService } from '../core/rest-services/subprocessor-list-rest.service';
import { controllableTimer } from '@tpf-ui/toolkit';

@Component({
  selector: 'hl-messaging-center',
  templateUrl: './messaging-center.component.html'
})
export class MessagingCenterComponent implements OnInit, OnDestroy {

  @ViewChild('dropDownToggle') dropDownToggleEl: ElementRef;
  @ViewChild('advisoryNotificationOverlay') advisoryNotificationOverlay: OverlayComponent;

  private timer$: Observable<number>;
  private timerSource: Subject<'resume' | 'pause' | 'reset'>;
  private readonly refreshSource = new Subject<void>();

  seenMessages: MessageViewModel[] = [];
  unseenMessages: MessageViewModel[] = [];

  isLoaded = false;
  isAuthorized = false;
  config: any = {};

  private hasViewSecurityAdvisoriesRole = false;
  private hasAdvisoryAuthorRole = false;
  selectedMessage: MessageViewModel;

  isDropdownOpen = false;
  user: User = null;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(private router: Router,
    private configService: CountryConfigRestService,
    private browserStateService: BrowserStateService,
    private messagingCenterService: MessagingCenterService,
    private messagesRestService: MessagesRestService,
    private userUtilService: UserUtilService,
    private myFiltersService: MyFiltersAdapterService,
    private securityAdvisoriesService: SecurityAdvisoriesService,
    private windowService: WindowService,
    private subprocessorListRestService: SubprocessorListRestService) {
  }

  @HostListener('document:click.out-zone', ['$event'])
  clickout(event: Event) {
    const target = event.target as Element;
    if (!this.dropDownToggleEl ||
      !this.isDropdownOpen ||
      target?.className === 'icon-reload' ||
      target?.id === 'numberOfNewEventsDetailed' ||
      // the target can be e.g. SVGPathElement, which doesn't contain 'includes' function
      target?.className?.includes('message-head-padding') ||
      this.dropDownToggleEl.nativeElement.contains(target)) {
      return;
    }
    this.isDropdownOpen = false;
    this.resumePolling();
  }

  ngOnInit() {
    const auth$ = combineLatest([
      this.userUtilService.checkUserRoles(roles),
      this.userUtilService.getUser()
    ]).pipe(
      tap(([{
        userRole,
        itAdminRole,
        securityAdvisoryAuthorRole,
        securityAdvisoryViewRole,
        securityAdvisoryViewXiaRole
      }, user]) => {
        this.isAuthorized = userRole || itAdminRole;
        this.hasAdvisoryAuthorRole = securityAdvisoryAuthorRole;
        this.hasViewSecurityAdvisoriesRole = securityAdvisoryViewRole || securityAdvisoryViewXiaRole;
        this.user = user;
      })
    );
    const config$ = this.configService.getConfig().pipe(
      tap(config => {
        this.config = {
          datePattern: config.GENERIC_DATE_PATTERN,
          messagesLimit: parseInt(config.MESSAGING_CENTER_PAGE_SIZE, 10),
          intervalSec: parseInt(config.MESSAGING_CENTER_POLL_INTERVAL_SEC, 10),
          toggleSecurityAdvisories: isEqual(config.TOGGLE_SECURITY_ADVISORIES_MESSAGING_CENTER, 'true'),
          toggleAdvisorySubscription: isEqual(config.FEATURE_TOGGLE_ADVISORY_SUBSCRIPTION, 'true'),
          toggleXiaViewAdvisories: isEqual(config.FEATURE_TOGGLE_XIA_VIEW_ADVISORIES, 'true')
        };
      })
    );

    combineLatest([auth$, config$]).pipe(
      filter((_) => this.config.messagesLimit !== 0 && this.isAuthorized),
      tap(() => {
        const {timer$, controlSource} = controllableTimer(this.config.intervalSec * 1000);
        this.timer$ = timer$;
        this.timerSource = controlSource;
      }),
      switchMap(() => merge(this.timer$, this.refreshSource.asObservable())),
      tap(() => this.isLoaded = false),
      switchMap(() => this.messagesRestService.getMessages()),
      catchError(() => {
        this.isLoaded = true;
        return of({seenMessages: [], unseenMessages: []});
      }),
      takeUntil(this.unsubscribe$)
    )
      .subscribe(messages => {
        this.seenMessages = this.filterAdvisoryMessages(messages.seenMessages);
        this.unseenMessages = this.filterAdvisoryMessages(messages.unseenMessages);
        this.isLoaded = true;
        this.messagingCenterService.updateMessages(messages);
      });

    this.myFiltersService.filterEquipmentKeys$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      () => {
        this.refresh();
      }
    );

    this.messagingCenterService.refreshMessagingCenter$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.refresh();
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  refresh(): void {
    this.refreshSource.next();
  }

  pausePolling(): void {
    this.timerSource.next('pause');
  }

  resumePolling(): void {
    this.timerSource.next('resume');
  }

  handleNavigation($event: Event, message: MessageViewModel) {
    $event?.preventDefault();
    this.markEventMessageAsSeen(message);
    this.isDropdownOpen = false;

    if (this.isSystemUpdate(message)) {
      this.navigateToSystemUpdates(message);
    } else if (this.isSecurityAdvisoryNotification(message)) {
      this.navigateToSecurityAdvisory(message, '/advisories/');
    } else if (this.isAdvisoryCybersecurityNotification(message)) {
      if (this.hasAdvisoryAuthorRole && this.securityAdvisoriesService.isAdminView()) {
        this.navigateToSecurityAdvisory(message, '/advisories/notifications/');
      } else {
        this.openAdvisoryNotificationOverlay(message);
      }
    } else if (this.isSurveyNotification(message)) {
      this.navigateToSurvey(message);
    } else if (this.isSubprocessorNotification(message)) {
      this.openSubprocessorListFile(message);
    } else if (this.isPreliminaryInvoiceNotification(message)) {
      this.navigateToPreliminaryInvoices();
    }
  }

  markEventMessageAsSeen(message: MessageViewModel) {
    this.messagesRestService.postViewedEventMessage(message)
      .subscribe(() => this.refresh());
  }

  private navigateToSystemUpdates(message: MessageViewModel) {
    if (message.messageType === SystemUpdateMessageType) {
      this.browserStateService.setUserNavigation();
      this.router.navigate(
        ['/updates'],
        {queryParams: {'searchTerm': message.identifier}}).then(() => {
        this.browserStateService.resetUserNavigation();
      });
    }
  }

  private navigateToSecurityAdvisory(message: MessageViewModel, baseUrl: string) {
    this.browserStateService.setUserNavigation();
    this.router.navigate(
      [baseUrl + message.identifier + '/overview']).then(() => {
      this.browserStateService.resetUserNavigation();
    });
  }

  private navigateToSurvey(message: MessageViewModel) {
    this.browserStateService.setUserNavigation();
    this.router.navigate(
      ['/tickets'],
      {queryParams: {'survey': message.identifier}}).then(() => {
      this.browserStateService.resetUserNavigation();
    });
  }

  private openSubprocessorListFile({messageType}: MessageViewModel) {
    this.subprocessorListRestService.getSubprocessorLink(messageType === SubprocessorNotificationMessageType ? 'country' : 'hq')
      .subscribe(link => {
        this.windowService.nativeWindow.open(link, 'blank');
      });
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
    if (this.isDropdownOpen) {
      this.pausePolling();
    } else {
      this.resumePolling();
    }
  }

  isSystemUpdate(message: MessageViewModel): boolean {
    return message.messageType === SystemUpdateMessageType;
  }

  isSubprocessorNotification(message: MessageViewModel): boolean {
    return this.isSubprocessorCountryNotification(message) || this.isSubprocessorHqNotification(message);
  }

  isSubprocessorCountryNotification(message: MessageViewModel): boolean {
    return message.messageType === SubprocessorNotificationMessageType;
  }

  isSubprocessorHqNotification(message: MessageViewModel): boolean {
    return message.messageType === SubprocessorNotificationHQMessageType;
  }

  isSecurityAdvisoryNotification(message: MessageViewModel): boolean {
    return message.messageType === SecurityAdvisoryNotificationMessageType;
  }

  isAdvisoryCybersecurityNotification(message: MessageViewModel): boolean {
    return message.messageType === AdvisoryCybersecurityNotificationMessageType;
  }

  isSurveyNotification(message: MessageViewModel): boolean {
    return message.messageType === SurveyNotificationMessageType;
  }

  isPreliminaryInvoiceNotification(message: MessageViewModel): boolean {
    return message.messageType === PreliminaryInvoicesNotificationMessageType;
  }

  isDocumentNotification(message: MessageViewModel): boolean {
    return message.messageType === DocumentNotificationMessageType;
  }

  private openAdvisoryNotificationOverlay(message: MessageViewModel) {
    this.selectedMessage = message;
    this.advisoryNotificationOverlay.show();
  }

  private filterAdvisoryMessages(messages: MessageViewModel[]): MessageViewModel[] {
    if (this.showSecurityAdvisories()) {
      return messages;
    }
    return messages.filter(
      message => !this.isSecurityAdvisoryNotification(message) && !this.isAdvisoryCybersecurityNotification(message));
  }

  private showSecurityAdvisories(): boolean {
    let showAdvisories = false;
    if (this.config.toggleSecurityAdvisories) {
      showAdvisories = !((this.config.toggleAdvisorySubscription || this.config.toggleXiaViewAdvisories) &&
        !this.hasViewSecurityAdvisoriesRole);
    }
    return showAdvisories;
  }

  private navigateToPreliminaryInvoices() {
    this.messagingCenterService.reloadPreliminaryInvoices();
    void this.router.navigate(['/invoices/prelim']);
  }
}
