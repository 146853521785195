<!--start content-->
<div hlAddRootClasses hlAddOmnitureAndRouterStateName hlWalkMe hlCookieBanner>
  <header class="header" id="header">
    @if (isAuthenticatedAndLoaded) {
      <hl-header class="hl-header" (userLogsOut)="logout()"></hl-header>
      <hl-main-nav-group class="hl-main-nav-group"></hl-main-nav-group>
    }
  </header>
  <main class="main-container">
    <router-outlet></router-outlet>
  </main>
  @if (isAuthenticatedAndLoaded) {
    <hl-toaster></hl-toaster>
  }
  <hl-terms-and-condition-modal #termsAndConditionModal [loaded]="translationFileLoaded" (accepted)="showIntroModal()"></hl-terms-and-condition-modal>
  <hl-ic-code-modal #icCodeModal [isWelcomePage]="false"></hl-ic-code-modal>
  <hl-intro-feature-modal #introFeatureModal (introChecked)="showNotificationsSubscription()"></hl-intro-feature-modal>
  <hl-notifications-subscription-modal #notificationsSubscriptionModal></hl-notifications-subscription-modal>

  <sn-chat-bot></sn-chat-bot>
</div>
