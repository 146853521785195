export const CdDocumentSource = [
  {
    id: 'technical-documentation',
    sourceUrl: '/rest/v1/source/technical-documentation',
    sourceImage:
      'https://lifenet.healthcare.siemens.com/static/document_images/default.jpg',
    sourceTitle: 'Technical Documentation',
    sourceDescription: ''
  },
  {
    id: 'service-report',
    sourceUrl: '/rest/v1/source/service-report',
    sourceImage:
      'https://lifenet.healthcare.siemens.com/static/document_images/default.jpg',
    sourceTitle: 'Service Report',
    sourceDescription: ''
  },
  {
    id: 'product-line-document',
    sourceUrl: '/rest/v1/source/product-line-document',
    sourceImage:
      'https://lifenet.healthcare.siemens.com/static/document_images/default.jpg',
    sourceTitle: 'Product line document',
    sourceDescription: ''
  }
]