import { Injectable } from '@angular/core';
import { TranslateDefaultParser } from '@ngx-translate/core';
import { TranslateDebugService } from './translate-debug.service';

@Injectable({
  providedIn: 'root'
})
export class TranslateDebugParser extends TranslateDefaultParser {

  constructor(private translateDebugService: TranslateDebugService) {
    super();
  }

  override getValue(target: any, key: string): any {
    return this.translateDebugService.isDebugEnabled ? key : super.getValue(target, key);
  }

}
