import moment from 'moment-timezone';

const dateNow = new Date();
export function setDateTime($addition, $hour = 0, $minutes = 0) {
  return new Date(
    dateNow.getFullYear(),
    dateNow.getMonth(),
    dateNow.getDate() + $addition,
    $hour,
    $minutes
  ).toISOString();
}

export function setDateDefinied(dateString: string, addition = 0) {
  const date = new Date(dateString);
  return new Date(date.getFullYear(), date.getMonth(), date.getDate() + addition).toISOString();
}

export function setMomentUTC(date: string) {
  return moment(date).tz('UTC').format('DD.MM.YYYY HH:mm:ss zz');
}
