import { ProfessionalServiceRequest } from 'app/core/models/psr/professional-service-request';
import { PsrType } from 'app/core/models/psr/psr-status-type';

export const CdProfessionalServiceRequest: ProfessionalServiceRequest = {
  customerId: '1',
  requestDescription: 'Psr Request 2',
  quoteNumber: 1800000002,
  modality: '11',
  submitDate: '2017-12-25T13:00:00Z',
  quoteStatus: 'WIMG',
  siemensEquipmentId: 'siemensEquipment2',
  country: '',
  webId: '',
  // customerNumber: '',
  soldTo: '',
  customerName: '',
  customerAddress: '',
  customerCity: '',
  customerState: '',
  customerPostalCode: '',
  customerContact: '',
  customerEmail: '',
  customerPhone: '',
  projectManager: '',
  priority: '',
  impactToSite: '',
  siemensContact: '',
  expectedOutcome: '',
  additionalDescription: '',
  purchaseOrderNumber: '',
  multipleProductsFlag: false,
  deliveryMethod: '',
  softwareVersion: '',
  submitterName: '',
  submitterTitle: '',
  submitterEmail: '',
  preApprovalStatus: '',
  quoteRequested: '',
  quoterName: '',
  quoterPhone: '',
  quoterEmail: '',
  quoteEffectiveFrom: '',
  quoteValidThrough: '',
  productDescription: '',
  lineItems: null
};

export const CdProfessionalServiceRequestList: ProfessionalServiceRequest[] = [
  {
    ...CdProfessionalServiceRequest,
    requestDescription: 'Request for PSR Quote',
    modality: 'syngo plaza',
    siemensEquipmentId: '4',
    customerName: 'Heiko Horn',
    customerAddress: 'Mystreet 1',
    customerCity: 'Mycity',
    customerState: 'Mystate',
    customerPostalCode: '123456',
    customerContact: 'Heiko Horn',
    customerEmail: 'heiko.horn@siemens-healthineers.com',
    customerPhone: '+123486752',
    priority: 'Urgent',
    siemensContact: 'Chris Klimaszweski',
    purchaseOrderNumber: 'PSR#20180005561',
    quoteEffectiveFrom: '2016-08-17',
    quoteValidThrough: '2017-02-17'
  },
  {
    ...CdProfessionalServiceRequest,
    requestDescription: 'update body part information in plaza DB',
    modality: 'syngo plaza',
    siemensEquipmentId: '4',
    customerName: 'Klaus Hendrichs',
    customerAddress: 'Mystreet 1',
    customerCity: 'Mycity',
    customerState: 'Mystate',
    customerPostalCode: '123456',
    customerContact: 'Klaus Hendrichs',
    customerEmail: 'klaus.hendrichs@siemens-healthineers.com',
    customerPhone: '+4913678640',
    priority: 'Standard',
    siemensContact: 'Sam Brown',
    purchaseOrderNumber: 'PSR#20180005561',
    quoteEffectiveFrom: '2016-12-04',
    quoteValidThrough: '2017-11-14'
  },
  {
    ...CdProfessionalServiceRequest,
    requestDescription: 'update lagcy body parts',
    modality: 'syngo plaza',
    siemensEquipmentId: '4',
    customerName: 'Heiko Horn',
    customerAddress: 'Mystreet 1',
    customerCity: 'Mycity',
    customerState: 'Mystate',
    customerPostalCode: '123456',
    customerContact: 'Heiko Horn',
    customerEmail: 'heiko.horn@siemens-healthineers.com',
    customerPhone: '+123486752',
    priority: 'Standard',
    siemensContact: 'Chris Klimaszweski',
    purchaseOrderNumber: 'PSR#20180005561',
    quoteEffectiveFrom: '2017-02-13',
    quoteValidThrough: '2017-05-19'
  },
  {
    ...CdProfessionalServiceRequest,
    requestDescription: 'import body part information in plaza DB',
    modality: 'syngo plaza',
    siemensEquipmentId: '4',
    customerName: 'Klaus Hendrichs',
    customerAddress: 'Mystreet 1',
    customerCity: 'Mycity',
    customerState: 'Mystate',
    customerPostalCode: '123456',
    customerContact: 'Klaus Hendrichs',
    customerEmail: 'klaus.hendrichs@siemens-healthineers.com',
    customerPhone: '+4913678640',
    priority: 'Urgent',
    siemensContact: 'Sam Brown',
    purchaseOrderNumber: 'PSR#20180005561',
    quoteEffectiveFrom: '2017-11-15',
    quoteValidThrough: '2018-03-20'
  },
  {
    ...CdProfessionalServiceRequest,
    requestDescription: 'Data Import/Export/Manipulation',
    modality: 'syngo plaza',
    siemensEquipmentId: '4',
    customerName: 'Heiko Horn',
    customerAddress: 'Mystreet 1',
    customerCity: 'Mycity',
    customerState: 'Mystate',
    customerPostalCode: '123456',
    customerContact: 'Heiko Horn',
    customerEmail: 'heiko.horn@siemens-healthineers.com',
    customerPhone: '+123486752',
    priority: 'Standard',
    siemensContact: 'Chris Klimaszweski',
    purchaseOrderNumber: 'PSR#20180005561',
    quoteEffectiveFrom: '2017-02-13',
    quoteValidThrough: '2017-05-19'
  }
];

export const CdPsrType1: PsrType = {
  type: 'CMPL',
  psrTypeDescription: 'Completed'
};

export const CdPsrType2: PsrType = {
  type: 'PROG',
  psrTypeDescription: 'In progress'
};

export const CdPsrType3: PsrType = {
  type: 'RANA',
  psrTypeDescription: 'Assigned to analyst'
};

export const CdPsrType4: PsrType = {
  type: 'WIMG',
  psrTypeDescription: 'Waiting for Syngo approval'
};

export const CdPsrType5: PsrType = {
  type: 'XCAN',
  psrTypeDescription: 'Cancelled'
};

export const CdPsrTypes: PsrType[] = [CdPsrType1, CdPsrType2, CdPsrType3, CdPsrType4, CdPsrType5];
