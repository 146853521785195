import { Equipment } from 'app/core/models/equipment/equipment';
import { EquipmentMyProfile } from 'app/core/models/equipment/equipment-my-profile';
import { LifenetSystemCustom } from 'app/core/models/optionsUpgrades/lifenet-system-custom';
import { EquipmentComponentItem, EquipmentDetails } from 'app/core/models/equipment/equipment-details';
import { EqDocumentType } from 'app/core/models/equipment/eq-document-type';
import { LastViewedEquipment } from '../app/core/models/last-viewed-equipment';
import { EquipmentStatus, EquipmentStatusForCustomer } from '../app/core/models/equipment/equipment-status';
import { EqAttachment } from 'app/core/models/equipment/eq-attachment';

interface CdEquipmentAttachments {
  id: string;
  attachments: EqAttachment[];
}

// new lastModifiedDateTime
export const CdEqAttachmentList: CdEquipmentAttachments[] = [
  {
    id: '1',
    attachments: [
      {
        filename: 'C2-058.832.01.08.02.pdf',
        filesize: 1457,
        base64: null,
        creatorName: null,
        creationDateTime: '2018-03-07',
        lastModifiedDateTime: '2018-03-07',
        ticketNumber: null,
        downloadLink: ''
      },
      {
        filename: 'Model_Contract_ Utilization_Management.doc',
        filesize: 101,
        base64: null,
        creatorName: null,
        creationDateTime: '2018-01-15',
        lastModifiedDateTime: '2018-03-07',
        ticketNumber: null,
        downloadLink: ''
      },
      {
        filename: 'Model_Contract_Guardian_Program.pdf',
        filesize: 694,
        base64: null,
        creatorName: null,
        creationDateTime: '2018-02-19',
        lastModifiedDateTime: '2018-03-07',
        ticketNumber: null,
        downloadLink: ''
      }
    ]
  },
  {
    id: '2',
    attachments: [
      {
        filename: 'C2-058.832.01.08.02.pdf',
        filesize: 1457,
        base64: null,
        creatorName: null,
        creationDateTime: '2018-03-07',
        lastModifiedDateTime: '2018-03-07',
        ticketNumber: null,
        downloadLink: ''
      }
    ]
  },
  {
    id: '3',
    attachments: [
      {
        filename: 'C2-058.832.01.08.02.pdf',
        filesize: 1457,
        base64: null,
        creatorName: null,
        creationDateTime: '2018-03-07',
        lastModifiedDateTime: '2018-03-07',
        ticketNumber: null,
        downloadLink: ''
      }
    ]
  },
  {
    id: '7',
    attachments: [
      {
        filename: 'Model_Contract_ Utilization_Management.doc',
        filesize: 101,
        base64: null,
        creatorName: null,
        creationDateTime: '2018-01-15',
        lastModifiedDateTime: '2018-03-07',
        ticketNumber: null,
        downloadLink: ''
      }
    ]
  },
  {
    id: '8',
    attachments: [
      {
        filename: 'Model_Contract_Evolve.pdf',
        filesize: 2544,
        base64: null,
        creatorName: null,
        creationDateTime: '2018-01-15',
        lastModifiedDateTime: '2018-03-07',
        ticketNumber: null,
        downloadLink: ''
      }
    ]
  },
  {
    id: '9',
    attachments: [
      {
        filename: 'Model_Contract_Evolve.pdf',
        filesize: 2544,
        base64: null,
        creatorName: null,
        creationDateTime: '2018-01-15',
        lastModifiedDateTime: '2018-03-07',
        ticketNumber: null,
        downloadLink: ''
      }
    ]
  },
  {
    id: '12',
    attachments: [
      {
        filename: 'Model_Contract_ Utilization_Management.doc',
        filesize: 101,
        base64: null,
        creatorName: null,
        creationDateTime: '2018-01-15',
        lastModifiedDateTime: '2018-03-07',
        ticketNumber: null,
        downloadLink: ''
      },
      {
        filename: 'Model_Contract_Evolve.pdf',
        filesize: 2544,
        base64: null,
        creatorName: null,
        creationDateTime: '2018-02-19',
        lastModifiedDateTime: '2018-03-07',
        ticketNumber: null,
        downloadLink: ''
      }
    ]
  },
  {
    id: '13',
    attachments: [
      {
        filename: 'Model_Contract_Evolve.pdf',
        filesize: 2544,
        base64: null,
        creatorName: null,
        creationDateTime: '2018-01-15',
        lastModifiedDateTime: '2018-03-07',
        ticketNumber: null,
        downloadLink: ''
      }
    ]
  },
  {
    id: '15',
    attachments: [
      {
        filename: 'Model_Contract_Evolve.pdf',
        filesize: 2544,
        base64: null,
        creatorName: null,
        creationDateTime: '2018-01-15',
        lastModifiedDateTime: '2018-03-07',
        ticketNumber: null,
        downloadLink: ''
      }
    ]
  }
];
export interface CdDetailsDocuments {
  id: string;
  details: EqDocumentType[];
}

export const CdEqDocuments: CdDetailsDocuments[] = [
  {
    id: '1',
    details: [
      {
        docType: 'PM Checklist',
        documents: [
          {
            hashkey: '6078566',
            document: 'C2-058.832.01.08.02.pdf',
            size: 1457
          }
        ]
      },
      {
        docType: 'Contract',
        documents: [
          {
            hashkey: '3154674',
            document: 'Model_Contract_ Utilization_Management.doc',
            size: 101
          }
        ]
      },
      {
        docType: 'Service',
        documents: [
          {
            hashkey: '5432600',
            document: 'Model_Contract_Guardian_Program.pdf',
            size: 694
          }
        ]
      }
    ]
  },
  {
    id: '2',
    details: [
      {
        docType: 'PM Checklist',
        documents: [
          {
            hashkey: '6078566',
            document: 'C2-058.832.01.08.02.pdf',
            size: 1457
          }
        ]
      }
    ]
  },
  {
    id: '3',
    details: [
      {
        docType: 'PM Checklist',
        documents: [
          {
            hashkey: '6078566',
            document: 'C2-058.832.01.08.02.pdf',
            size: 1457
          }
        ]
      }
    ]
  },
  {
    id: '7',
    details: [
      {
        docType: 'Contract',
        documents: [
          {
            hashkey: '8756436',
            document: 'Model_Contract_ Utilization_Management.doc',
            size: 101
          }
        ]
      }
    ]
  },
  {
    id: '8',
    details: [
      {
        docType: 'Service',
        documents: [
          {
            hashkey: '6346765',
            document: 'Model_Contract_Evolve.pdf',
            size: 2544
          }
        ]
      }
    ]
  },
  {
    id: '9',
    details: [
      {
        docType: 'Service',
        documents: [
          {
            hashkey: '8900033',
            document: 'Model_Contract_Evolve.pdf',
            size: 2544
          }
        ]
      }
    ]
  },
  {
    id: '12',
    details: [
      {
        docType: 'Contract',
        documents: [
          {
            hashkey: '8756436',
            document: 'Model_Contract_ Utilization_Management.docs',
            size: 101
          }
        ]
      },
      {
        docType: 'Service',
        documents: [
          {
            hashkey: '6439005',
            document: 'Model_Contract_Evolve.pdf',
            size: 2544
          }
        ]
      }
    ]
  },
  {
    id: '13',
    details: [
      {
        docType: 'Service',
        documents: [
          {
            hashkey: '7535887',
            document: 'Model_Contract_Evolve.pdf',
            size: 2544
          }
        ]
      }
    ]
  },
  {
    id: '15',
    details: [
      {
        docType: 'Service',
        documents: [
          {
            hashkey: '7400074',
            document: 'Model_Contract_Evolve.pdf',
            size: 2544
          }
        ]
      }
    ]
  }
];

// components
export const CdequipmentComponentItem: EquipmentComponentItem = {
  componentID: 'componentID',
  componentInstallationDate: new Date(),
  componentMaterialNumber: 1234,
  componentName: 'componentName',
  componentOwnID: 'componentOwnID',
  componentSerialNumber: 4321,
  equipmentKey: 'equiKey'
};

export const CdequipmentComponentItemList: EquipmentComponentItem[] = [
  {
    ...CdequipmentComponentItem,
    componentID: '1031569345',
    componentInstallationDate: new Date(2015, 10, 13),
    componentMaterialNumber: 1234,
    componentName: '3T Wrist Coil',
    componentOwnID: '-',
    componentSerialNumber: 1848,
    equipmentKey: '2'
  },
  {
    componentID: '1031816250',
    componentInstallationDate: new Date(2015, 10, 13),
    componentMaterialNumber: 1234,
    componentName: '3T Flex Small MR Coil',
    componentOwnID: '-',
    componentSerialNumber: 3510,
    equipmentKey: '2'
  },
  {
    componentID: '1030285526',
    componentInstallationDate: new Date(2015, 10, 13),
    componentMaterialNumber: 1234,
    componentName: 'Assembly_E-Shim_Cortex_M3',
    componentOwnID: '-',
    componentSerialNumber: 2046,
    equipmentKey: '2'
  },
  {
    componentID: '1031370618',
    componentInstallationDate: new Date(2015, 10, 13),
    componentMaterialNumber: 1234,
    componentName: 'Head/Neck 16',
    componentOwnID: '-',
    componentSerialNumber: 1226,
    equipmentKey: '2'
  },
  {
    componentID: '1031383415',
    componentInstallationDate: new Date(2015, 10, 13),
    componentMaterialNumber: 1234,
    componentName: 'Monet 3T body 6',
    componentOwnID: '-',
    componentSerialNumber: 1355,
    equipmentKey: '2'
  },
  {
    componentID: '1031424326',
    componentInstallationDate: new Date(2015, 10, 13),
    componentMaterialNumber: 1234,
    componentName: 'Monet spinel 24',
    componentOwnID: '-',
    componentSerialNumber: 1230,
    equipmentKey: '2'
  },
  {
    componentID: '1031507033',
    componentInstallationDate: new Date(2015, 10, 13),
    componentMaterialNumber: 1234,
    componentName: 'Monitor 19 DSC1913-D',
    componentOwnID: '-',
    componentSerialNumber: 151505721,
    equipmentKey: '2'
  },
  {
    componentID: '1031517392',
    componentInstallationDate: new Date(2015, 10, 13),
    componentMaterialNumber: 1234,
    componentName: 'Monet 3T body 6',
    componentOwnID: '-',
    componentSerialNumber: 1362,
    equipmentKey: '2'
  },
  {
    componentID: '1031567221',
    componentInstallationDate: new Date(2015, 10, 13),
    componentMaterialNumber: 1234,
    componentName: 'Flex coil interface 3T',
    componentOwnID: '-',
    componentSerialNumber: 2617,
    equipmentKey: '2'
  },
  {
    componentID: '1031586966',
    componentInstallationDate: new Date(2015, 10, 13),
    componentMaterialNumber: 1234,
    componentName: 'TIM Coil Interfface 3.0 T',
    componentOwnID: '-',
    componentSerialNumber: 3170,
    equipmentKey: '2'
  },
  {
    componentID: '1031517370',
    componentInstallationDate: new Date(2015, 7, 10),
    componentMaterialNumber: 1234,
    componentName: '1.5T 4 Ch Flex Small MR coil',
    componentOwnID: '-',
    componentSerialNumber: 3382,
    equipmentKey: '3'
  },
  {
    componentID: '1031517325',
    componentInstallationDate: new Date(2015, 7, 10),
    componentMaterialNumber: 1234,
    componentName: '1.5T 4 Ch Flex large MR coil',
    componentOwnID: '-',
    componentSerialNumber: 3463,
    equipmentKey: '3'
  },
  {
    componentID: '1031569367',
    componentInstallationDate: new Date(2015, 7, 10),
    componentMaterialNumber: 1234,
    componentName: 'Body 6 MR Coil 1.5T',
    componentOwnID: '-',
    componentSerialNumber: 1368,
    equipmentKey: '3'
  },
  {
    componentID: '1029879676',
    componentInstallationDate: new Date(2015, 7, 10),
    componentMaterialNumber: 1234,
    componentName: 'Foot/Ankle 10 MR Coil 1.5T',
    componentOwnID: '-',
    componentSerialNumber: 1004,
    equipmentKey: '3'
  },
  {
    componentID: '1029914994',
    componentInstallationDate: new Date(2015, 7, 10),
    componentMaterialNumber: 1234,
    componentName: 'wrist 8 MR Coil 1.5T',
    componentOwnID: '-',
    componentSerialNumber: 1004,
    equipmentKey: '3'
  },
  {
    componentID: '1029965074',
    componentInstallationDate: new Date(2015, 7, 10),
    componentMaterialNumber: 1234,
    componentName: 'Computer MaRS1S.6 8RX',
    componentOwnID: '-',
    componentSerialNumber: 1006,
    equipmentKey: '3'
  },
  {
    componentID: '1030125206',
    componentInstallationDate: new Date(2015, 7, 10),
    componentMaterialNumber: 1234,
    componentName: 'Shoulder Large 6 MR Coil 1.5T',
    componentOwnID: '-',
    componentSerialNumber: 1005,
    equipmentKey: '3'
  },
  {
    componentID: '1030125207',
    componentInstallationDate: new Date(2015, 7, 10),
    componentMaterialNumber: 1234,
    componentName: 'Shoulder Small 6 MR Coil 1.5T',
    componentOwnID: '-',
    componentSerialNumber: 1005,
    equipmentKey: '3'
  },
  {
    componentID: '1030178748',
    componentInstallationDate: new Date(2015, 7, 10),
    componentMaterialNumber: 1234,
    componentName: 'Head/Neck 10 MR Coil 1.5T',
    componentOwnID: '-',
    componentSerialNumber: 1007,
    equipmentKey: '3'
  },
  {
    componentID: '1031036006',
    componentInstallationDate: new Date(2015, 7, 10),
    componentMaterialNumber: 1234,
    componentName: 'Body Coil 013',
    componentOwnID: '-',
    componentSerialNumber: 1022,
    equipmentKey: '3'
  },
  {
    componentID: '10141920',
    componentInstallationDate: new Date(2009, 3, 17),
    componentMaterialNumber: 1234,
    componentName: 'Single Tank Unit P40 MoW-100G',
    componentOwnID: '-',
    componentSerialNumber: 1513,
    equipmentKey: '6'
  },
  {
    componentID: '10501285',
    componentInstallationDate: new Date(2009, 3, 17),
    componentMaterialNumber: 1234,
    componentName: 'Detector LMAM',
    componentOwnID: '-',
    componentSerialNumber: 301473,
    equipmentKey: '6'
  },
  {
    componentID: '10643085',
    componentInstallationDate: new Date(2009, 3, 17),
    componentMaterialNumber: 1234,
    componentName: 'Detector LMAM 2',
    componentOwnID: '-',
    componentSerialNumber: 301473,
    equipmentKey: '6'
  },
  {
    componentID: '3345209',
    componentInstallationDate: new Date(2016, 3, 11),
    componentMaterialNumber: 1234,
    componentName: 'OPTITOP 150/40/80HC-100',
    componentOwnID: '-',
    componentSerialNumber: 410281571,
    equipmentKey: '7'
  },
  {
    componentID: '10762403',
    componentInstallationDate: new Date(2016, 3, 11),
    componentMaterialNumber: 1234,
    componentName: 'MAX mini 2430EZ wi-D',
    componentOwnID: '-',
    componentSerialNumber: 0,
    equipmentKey: '7'
  },
  {
    componentID: '10762402',
    componentInstallationDate: new Date(2016, 3, 11),
    componentMaterialNumber: 1234,
    componentName: 'MAX wi-D 3543EZh',
    componentOwnID: '-',
    componentSerialNumber: 0,
    equipmentKey: '7'
  },
  {
    componentID: '10521108',
    componentInstallationDate: new Date(2015, 3, 24),
    componentMaterialNumber: 1234,
    componentName: 'Coll Cart Wheel',
    componentOwnID: '-',
    componentSerialNumber: 15432,
    equipmentKey: '8'
  },
  {
    componentID: '10412574',
    componentInstallationDate: new Date(2015, 3, 24),
    componentMaterialNumber: 1234,
    componentName: 'Rotate Motor ASM',
    componentOwnID: '-',
    componentSerialNumber: 2595,
    equipmentKey: '8'
  },
  {
    componentID: '10522533',
    componentInstallationDate: new Date(2015, 3, 24),
    componentMaterialNumber: 1234,
    componentName: 'AEB Replacement',
    componentOwnID: '-',
    componentSerialNumber: 4589,
    equipmentKey: '8'
  },
  {
    componentID: '10590814',
    componentInstallationDate: new Date(2011, 5, 6),
    componentMaterialNumber: 1234,
    componentName: 'ICS Tower 11E',
    componentOwnID: '-',
    componentSerialNumber: 1114,
    equipmentKey: '9'
  },
  {
    componentID: '10528606',
    componentInstallationDate: new Date(2011, 5, 6),
    componentMaterialNumber: 1234,
    componentName: 'ASM MECH PHS Pallet - S.White',
    componentOwnID: '-',
    componentSerialNumber: 1489,
    equipmentKey: '9'
  },
  {
    componentID: '10527938',
    componentInstallationDate: new Date(2011, 5, 6),
    componentMaterialNumber: 1234,
    componentName: 'ASM Trinity Detector ',
    componentOwnID: '-',
    componentSerialNumber: 3569,
    equipmentKey: '9'
  },
  {
    componentID: '1027341353',
    componentInstallationDate: new Date(2015, 6, 11),
    componentMaterialNumber: 1234,
    componentName: 'AXIS EAS PC',
    componentOwnID: '-',
    componentSerialNumber: 1057,
    equipmentKey: '10'
  },
  {
    componentID: '1027341356',
    componentInstallationDate: new Date(2015, 6, 11),
    componentMaterialNumber: 1234,
    componentName: 'AXIS EAS SYSTEM',
    componentOwnID: '-',
    componentSerialNumber: 1090,
    equipmentKey: '10'
  },
  {
    componentID: '1030262241',
    componentInstallationDate: new Date(2015, 6, 11),
    componentMaterialNumber: 1234,
    componentName: 'Artis one Appl. VA10D',
    componentOwnID: '-',
    componentSerialNumber: 1060,
    equipmentKey: '10'
  },
  {
    componentID: '1010220390',
    componentInstallationDate: new Date(2013, 3, 9),
    componentMaterialNumber: 1234,
    componentName: 'ACE-1 Module with USB Extender',
    componentOwnID: '-',
    componentSerialNumber: 1610,
    equipmentKey: '11'
  },
  {
    componentID: '1009823469',
    componentInstallationDate: new Date(2013, 3, 9),
    componentMaterialNumber: 1234,
    componentName: 'Axis Biplan',
    componentOwnID: '-',
    componentSerialNumber: 5168,
    equipmentKey: '11'
  },
  {
    componentID: '1010222978',
    componentInstallationDate: new Date(2013, 3, 9),
    componentMaterialNumber: 1234,
    componentName: 'Automap Module',
    componentOwnID: '-',
    componentSerialNumber: 100000002002321,
    equipmentKey: '11'
  },
  {
    componentID: '1011865720',
    componentInstallationDate: new Date(2009, 12, 21),
    componentMaterialNumber: 1234,
    componentName: 'Axis Monoplan BB',
    componentOwnID: '-',
    componentSerialNumber: 5172,
    equipmentKey: '12'
  },
  {
    componentID: '1011871264',
    componentInstallationDate: new Date(2009, 12, 21),
    componentMaterialNumber: 1234,
    componentName: 'Artist test license FL loop (trial)',
    componentOwnID: '-',
    componentSerialNumber: 100000000001379,
    equipmentKey: '12'
  },
  {
    componentID: '1011871280',
    componentInstallationDate: new Date(2009, 12, 21),
    componentMaterialNumber: 1234,
    componentName: 'Care profile module (key)',
    componentOwnID: '-',
    componentSerialNumber: 200000300002068,
    equipmentKey: '12'
  },
  {
    componentID: '1028269390',
    componentInstallationDate: new Date(2013, 12, 6),
    componentMaterialNumber: 1234,
    componentName: 'Control-Box HIC',
    componentOwnID: '-',
    componentSerialNumber: 1004,
    equipmentKey: '13'
  },
  {
    componentID: '1026512495',
    componentInstallationDate: new Date(2013, 12, 6),
    componentMaterialNumber: 1234,
    componentName: 'Copy protection dongle',
    componentOwnID: '-',
    componentSerialNumber: 51920,
    equipmentKey: '13'
  },
  {
    componentID: '1026369014',
    componentInstallationDate: new Date(2013, 12, 6),
    componentMaterialNumber: 1234,
    componentName: 'DMS P58A',
    componentOwnID: '-',
    componentSerialNumber: 20033,
    equipmentKey: '13'
  },

  {
    componentID: '1023691036',
    componentInstallationDate: new Date(2008, 9, 1),
    componentMaterialNumber: 1234,
    componentName: 'CT Bildrechner Tower IRS-G1',
    componentOwnID: '-',
    componentSerialNumber: 1098,
    equipmentKey: '1'
  },
  {
    componentID: '1023419511',
    componentInstallationDate: new Date(2008, 9, 1),
    componentMaterialNumber: 1234,
    componentName: 'Copy protection dongle',
    componentOwnID: '-',
    componentSerialNumber: 9182,
    equipmentKey: '1'
  },
  {
    componentID: '1022951647',
    componentInstallationDate: new Date(2008, 9, 1),
    componentMaterialNumber: 1234,
    componentName: 'DMS P65',
    componentOwnID: '-',
    componentSerialNumber: 50077,
    equipmentKey: '14'
  },

  {
    componentID: '1023691063',
    componentInstallationDate: new Date(2012, 9, 21),
    componentMaterialNumber: 1234,
    componentName: 'CT Bildrechner Tower IRS-G1',
    componentOwnID: '-',
    componentSerialNumber: 1099,
    equipmentKey: '14'
  },
  {
    componentID: '1023419566',
    componentInstallationDate: new Date(2012, 9, 21),
    componentMaterialNumber: 1234,
    componentName: 'Copy protection dongle',
    componentOwnID: '-',
    componentSerialNumber: 9182,
    equipmentKey: '14'
  },
  {
    componentID: '1022951674',
    componentInstallationDate: new Date(2012, 9, 21),
    componentMaterialNumber: 1234,
    componentName: 'DMS P65',
    componentOwnID: '-',
    componentSerialNumber: 50076,
    equipmentKey: '14'
  },
  {
    componentID: '1016508940',
    componentInstallationDate: new Date(2008, 2, 9),
    componentMaterialNumber: 1234,
    componentName: '4V1C Transducer',
    componentOwnID: '-',
    componentSerialNumber: 4607907,
    equipmentKey: '15'
  },
  {
    componentID: '1021052698',
    componentInstallationDate: new Date(2008, 2, 9),
    componentMaterialNumber: 1234,
    componentName: '9L4 Transducer',
    componentOwnID: '-',
    componentSerialNumber: 13960059,
    equipmentKey: '15'
  },
  {
    componentID: '1022850784',
    componentInstallationDate: new Date(2008, 2, 9),
    componentMaterialNumber: 1234,
    componentName: 'Assy, MBM200, SC2000',
    componentOwnID: '-',
    componentSerialNumber: 12263312,
    equipmentKey: '15'
  },
  {
    componentID: '3000869541',
    componentInstallationDate: new Date(2005, 9, 23),
    componentMaterialNumber: 1234,
    componentName: '3rd Array port Option, X300',
    componentOwnID: '-',
    componentSerialNumber: 213,
    equipmentKey: '16'
  },
  {
    componentID: '3000869543',
    componentInstallationDate: new Date(2005, 9, 23),
    componentMaterialNumber: 1234,
    componentName: 'Basic Cardiac Option',
    componentOwnID: '-',
    componentSerialNumber: 310104,
    equipmentKey: '16'
  },
  {
    componentID: '1019590099',
    componentInstallationDate: new Date(2005, 9, 23),
    componentMaterialNumber: 1234,
    componentName: 'CH5-2 Transducer',
    componentOwnID: '-',
    componentSerialNumber: 13860006,
    equipmentKey: '16'
  },
  {
    componentID: '10310218',
    componentInstallationDate: new Date(2015, 3, 24),
    componentMaterialNumber: 1234,
    componentName: 'FILTER RGT 250 MICRON',
    componentOwnID: '-',
    componentSerialNumber: 5172,
    equipmentKey: '17'
  },
  {
    componentID: '10807448',
    componentInstallationDate: new Date(2015, 3, 24),
    componentMaterialNumber: 1234,
    componentName: 'C-PUMP ASSY REAGENT FLUIDICS',
    componentOwnID: '-',
    componentSerialNumber: 16500209,
    equipmentKey: '17'
  },
  {
    componentID: '10807478',
    componentInstallationDate: new Date(2015, 3, 24),
    componentMaterialNumber: 1234,
    componentName: 'C-IMT MODULE',
    componentOwnID: '-',
    componentSerialNumber: 5314300,
    equipmentKey: '17'
  },
  {
    componentID: '10283431',
    componentInstallationDate: new Date(2012, 9, 21),
    componentMaterialNumber: 1234,
    componentName: 'ASSY 3-WAY VALVE',
    componentOwnID: '-',
    componentSerialNumber: 3505,
    equipmentKey: '18'
  },
  {
    componentID: '10814595',
    componentInstallationDate: new Date(2012, 9, 21),
    componentMaterialNumber: 1234,
    componentName: 'PRINTER LASER MS510 110V',
    componentOwnID: '-',
    componentSerialNumber: 13648200,
    equipmentKey: '18'
  },
  {
    componentID: '10324448',
    componentInstallationDate: new Date(2012, 9, 21),
    componentMaterialNumber: 1234,
    componentName: 'COVER LUMO',
    componentOwnID: '-',
    componentSerialNumber: 535330,
    equipmentKey: '18'
  },
  {
    componentID: '1076722517',
    componentInstallationDate: new Date(2018, 4, 21),
    componentMaterialNumber: 1234,
    componentName: 'ATELLICA IM 1600 ANALYZER',
    componentOwnID: '-',
    componentSerialNumber: 1037,
    equipmentKey: '30'
  },
  {
    componentID: '1084961234',
    componentInstallationDate: new Date(2017, 6, 1),
    componentMaterialNumber: 1234,
    componentName: 'Atellica UAS Main Unit Analyzer',
    componentOwnID: '-',
    componentSerialNumber: 1076,
    equipmentKey: '31'
  },
  {
    componentID: '1084961235',
    componentInstallationDate: new Date(2017, 6, 1),
    componentMaterialNumber: 1234,
    componentName: 'Connected Rack handler For UAS',
    componentOwnID: '-',
    componentSerialNumber: 30075,
    equipmentKey: '31'
  },
  {
    componentID: '1030125789',
    componentInstallationDate: new Date(2016, 5, 5),
    componentMaterialNumber: 1234,
    componentName: 'DAP chamber',
    componentOwnID: '-',
    componentSerialNumber: 1189,
    equipmentKey: '20'
  },
  {
    componentID: '1030125790',
    componentInstallationDate: new Date(2016, 5, 5),
    componentMaterialNumber: 1234,
    componentName: 'FL-C image processor Mira Max',
    componentOwnID: '-',
    componentSerialNumber: 1190,
    equipmentKey: '20'
  },
  {
    componentID: '1030125791',
    componentInstallationDate: new Date(2016, 5, 5),
    componentMaterialNumber: 1234,
    componentName: 'MAX wi-D 3543EZh',
    componentOwnID: '-',
    componentSerialNumber: 1191,
    equipmentKey: '20'
  },
  {
    componentID: '1030125792',
    componentInstallationDate: new Date(2016, 3, 2),
    componentMaterialNumber: 1234,
    componentName: 'DAP S1',
    componentOwnID: '-',
    componentSerialNumber: 1120,
    equipmentKey: '21'
  },
  {
    componentID: '1030125793',
    componentInstallationDate: new Date(2016, 3, 2),
    componentMaterialNumber: 1234,
    componentName: 'Color Monitor TFT 19 MX191',
    componentOwnID: '-',
    componentSerialNumber: 1121,
    equipmentKey: '21'
  },
  {
    componentID: '1030125793',
    componentInstallationDate: new Date(2016, 3, 2),
    componentMaterialNumber: 1234,
    componentName: 'Color Monitor TFT 19 MX191',
    componentOwnID: '-',
    componentSerialNumber: 1121,
    equipmentKey: '21'
  },
  {
    componentID: '1030149811',
    componentInstallationDate: new Date(2012, 7, 1),
    componentMaterialNumber: 1234,
    componentName: 'ADVIA 2120i W/DUAL ASP. AUTOSAMPLER',
    componentOwnID: '-',
    componentSerialNumber: 1654846,
    equipmentKey: '22'
  },
  {
    componentID: '1030149812',
    componentInstallationDate: new Date(2012, 7, 1),
    componentMaterialNumber: 1234,
    componentName: 'ADVIA AUTOSLIDE ASSEMBLY',
    componentOwnID: '-',
    componentSerialNumber: 1654648,
    equipmentKey: '22'
  },
  {
    componentID: '1035577136',
    componentInstallationDate: new Date(2018, 6, 5),
    componentMaterialNumber: 1234,
    componentName: 'AEC Chambre 5 fields',
    componentOwnID: '-',
    componentSerialNumber: 1102,
    equipmentKey: '23'
  },
  {
    componentID: '1035577137',
    componentInstallationDate: new Date(2018, 6, 5),
    componentMaterialNumber: 1234,
    componentName: 'Collimator AL02 II eL',
    componentOwnID: '-',
    componentSerialNumber: 1103,
    equipmentKey: '23'
  },
  {
    componentID: '1035577138',
    componentInstallationDate: new Date(2018, 6, 5),
    componentMaterialNumber: 1234,
    componentName: 'Color Monitor TFT 19 MX191',
    componentOwnID: '-',
    componentSerialNumber: 1104,
    equipmentKey: '23'
  },
  {
    componentID: '1035546815',
    componentInstallationDate: new Date(2011, 8, 6),
    componentMaterialNumber: 1234,
    componentName: 'IMMULITE 1000 INSTRUMENT',
    componentOwnID: '-',
    componentSerialNumber: 2599,
    equipmentKey: '24'
  },
  {
    componentID: '1035541623',
    componentInstallationDate: new Date(2012, 9, 21),
    componentMaterialNumber: 1234,
    componentName: 'ALIQUOTER MODULE (AM)',
    componentOwnID: '-',
    componentSerialNumber: 1156,
    equipmentKey: '26'
  },
  {
    componentID: '1035541624',
    componentInstallationDate: new Date(2012, 9, 21),
    componentMaterialNumber: 1234,
    componentName: 'CENTRIFUGE MODULE (CM)',
    componentOwnID: '-',
    componentSerialNumber: 1407,
    equipmentKey: '26'
  },
  {
    componentID: '1035541624',
    componentInstallationDate: new Date(2012, 9, 21),
    componentMaterialNumber: 1234,
    componentName: 'CENTRIFUGE MODULE (CM)',
    componentOwnID: '-',
    componentSerialNumber: 1408,
    equipmentKey: '26'
  },
  {
    componentID: '1035541624',
    componentInstallationDate: new Date(2012, 9, 21),
    componentMaterialNumber: 1234,
    componentName: 'CENTRIFUGE MODULE (CM)',
    componentOwnID: '-',
    componentSerialNumber: 1409,
    equipmentKey: '26'
  },
  {
    componentID: '1035541625',
    componentInstallationDate: new Date(2012, 9, 1),
    componentMaterialNumber: 1234,
    componentName: 'DECAPPER (DEC)',
    componentOwnID: '-',
    componentSerialNumber: 5896,
    equipmentKey: '26'
  },
  {
    componentID: '1035541337',
    componentInstallationDate: new Date(2017, 12, 1),
    componentMaterialNumber: 1234,
    componentName: 'KermaX-plus Measuring Device',
    componentOwnID: '-',
    componentSerialNumber: 1337,
    equipmentKey: '27'
  },
  {
    componentID: '1035541338',
    componentInstallationDate: new Date(2017, 12, 1),
    componentMaterialNumber: 1234,
    componentName: 'Multileaf collimator N FD II eL',
    componentOwnID: '-',
    componentSerialNumber: 1945,
    equipmentKey: '27'
  },
  {
    componentID: '1035541339',
    componentInstallationDate: new Date(2017, 12, 1),
    componentMaterialNumber: 1234,
    componentName: 'OPTITOP 150/40/80HC-100',
    componentOwnID: '-',
    componentSerialNumber: 1502,
    equipmentKey: '27'
  },
  {
    componentID: '1052001337',
    componentInstallationDate: new Date(2017, 3, 2),
    componentMaterialNumber: 1234,
    componentName: 'Detector stand 3D V G1',
    componentOwnID: '-',
    componentSerialNumber: 4995,
    equipmentKey: '28'
  },
  {
    componentID: '1052001338',
    componentInstallationDate: new Date(2017, 3, 2),
    componentMaterialNumber: 1234,
    componentName: 'Detector Supply Module',
    componentOwnID: '-',
    componentSerialNumber: 4812,
    equipmentKey: '28'
  },
  {
    componentID: '1052001339',
    componentInstallationDate: new Date(2017, 3, 2),
    componentMaterialNumber: 1234,
    componentName: 'KermaX-plus HS integrated',
    componentOwnID: '-',
    componentSerialNumber: 3654,
    equipmentKey: '28'
  },
  {
    componentID: '1011574589',
    componentInstallationDate: new Date(2012, 3, 3),
    componentMaterialNumber: 1234,
    componentName: 'COAG ANAL CA-1500 WITH BR',
    componentOwnID: '-',
    componentSerialNumber: 8018,
    equipmentKey: '29'
  },
  {
    componentID: '1011574590',
    componentInstallationDate: new Date(2012, 3, 3),
    componentMaterialNumber: 1234,
    componentName: 'SAMPLER OPSU-6 (CA1500) RECONDITION',
    componentOwnID: '-',
    componentSerialNumber: 4165,
    equipmentKey: '29'
  },

  {
    componentID: '1039844001',
    componentInstallationDate: new Date(2018, 10, 1),
    componentMaterialNumber: 1234,
    componentName: '5C1 Transducer (MP456)N',
    componentOwnID: '-',
    componentSerialNumber: 1001,
    equipmentKey: '25'
  },
  {
    componentID: '1039844002',
    componentInstallationDate: new Date(2018, 10, 1),
    componentMaterialNumber: 1234,
    componentName: 'Common Physio Module',
    componentOwnID: '-',
    componentSerialNumber: 1002,
    equipmentKey: '25'
  },
  {
    componentID: '1039844003',
    componentInstallationDate: new Date(2018, 10, 1),
    componentMaterialNumber: 1234,
    componentName: 'Monitor, OLED',
    componentOwnID: '-',
    componentSerialNumber: 1003,
    equipmentKey: '25'
  },
  {
    componentID: '1084964321',
    componentInstallationDate: new Date(2017, 3, 2),
    componentMaterialNumber: 1234,
    componentName: 'Dimension XPAND Main Unit Analyzer',
    componentOwnID: '-',
    componentSerialNumber: 1337,
    equipmentKey: '19'
  },
  {
    componentID: '1041021997',
    componentInstallationDate: new Date(2017, 5, 23),
    componentMaterialNumber: 1234,
    componentName: 'PICTools Libr. 64 bit V 2.0',
    componentOwnID: '-',
    componentSerialNumber: 0,
    equipmentKey: '32'
  },
  {
    componentID: '1041021998',
    componentInstallationDate: new Date(2017, 5, 23),
    componentMaterialNumber: 1234,
    componentName: 'syngo Prism VA11A - VA14A',
    componentOwnID: '-',
    componentSerialNumber: 0,
    equipmentKey: '32'
  },
  {
    componentID: '1041021999',
    componentInstallationDate: new Date(2017, 5, 23),
    componentMaterialNumber: 1234,
    componentName: 'teamplay Images VC30A',
    componentOwnID: '-',
    componentSerialNumber: 0,
    equipmentKey: '32'
  },
  {
    componentID: '1033146000',
    componentInstallationDate: new Date(2015, 11, 24),
    componentMaterialNumber: 1234,
    componentName: 'AccuSoft ImageGear 99 ProGold (Lic)',
    componentOwnID: '-',
    componentSerialNumber: 0,
    equipmentKey: '5'
  },
  {
    componentID: '1033146001',
    componentInstallationDate: new Date(2015, 11, 24),
    componentMaterialNumber: 1234,
    componentName: 's.plaza Single Server ID >VB10A',
    componentOwnID: '-',
    componentSerialNumber: 0,
    equipmentKey: '5'
  },
  {
    componentID: '1033146002',
    componentInstallationDate: new Date(2015, 11, 24),
    componentMaterialNumber: 1234,
    componentName: 's.plaza WP SK-Gen Single-Serv',
    componentOwnID: '-',
    componentSerialNumber: 0,
    equipmentKey: '5'
  },
  {
    componentID: '1033146100',
    componentInstallationDate: new Date(2016, 1, 12),
    componentMaterialNumber: 1234,
    componentName: 'HP Workstation',
    componentOwnID: '-',
    componentSerialNumber: 0,
    equipmentKey: '4'
  },
  {
    componentID: '1033146101',
    componentInstallationDate: new Date(2016, 1, 12),
    componentMaterialNumber: 1234,
    componentName: 'L Server ML350 G8, OS 2012',
    componentOwnID: '-',
    componentSerialNumber: 0,
    equipmentKey: '4'
  },
  {
    componentID: '1033146102',
    componentInstallationDate: new Date(2016, 1, 12),
    componentMaterialNumber: 1234,
    componentName: 'Monitor Color MX241W',
    componentOwnID: '-',
    componentSerialNumber: 0,
    equipmentKey: '4'
  }
];

export const CdEquipmentDetails: EquipmentDetails = {
  attachments: [],
  components: CdequipmentComponentItemList
};

// options & upgrades
export const CdLifenetSystemCustom: LifenetSystemCustom = {
  vbloGroupsCustom: [
    {
      vblos: [
        {
          name: '',
          tlStatus: '',
          optionStatus: '1'
        }
      ],
      handOutGroup: {
        clinicalFields: [],
        groupName: '',
        disclaimer: '',
        groupLinkDTQ: '',
        vBloPictureId: '',
        imageIsAvailable: false,
        descriptionIsAvailable: false,
        disclaimerIsAvailable: false,
        optionDescription: 'string'
      }
    }
  ]
};

export const CdLifenetSystemCustomList: CdLifenetSystemCustom[] = [
  {
    id: '1',
    value: [
      {
        vbloGroupsCustom: [
          {
            vblos: [
              {
                name: '100 kW Power',
                tlStatus: '',
                optionStatus: 'AlreadyInstalled'
              }
            ],
            handOutGroup: {
              clinicalFields: [
                {
                  name: 'CLINICAL_FIELD_CARDIOVASCULAR',
                  id: '1'
                }
              ],
              groupName: '',
              disclaimer: 'Option/Upgrade is already installed',
              groupLinkDTQ: '',
              vBloPictureId: '',
              imageIsAvailable: true,
              descriptionIsAvailable: false,
              disclaimerIsAvailable: false,
              optionDescription:
                'The 100 kW power allows the X-ray generator the use of maximum power of 100kW in fine adjustable steps.'
            }
          },
          {
            vblos: [
              {
                name: 'Adaptive 3D Intervention',
                tlStatus: '',
                optionStatus: 'WithPrerequisites'
              }
            ],
            handOutGroup: {
              clinicalFields: [
                {
                  name: 'CLINICAL_FIELD_CARDIOVASCULAR',
                  id: '1'
                }
              ],
              groupName: '',
              disclaimer: 'Option/Upgrade with prerequites',
              groupLinkDTQ: '',
              vBloPictureId: '',
              imageIsAvailable: true,
              descriptionIsAvailable: false,
              disclaimerIsAvailable: false,
              optionDescription:
                'Adaptive 3D Intervention can allow you to see the whole organ using a VRT and view the lesion with sagittal, coronal, and axial MPRs. Always be on track with our smart automatic needle-detection algorithms and path planning tools. They automatically select the optimal needle viewing plane and entry point for you and determine the angle for needle insertion. This 3D capability allows for fast and accurate positioning in the most complex anatomies, even at difficult oblique angles.'
            }
          },
          {
            vblos: [
              {
                name: 'AWP Tower 12 Upgrade',
                tlStatus: '',
                optionStatus: 'WithoutPrerequisites'
              }
            ],
            handOutGroup: {
              clinicalFields: [
                {
                  name: 'CLINICAL_FIELD_CARDIOVASCULAR',
                  id: '1'
                }
              ],
              groupName: '',
              disclaimer: 'Option/Upgrade without prerequites',
              groupLinkDTQ: '',
              vBloPictureId: '',
              imageIsAvailable: true,
              descriptionIsAvailable: false,
              disclaimerIsAvailable: false,
              optionDescription:
                'Upgrade to new AWP computer for image acquisition; includes high performance CPU and graphics accelerator.'
            }
          }
        ]
      }
    ]
  },
  {
    id: '2',
    value: [
      {
        vbloGroupsCustom: [
          {
            vblos: [
              {
                name: '16-ch Sentinelle Breast Coil',
                tlStatus: '',
                optionStatus: 'WithoutPrerequisites'
              }
            ],
            handOutGroup: {
              clinicalFields: [
                {
                  name: 'CLINICAL_FIELD_CARDIOVASCULAR',
                  id: '1'
                }
              ],
              groupName: '',
              disclaimer: 'Option/Upgrade without prerequites',
              groupLinkDTQ: '',
              vBloPictureId: '',
              imageIsAvailable: true,
              descriptionIsAvailable: false,
              disclaimerIsAvailable: false,
              optionDescription:
                'The 16-channel Sentinelle Breast Coil can be used as an imaging coil and consists of a positioning frame with two lateral 4-channel coil elements and an 8-channel coil middle element. The coil is iPAT compatible.'
            }
          },
          {
            vblos: [
              {
                name: '16-ch AI Breast Coil ',
                tlStatus: '',
                optionStatus: 'WithPrerequisites'
              }
            ],
            handOutGroup: {
              clinicalFields: [
                {
                  name: 'CLINICAL_FIELD_CARDIOVASCULAR',
                  id: '1'
                }
              ],
              groupName: '',
              disclaimer: 'Option/Upgrade with prerequites',
              groupLinkDTQ: '',
              vBloPictureId: '',
              imageIsAvailable: true,
              descriptionIsAvailable: false,
              disclaimerIsAvailable: false,
              optionDescription: 'High-resolution breast imaging with iPAT factor up to 4'
            }
          },
          {
            vblos: [
              {
                name: '2/10/16-ch Sentielle Breast Coil',
                tlStatus: '',
                optionStatus: 'WithoutPrerequisites'
              }
            ],
            handOutGroup: {
              clinicalFields: [
                {
                  name: 'CLINICAL_FIELD_CARDIOVASCULAR',
                  id: '1'
                }
              ],
              groupName: '',
              disclaimer: 'Option/Upgrade without prerequites',
              groupLinkDTQ: '',
              vBloPictureId: '',
              imageIsAvailable: true,
              descriptionIsAvailable: false,
              disclaimerIsAvailable: false,
              optionDescription:
                'The 2/10/16-channel Sentielle Breast Coil can be used as a breast imaging coil, a bilateral biopsy coil as well as an unilateral biopsy coil providing large biopsy access. The coil is iPAT compatible.'
            }
          }
        ]
      }
    ]
  },
  {
    id: '3',
    value: [
      {
        vbloGroupsCustom: [
          {
            vblos: [
              {
                name: '16-ch Sentinelle Breast Coil',
                tlStatus: '',
                optionStatus: 'WithoutPrerequisites'
              }
            ],
            handOutGroup: {
              clinicalFields: [
                {
                  name: 'CLINICAL_FIELD_CARDIOVASCULAR',
                  id: '1'
                }
              ],
              groupName: '',
              disclaimer: 'Option/Upgrade without prerequites',
              groupLinkDTQ: '',
              vBloPictureId: '',
              imageIsAvailable: true,
              descriptionIsAvailable: false,
              disclaimerIsAvailable: false,
              optionDescription:
                'The 16-channel Sentinelle Breast Coil can be used as an imaging coil and consists of a positioning frame with two lateral 4-channel coil elements and an 8-channel coil middle element. The coil is iPAT compatible.'
            }
          },
          {
            vblos: [
              {
                name: '16-ch AI Breast Coil ',
                tlStatus: '',
                optionStatus: 'WithPrerequisites'
              }
            ],
            handOutGroup: {
              clinicalFields: [
                {
                  name: 'CLINICAL_FIELD_CARDIOVASCULAR',
                  id: '1'
                }
              ],
              groupName: '',
              disclaimer: 'Option/Upgrade with prerequites',
              groupLinkDTQ: '',
              vBloPictureId: '',
              imageIsAvailable: true,
              descriptionIsAvailable: false,
              disclaimerIsAvailable: false,
              optionDescription: 'High-resolution breast imaging with iPAT factor up to 4'
            }
          },
          {
            vblos: [
              {
                name: '2/10/16-ch Sentielle Breast Coil',
                tlStatus: '',
                optionStatus: 'WithoutPrerequisites'
              }
            ],
            handOutGroup: {
              clinicalFields: [
                {
                  name: 'CLINICAL_FIELD_CARDIOVASCULAR',
                  id: '1'
                }
              ],
              groupName: '',
              disclaimer: 'Option/Upgrade without prerequites',
              groupLinkDTQ: '',
              vBloPictureId: '',
              imageIsAvailable: true,
              descriptionIsAvailable: false,
              disclaimerIsAvailable: false,
              optionDescription:
                'The 2/10/16-channel Sentielle Breast Coil can be used as a breast imaging coil, a bilateral biopsy coil as well as an unilateral biopsy coil providing large biopsy access. The coil is iPAT compatible.'
            }
          }
        ]
      }
    ]
  },
  {
    id: '4',
    value: [
      {
        vbloGroupsCustom: [
          {
            vblos: [
              {
                name: 'syngo.Breast Care CAD Display #1',
                tlStatus: '',
                optionStatus: 'WithoutPrerequisites'
              }
            ],
            handOutGroup: {
              clinicalFields: [
                {
                  name: 'CLINICAL_FIELD_CARDIOVASCULAR',
                  id: '1'
                }
              ],
              groupName: '',
              disclaimer: 'Option/Upgrade without prerequisites',
              groupLinkDTQ: '',
              vBloPictureId: '',
              imageIsAvailable: true,
              descriptionIsAvailable: false,
              disclaimerIsAvailable: false,
              optionDescription:
                'syngo.Breast Care CAD Display is an add-on for advanced mammography reading. It enables different workflows to shape your personal preferences including CAD markers.'
            }
          },
          {
            vblos: [
              {
                name: 'syngo.CT Cardiac Func. Enhance #1',
                tlStatus: '',
                optionStatus: 'WithoutPrerequisites'
              }
            ],
            handOutGroup: {
              clinicalFields: [
                {
                  name: 'CLINICAL_FIELD_CARDIOVASCULAR',
                  id: '1'
                }
              ],
              groupName: '',
              disclaimer: 'Option/Upgrade without prerequisites',
              groupLinkDTQ: '',
              vBloPictureId: '',
              imageIsAvailable: true,
              descriptionIsAvailable: false,
              disclaimerIsAvailable: false,
              optionDescription:
                'syngo.CT Cardiac Function - Enhancement allows the visualization of ischemia from early or late enhanced images.'
            }
          }
        ]
      }
    ]
  },
  {
    id: '8',
    value: [
      {
        vbloGroupsCustom: [
          {
            vblos: [
              {
                name: 'HD PET Package Promotion',
                tlStatus: '',
                optionStatus: 'WithPrerequisites'
              }
            ],
            handOutGroup: {
              clinicalFields: [
                {
                  name: 'CLINICAL_FIELD_CARDIOVASCULAR',
                  id: '1'
                }
              ],
              groupName: '',
              disclaimer: 'Option/Upgrade without prerequisites',
              groupLinkDTQ: '',
              vBloPictureId: '',
              imageIsAvailable: true,
              descriptionIsAvailable: false,
              disclaimerIsAvailable: false,
              optionDescription:
                'HD•PET incorporates measured point spread functions (PSF) into the iterative reconstruction algorithm. Through modeling of the PSF, HD•PET more precisely accounts for the positioning of the LOR yielding visually sharper clinical images.'
            }
          }
        ]
      }
    ]
  },
  {
    id: '10',
    value: [
      {
        vbloGroupsCustom: [
          {
            vblos: [
              {
                name: 'DSA/DR acquisition mode',
                tlStatus: '',
                optionStatus: 'AlreadyInstalled'
              }
            ],
            handOutGroup: {
              clinicalFields: [
                {
                  name: 'CLINICAL_FIELD_CARDIOVASCULAR',
                  id: '1'
                }
              ],
              groupName: '',
              disclaimer: 'Option/Upgrade is already installed',
              groupLinkDTQ: '',
              vBloPictureId: '',
              imageIsAvailable: true,
              descriptionIsAvailable: false,
              disclaimerIsAvailable: false,
              optionDescription: 'Digital acquisition technology and digital subtraction angiography in matrix 1k.'
            }
          }
        ]
      }
    ]
  },
  {
    id: '11',
    value: [
      {
        vbloGroupsCustom: [
          {
            vblos: [
              {
                name: 'DSA/DR acquisition mode',
                tlStatus: '',
                optionStatus: 'AlreadyInstalled'
              }
            ],
            handOutGroup: {
              clinicalFields: [
                {
                  name: 'CLINICAL_FIELD_CARDIOVASCULAR',
                  id: '1'
                }
              ],
              groupName: '',
              disclaimer: 'Option/Upgrade is already installed',
              groupLinkDTQ: '',
              vBloPictureId: '',
              imageIsAvailable: true,
              descriptionIsAvailable: false,
              disclaimerIsAvailable: false,
              optionDescription: 'Digital acquisition technology and digital subtraction angiography in matrix 1k.'
            }
          }
        ]
      }
    ]
  },
  {
    id: '12',
    value: [
      {
        vbloGroupsCustom: [
          {
            vblos: [
              {
                name: 'DSA/DR acquisition mode',
                tlStatus: '',
                optionStatus: 'AlreadyInstalled'
              }
            ],
            handOutGroup: {
              clinicalFields: [
                {
                  name: 'CLINICAL_FIELD_CARDIOVASCULAR',
                  id: '1'
                }
              ],
              groupName: '',
              disclaimer: 'Option/Upgrade is already installed',
              groupLinkDTQ: '',
              vBloPictureId: '',
              imageIsAvailable: true,
              descriptionIsAvailable: false,
              disclaimerIsAvailable: false,
              optionDescription: 'Digital acquisition technology and digital subtraction angiography in matrix 1k.'
            }
          }
        ]
      }
    ]
  },
  {
    id: '13',
    value: [
      {
        vbloGroupsCustom: [
          {
            vblos: [
              {
                name: 'Adaptive 4D Spiral',
                tlStatus: '',
                optionStatus: 'WithoutPrerequisites'
              }
            ],
            handOutGroup: {
              clinicalFields: [
                {
                  name: 'CLINICAL_FIELD_CARDIOVASCULAR',
                  id: '1'
                }
              ],
              groupName: '',
              disclaimer: 'Option/Upgrade is already installed',
              groupLinkDTQ: '',
              vBloPictureId: '',
              imageIsAvailable: true,
              descriptionIsAvailable: false,
              disclaimerIsAvailable: false,
              optionDescription:
                'The unique Adaptive 4D Spiral moves beyond fixed detector limitations. This allows dynamic CT imaging to provide full coverage of any organ.'
            }
          },
          {
            vblos: [
              {
                name: 'Adaptive 3D Intervention',
                tlStatus: '',
                optionStatus: 'WithoutPrerequisites'
              }
            ],
            handOutGroup: {
              clinicalFields: [
                {
                  name: 'CLINICAL_FIELD_CARDIOVASCULAR',
                  id: '1'
                }
              ],
              groupName: '',
              disclaimer: 'Option/Upgrade without prerequisites',
              groupLinkDTQ: '',
              vBloPictureId: '',
              imageIsAvailable: true,
              descriptionIsAvailable: false,
              disclaimerIsAvailable: false,
              optionDescription:
                'Adaptive 3D Intervention can allow you to see the whole organ using a VRT and view the lesion with sagittal, coronal, and axial MPRs. Always be on track with our smart automatic needle-detection algorithms and path planning tools. They automatically select the optimal needle viewing plane and entry point for you and determine the angle for needle insertion. This 3D capability allows for fast and accurate positioning in the most complex anatomies, even at difficult oblique angles.'
            }
          }
        ]
      }
    ]
  },
  {
    id: '14',
    value: [
      {
        vbloGroupsCustom: [
          {
            vblos: [
              {
                name: 'Adaptive 4D Spiral',
                tlStatus: '',
                optionStatus: 'WithoutPrerequisites'
              }
            ],
            handOutGroup: {
              clinicalFields: [
                {
                  name: 'CLINICAL_FIELD_CARDIOVASCULAR',
                  id: '1'
                }
              ],
              groupName: '',
              disclaimer: 'Option/Upgrade without prerequisites',
              groupLinkDTQ: '',
              vBloPictureId: '',
              imageIsAvailable: true,
              descriptionIsAvailable: false,
              disclaimerIsAvailable: false,
              optionDescription:
                'The unique Adaptive 4D Spiral moves beyond fixed detector limitations. This allows dynamic CT imaging to provide full coverage of any organ.'
            }
          },
          {
            vblos: [
              {
                name: 'Adaptive 3D Intervention',
                tlStatus: '',
                optionStatus: 'WithoutPrerequisites'
              }
            ],
            handOutGroup: {
              clinicalFields: [
                {
                  name: 'CLINICAL_FIELD_CARDIOVASCULAR',
                  id: '1'
                }
              ],
              groupName: '',
              disclaimer: 'Option/Upgrade without prerequisites',
              groupLinkDTQ: '',
              vBloPictureId: '',
              imageIsAvailable: true,
              descriptionIsAvailable: false,
              disclaimerIsAvailable: false,
              optionDescription:
                'Adaptive 3D Intervention can allow you to see the whole organ using a VRT and view the lesion with sagittal, coronal, and axial MPRs. Always be on track with our smart automatic needle-detection algorithms and path planning tools. They automatically select the optimal needle viewing plane and entry point for you and determine the angle for needle insertion. This 3D capability allows for fast and accurate positioning in the most complex anatomies, even at difficult oblique angles.'
            }
          }
        ]
      }
    ]
  }
];

interface CdLifenetSystemCustom {
  id: string; // equipmentkey
  value: LifenetSystemCustom[];
}

export const CdEquipment: Equipment = {
  customerId: '1',
  key: 'US_12345678',
  location: '004-0034358465-001-016-004-001',
  siemensId: 'Siemens ID 12345680',
  countryCode: 'DE',
  equipmentNumber: '12345678',
  customerNumber: '1234567',
  customerName: 'OHIO STATE UNIVERSITY HOSPITAL',
  businessUnit: 'IKM',
  modality: '1',
  modalityTranslation: '',
  materialNumber: '000000000010496180',
  serialNumber: '100103',
  partNumber: 'KUNDENNR:0034358465',
  isSharedService: false,
  endOfSupport: null,
  installedOn: '2010-11-11T00:00:00',
  warrantyStart: '2010-11-11T00:00:00',
  warrantyEnd: '2011-11-11T00:00:00',
  contractNumber: '0010043695',
  contractDetails: null,
  contractGroupNumber: null,
  contractType: 'IT CARE PLAN ALL',
  isActive: true,
  lastRetrieved: '2017-06-28T21:58:46',
  softwareVersion: 'VB10A_HF03',
  sapAssetNumber: null,
  productName: 'Product name 12345680',
  city: 'OHIO',
  street: 'Hoppe-Seyler-Str. 3',
  zip: '72076',
  department: null,
  district: 'Nordstadt',
  myEquipmentName: 'My Equipment 12345680',
  customerDescription: '',
  state: '',
  soldTo: '',
  mobile: false
};

export const CdEquipmentList: Equipment[] = [
  // CdEquipment,
  {
    ...CdEquipment,
    key: '1',
    equipmentNumber: '00000001',
    location: 'Siemens Healthcare',
    siemensId: '64073',
    serialNumber: 'CT0221-01',
    installedOn: '2008-09-01',
    warrantyStart: '2008-09-01',
    warrantyEnd: '2009-08-31',
    contractNumber: '201031836',
    contractType: '-',
    softwareVersion: 'VA44A',
    productName: 'Somatom Definition AS+',
    city: 'Erlangen',
    street: 'Hartmannstr. 16',
    zip: '91052',
    customerName: 'Siemens Healthineers 3',
    customerNumber: '2SIMULATOR',
    department: 'Radiology',
    myEquipmentName: 'Somatom Definition AS+',
    state: 'green',
    modality: '05',
    modalityTranslation: 'Syngo Pacs'
  },
  {
    ...CdEquipment,
    key: '2',
    equipmentNumber: '00000002',
    location: 'Siemens Healthcare',
    siemensId: '46081',
    serialNumber: 'MR1210-01',
    installedOn: '2015-10-13',
    warrantyStart: '2015-10-13',
    warrantyEnd: '2016-10-12',
    contractNumber: '056984512',
    contractType: 'TOP Contract',
    softwareVersion: 'E11C',
    productName: 'MAGNETOM Skyra',
    city: 'Erlangen',
    street: 'Henri-Dunant Str. 50',
    zip: '91058',
    customerName: 'Siemens Healthineers',
    customerNumber: '0SIMULATOR',
    department: 'Radiology',
    myEquipmentName: 'MR 1',
    state: 'green',
    modality: '11'
  },
  {
    ...CdEquipment,
    key: '3',
    equipmentNumber: '00000003',
    location: 'Siemens Healthcare',
    siemensId: '74014',
    serialNumber: 'MR1170-01',
    installedOn: '2015-07-10',
    warrantyStart: '2015-07-10',
    warrantyEnd: '2016-07-09',
    contractNumber: '8300548884',
    contractType: 'TOP Contract',
    softwareVersion: 'E11',
    productName: 'MAGNETOM Amira',
    city: 'Erlangen',
    street: 'Hartmannstr. 16',
    zip: '91052',
    customerName: 'Siemens Healthineers 3',
    customerNumber: '2SIMULATOR',
    department: 'Radiology',
    myEquipmentName: 'MR 2',
    state: 'red'
  },
  {
    ...CdEquipment,
    key: '4',
    equipmentNumber: '00000004',
    location: 'Siemens Healthcare',
    siemensId: '46081',
    serialNumber: 'SY1190-01',
    installedOn: '2016-01-12',
    warrantyStart: '2016-01-12',
    warrantyEnd: '2017-01-11',
    contractNumber: '5440000355',
    contractType: 'IT Care Plan',
    softwareVersion: 'VA30A-HF05',
    productName: 'syngo.via',
    city: 'Forchheim',
    street: 'Siemensstr. 3',
    zip: '91301',
    customerName: 'Siemens Healthineers 2',
    customerNumber: '1SIMULATOR',
    department: 'Radiology',
    myEquipmentName: 'syngo 1',
    state: 'green',
    materialNumber: '0000015'
  },
  {
    ...CdEquipment,
    key: '5',
    equipmentNumber: '00000005',
    location: 'Siemens Healthcare',
    siemensId: '40305',
    serialNumber: 'SY1116-01',
    installedOn: '2015-11-24',
    warrantyStart: '2015-11-24',
    warrantyEnd: '2016-11-23',
    contractNumber: '7600003971',
    contractType: 'IT Care Plan',
    softwareVersion: 'VB20A',
    productName: 'syngo.plaza',
    city: 'Erlangen',
    street: 'Henri-Dunant Str. 50',
    zip: '91058',
    customerName: 'Siemens Healthineers',
    customerNumber: '0SIMULATOR',
    department: 'Radiology',
    myEquipmentName: 'syngo 2',
    state: 'green',
    materialNumber: '0000015'
  },
  {
    ...CdEquipment,
    key: '6',
    location: 'Siemens Healthcare',
    siemensId: '4367',
    serialNumber: 'XP1764-01',
    installedOn: '2009-03-17',
    warrantyStart: '2009-03-17',
    warrantyEnd: '2010-03-16',
    contractNumber: '89000068269',
    contractType: '-',
    softwareVersion: 'VB30O',
    productName: 'MAMMOMAT Inspiration',
    city: 'Erlangen',
    street: 'Hartmannstr. 16',
    zip: '91052',
    customerName: 'Siemens Healthineers 3',
    customerNumber: '2SIMULATOR',
    department: 'Radiology',
    myEquipmentName: 'Mammo 1',
    state: 'green'
  },
  {
    ...CdEquipment,
    key: '7',
    location: 'Siemens Healthcare',
    siemensId: '24514',
    serialNumber: 'XP2038-01',
    installedOn: '2016-03-11',
    warrantyStart: '2016-03-11',
    warrantyEnd: '2017-03-10',
    contractNumber: '7600004332',
    contractType: 'Shared Service Basic',
    softwareVersion: 'VE10J',
    productName: 'Ysio Max',
    city: 'Erlangen',
    street: 'Henri-Dunant Str. 50',
    zip: '91058',
    customerName: 'Siemens Healthineers',
    customerNumber: '0SIMULATOR',
    department: 'Radiology',
    myEquipmentName: 'Ysio 1',
    state: 'green'
  },
  {
    ...CdEquipment,
    key: '8',
    location: 'Siemens Healthcare',
    siemensId: '1050',
    serialNumber: 'MI1133-01',
    installedOn: '2015-03-24',
    warrantyStart: '2015-03-24',
    warrantyEnd: '2016-03-23',
    contractNumber: '7600065347',
    contractType: 'Shared Service Basic',
    softwareVersion: 'VB10B',
    productName: 'Symbia evo',
    city: 'Erlangen',
    street: 'Hartmannstr. 16',
    zip: '91052',
    customerName: 'Siemens Healthineers 3',
    customerNumber: '2SIMULATOR',
    department: 'Radiology',
    myEquipmentName: 'MI 1',
    state: 'green'
  },
  {
    ...CdEquipment,
    key: '9',
    location: 'Siemens Healthcare',
    siemensId: '11044',
    serialNumber: 'MI1111-01',
    installedOn: '2011-05-06',
    warrantyStart: '2011-05-06',
    warrantyEnd: '2012-05-05',
    contractNumber: '6730005345',
    contractType: '-',
    softwareVersion: 'VG51B',
    productName: 'Biograph mCT',
    city: 'Forchheim',
    street: 'Siemensstr. 3',
    zip: '91301',
    customerName: 'Siemens Healthineers 2',
    customerNumber: '1SIMULATOR',
    department: 'Radiology',
    myEquipmentName: 'MI 2',
    state: 'green'
  },
  {
    ...CdEquipment,
    key: '10',
    location: 'Siemens Healthcare',
    siemensId: '82052',
    serialNumber: 'AT1837-01',
    installedOn: '2015-06-11',
    warrantyStart: '2015-06-11',
    warrantyEnd: '2016-06-10',
    contractNumber: '5344000533',
    contractType: 'Shared Service Basic',
    softwareVersion: 'VA10D',
    productName: 'Artis One',
    city: 'Erlangen',
    street: 'Henri-Dunant Str. 50',
    zip: '91058',
    customerName: 'Siemens Healthineers',
    customerNumber: '0SIMULATOR',
    department: 'Radiology',
    myEquipmentName: 'Artis 1',
    state: 'green'
  },
  {
    ...CdEquipment,
    key: '11',
    location: 'Siemens Healthcare',
    siemensId: '153284',
    serialNumber: 'AT2032-01',
    installedOn: '2013-02-20',
    warrantyStart: '2013-02-20',
    warrantyEnd: '2014-02-19',
    contractNumber: '6548883350',
    contractType: 'Shared Service Basic',
    softwareVersion: 'VD11B',
    productName: 'Artis Q.zen biplane',
    city: 'Erlangen',
    street: 'Hartmannstr. 16',
    zip: '91052',
    customerName: 'Siemens Healthineers 3',
    customerNumber: '2SIMULATOR',
    department: 'Radiology',
    myEquipmentName: 'Artis 2',
    state: 'green'
  },
  {
    ...CdEquipment,
    key: '12',
    location: 'Siemens Healthcare',
    siemensId: '135787',
    serialNumber: 'AT1798-01',
    installedOn: '2009-12-21',
    warrantyStart: '2009-12-21',
    warrantyEnd: '2010-12-20',
    contractNumber: '7652600650',
    contractType: '-',
    softwareVersion: 'VC21B',
    productName: 'Artis zee Floor',
    city: 'Forchheim',
    street: 'Siemensstr. 3',
    zip: '91301',
    customerName: 'Siemens Healthineers 2',
    customerNumber: '1SIMULATOR',
    department: 'Radiology',
    myEquipmentName: 'Artis 3',
    state: 'green'
  },
  {
    ...CdEquipment,
    key: '13',
    location: 'Siemens Healthcare',
    siemensId: '400987',
    serialNumber: 'CT1295-01',
    installedOn: '2013-12-06',
    warrantyStart: '2013-12-06',
    warrantyEnd: '2014-12-05',
    contractNumber: '7865600076',
    contractType: '-',
    softwareVersion: 'VA50A',
    productName: 'SOMATOM Force',
    city: 'Forchheim',
    street: 'Siemensstr. 3',
    zip: '91301',
    customerName: 'Siemens Healthineers 2',
    customerNumber: '1SIMULATOR',
    department: 'Radiology',
    myEquipmentName: 'CT 1',
    state: 'yellow'
  },
  {
    ...CdEquipment,
    key: '14',
    location: 'Siemens Healthcare',
    siemensId: '77710',
    serialNumber: 'CT1208-01',
    installedOn: '2012-09-21',
    warrantyStart: '2012-09-21',
    warrantyEnd: '2013-09-20',
    contractNumber: '7600042346',
    contractType: '-',
    softwareVersion: 'VC20B',
    productName: 'SOMATOM Perspective',
    city: 'Erlangen',
    street: 'Henri-Dunant Str. 50',
    zip: '91058',
    customerName: 'Siemens Healthineers',
    customerNumber: '0SIMULATOR',
    department: 'Radiology',
    myEquipmentName: 'CT 2',
    state: 'green'
  },
  {
    ...CdEquipment,
    key: '15',
    location: 'Siemens Healthcare',
    siemensId: '400987',
    serialNumber: 'US3445-01',
    installedOn: '2008-02-09',
    warrantyStart: '2008-02-09',
    warrantyEnd: '2009-02-08',
    contractNumber: '7600034210',
    contractType: '-',
    softwareVersion: '2.0.13',
    productName: 'ACUSON SC 2000',
    city: 'Erlangen',
    street: 'Hartmannstr. 16',
    zip: '91052',
    customerName: 'Siemens Healthineers 3',
    customerNumber: '2SIMULATOR',
    department: 'Radiology',
    myEquipmentName: 'US 1',
    state: 'yellow',
    materialNumber: '00000001'
  },
  {
    ...CdEquipment,
    key: '16',
    location: 'Siemens Healthcare',
    siemensId: '310104',
    serialNumber: 'US2515-01',
    installedOn: '2005-09-29',
    warrantyStart: '2005-09-29',
    warrantyEnd: '2006-09-28',
    contractNumber: '7605389006',
    contractType: '-',
    softwareVersion: '2.1.0D',
    productName: 'ACUSON X 300',
    city: 'Forchheim',
    street: 'Siemensstr. 3',
    zip: '91301',
    customerName: 'Siemens Healthineers 2',
    customerNumber: '1SIMULATOR',
    department: 'Radiology',
    myEquipmentName: 'US 2',
    state: 'green',
    materialNumber: '00000001'
  },
  {
    ...CdEquipment,
    key: '17',
    location: 'Siemens Healthcare',
    siemensId: '310856',
    serialNumber: 'LD6423-02',
    installedOn: '2010-10-30',
    warrantyStart: '2010-10-30',
    warrantyEnd: '2011-10-29',
    contractNumber: '7589647000',
    contractType: '-',
    softwareVersion: 'V3.6.2',
    productName: 'Dimension Vista 1500',
    city: 'Erlangen',
    street: 'Hartmannstr. 16',
    zip: '91052',
    customerName: 'Siemens Healthineers 3',
    customerNumber: '2SIMULATOR',
    department: 'Laboratory',
    myEquipmentName: 'Vista',
    state: 'green',
    materialNumber: '0000008'
  },
  {
    ...CdEquipment,
    key: '18',
    location: 'Siemens Healthcare',
    siemensId: '13571024',
    serialNumber: 'LD4502-01',
    installedOn: '2012-05-04',
    warrantyStart: '2012-05-04',
    warrantyEnd: '2013-05-03',
    contractNumber: '7658924582',
    contractType: '-',
    softwareVersion: 'V7.2',
    productName: 'ADVIA Centaur XP',
    city: 'Forchheim',
    street: 'Siemensstr. 3',
    zip: '91301',
    customerName: 'Siemens Healthineers 2',
    customerNumber: '1SIMULATOR',
    department: 'Laboratory',
    myEquipmentName: 'Centaur',
    state: 'green',
    materialNumber: '000003'
  },
  {
    ...CdEquipment,
    key: '19',
    location: 'Siemens Healthcare',
    siemensId: '37756',
    serialNumber: 'LD1504-01',
    installedOn: '2017-03-02',
    warrantyStart: '2017-03-02',
    warrantyEnd: '2018-03-01',
    contractNumber: '5489874516',
    contractType: 'TOP Contract',
    softwareVersion: '-',
    productName: 'Dimension XPAND',
    city: 'Forchheim',
    street: 'Siemensstr. 3',
    zip: '91301',
    customerName: 'Siemens Healthineers 2',
    customerNumber: '1SIMULATOR',
    department: 'Laboratory',
    myEquipmentName: 'LAB-Eq.182',
    state: 'green',
    materialNumber: '000009'
  },
  {
    ...CdEquipment,
    key: '20',
    location: 'Siemens Healthcare',
    siemensId: '93542',
    serialNumber: 'XP1502-01',
    installedOn: '2015-05-06',
    warrantyStart: '2015-05-06',
    warrantyEnd: '2016-05-05',
    contractNumber: '201031836',
    contractType: 'TOP Contract',
    softwareVersion: 'VE10S',
    productName: 'Mobilett Mira Max',
    city: 'Erlangen',
    street: 'Hartmannstr. 16',
    zip: '91052',
    customerName: 'Siemens Healthineers 3',
    customerNumber: '2SIMULATOR',
    department: 'Radiology',
    myEquipmentName: 'Mira',
    state: 'green',
    materialNumber: '000012'
  },
  {
    ...CdEquipment,
    key: '21',
    location: 'Siemens Healthcare',
    siemensId: '11123',
    serialNumber: 'AT1503-01',
    installedOn: '2016-03-02',
    warrantyStart: '2016-03-02',
    warrantyEnd: '2017-03-01',
    contractNumber: '822649056',
    contractType: 'TOP Contract',
    softwareVersion: 'VA20B',
    productName: 'Cios Alpha',
    city: 'Erlangen',
    street: 'Henri-Dunant Str. 50',
    zip: '91058',
    customerName: 'Siemens Healthineers',
    customerNumber: '0SIMULATOR',
    department: 'Radiology',
    myEquipmentName: 'Alpha',
    state: 'green',
    materialNumber: '000007'
  },
  {
    ...CdEquipment,
    key: '22',
    location: 'Siemens Healthcare',
    siemensId: '83070',
    serialNumber: 'LD1505-01',
    installedOn: '2012-07-01',
    warrantyStart: '2012-07-01',
    warrantyEnd: '2013-06-30',
    contractNumber: '638894904',
    contractType: '-',
    softwareVersion: '6.2',
    productName: 'ADVIA 2120i',
    city: 'Erlangen',
    street: 'Hartmannstr. 16',
    zip: '91052',
    customerName: 'Siemens Healthineers 3',
    customerNumber: '2SIMULATOR',
    department: 'Laboratory',
    myEquipmentName: 'AD 01',
    state: 'green',
    materialNumber: '00000002'
  },
  {
    ...CdEquipment,
    key: '23',
    location: 'Siemens Healthcare',
    siemensId: '13605',
    serialNumber: 'XP1501-01',
    installedOn: '2018-06-05',
    warrantyStart: '2018-06-05',
    warrantyEnd: '2019-06-04',
    contractNumber: '578049260',
    contractType: 'Warranty',
    softwareVersion: 'VE10Q',
    productName: 'Uroskop Omnia Max',
    city: 'Forchheim',
    street: 'Siemensstr. 3',
    zip: '91301',
    customerName: 'Siemens Healthineers 2',
    customerNumber: '1SIMULATOR',
    department: 'Urology',
    myEquipmentName: 'RH-fav',
    state: 'green',
    materialNumber: '0000016'
  },
  {
    ...CdEquipment,
    key: '24',
    location: 'Siemens Healthcare',
    siemensId: '94811',
    serialNumber: 'LD1506-01',
    installedOn: '2011-08-06',
    warrantyStart: '2011-08-06',
    warrantyEnd: '2012-08-05',
    contractNumber: '728662748',
    contractType: '-',
    softwareVersion: 'V5.22',
    productName: 'Immulite 1000',
    city: 'Forchheim',
    street: 'Siemensstr. 3',
    zip: '91301',
    customerName: 'Siemens Healthineers 2',
    customerNumber: '1SIMULATOR',
    department: 'Laboratory',
    myEquipmentName: 'LAB-Immu',
    state: 'yellow',
    materialNumber: '000011'
  },
  {
    ...CdEquipment,
    key: '25',
    location: 'Siemens Healthcare',
    siemensId: '92013',
    serialNumber: 'US1508-01',
    installedOn: '2018-10-01',
    warrantyStart: '2018-10-01',
    warrantyEnd: '2019-09-30',
    contractNumber: '986516786',
    contractType: 'Warranty',
    softwareVersion: '-',
    productName: 'ACUSON Sequoia',
    city: 'Forchheim',
    street: 'Siemensstr. 3',
    zip: '91301',
    customerName: 'Siemens Healthineers 2',
    customerNumber: '1SIMULATOR',
    department: 'Radiology',
    myEquipmentName: 'ER-US01',
    state: 'green',
    materialNumber: '00000001'
  },
  {
    ...CdEquipment,
    key: '26',
    location: 'Siemens Healthcare',
    siemensId: '15433',
    serialNumber: 'LD1509-01',
    installedOn: '2012-09-21',
    warrantyStart: '2012-09-21',
    warrantyEnd: '2011-09-20',
    contractNumber: '711286599',
    contractType: '-',
    softwareVersion: 'VR17',
    productName: 'Flexlab',
    city: 'Erlangen',
    street: 'Hartmannstr. 16',
    zip: '91052',
    customerName: 'Siemens Healthineers 3',
    customerNumber: '2SIMULATOR',
    department: 'Laboratory',
    myEquipmentName: 'LAB-Flex',
    state: 'green',
    materialNumber: '0000010'
  },
  {
    ...CdEquipment,
    key: '27',
    location: 'Siemens Healthcare',
    siemensId: '96264',
    serialNumber: 'XP1337-01',
    installedOn: '2017-12-01',
    warrantyStart: '2017-12-01',
    warrantyEnd: '2018-11-30',
    contractNumber: '622935049',
    contractType: 'TOP Contract',
    softwareVersion: 'VF10A',
    productName: 'Multix Fusion Max',
    city: 'Erlangen',
    street: 'Henri-Dunant Str. 50',
    zip: '91058',
    customerName: 'Siemens Healthineers',
    customerNumber: '0SIMULATOR',
    department: 'Radiology',
    myEquipmentName: 'RAD-MaxSys03',
    state: 'green',
    materialNumber: '0000014'
  },
  {
    ...CdEquipment,
    key: '28',
    location: 'Siemens Healthcare',
    siemensId: '34619',
    serialNumber: 'XP1510-01',
    installedOn: '2017-03-02',
    warrantyStart: '2017-03-02',
    warrantyEnd: '2018-03-01',
    contractNumber: '874445487',
    contractType: 'TOP Contract',
    softwareVersion: 'VF10B',
    productName: 'Multitom Rax',
    city: 'Forchheim',
    street: 'Siemensstr. 3',
    zip: '91301',
    customerName: 'Siemens Healthineers 2',
    customerNumber: '1SIMULATOR',
    department: 'Radiology',
    myEquipmentName: 'RAD-MultiUse01',
    state: 'green',
    materialNumber: '000013'
  },
  {
    ...CdEquipment,
    key: '29',
    location: 'Siemens Healthcare',
    siemensId: '23157',
    serialNumber: 'LD1511-01',
    installedOn: '2012-03-03',
    warrantyStart: '2012-03-03',
    warrantyEnd: '2013-03-02',
    contractNumber: '673526831',
    contractType: '-',
    softwareVersion: 'SW 00-18',
    productName: 'CA-1500',
    city: 'Erlangen',
    street: 'Henri-Dunant Str. 50',
    zip: '91058',
    customerName: 'Siemens Healthineers',
    customerNumber: '0SIMULATOR',
    department: 'Laboratory',
    myEquipmentName: 'CA-1500',
    state: 'green',
    materialNumber: '000006'
  },
  {
    ...CdEquipment,
    key: '30',
    location: 'Siemens Healthcare',
    siemensId: '1037',
    serialNumber: 'LD1512-01',
    installedOn: '2018-03-04',
    warrantyStart: '2018-03-04',
    warrantyEnd: '2019-03-03',
    contractNumber: '560241194',
    contractType: 'Warranty',
    softwareVersion: '-',
    productName: 'Atellica IM 1600',
    city: 'Forchheim',
    street: 'Siemensstr. 3',
    zip: '91301',
    customerName: 'Siemens Healthineers 2',
    customerNumber: '1SIMULATOR',
    department: 'Laboratory',
    myEquipmentName: 'Atellica-1600',
    state: 'green',
    materialNumber: '000004'
  },
  {
    ...CdEquipment,
    key: '31',
    location: 'Siemens Healthcare',
    siemensId: '1069',
    serialNumber: 'LD1513-01',
    installedOn: '2017-06-01',
    warrantyStart: '2017-06-01',
    warrantyEnd: '2018-05-31',
    contractNumber: '836190155',
    contractType: 'TOP Contract',
    softwareVersion: '-',
    productName: 'Atellica UAS',
    city: 'Erlangen',
    street: 'Hartmannstr. 16',
    zip: '91052',
    customerName: 'Siemens Healthineers 3',
    customerNumber: '2SIMULATOR',
    department: 'Laboratory',
    myEquipmentName: 'Atellica-UAS',
    state: 'green',
    materialNumber: '000005'
  },
  {
    ...CdEquipment,
    key: '32',
    location: 'Siemens Healthcare',
    siemensId: '34866',
    serialNumber: 'SY1514-01',
    installedOn: '2017-05-23',
    warrantyStart: '2017-05-23',
    warrantyEnd: '2018-05-22',
    contractNumber: '619343858',
    contractType: 'IT Care Plan',
    softwareVersion: '-',
    productName: 'syngo teamplay platform',
    city: 'Erlangen',
    street: 'Henri-Dunant Str. 50',
    zip: '91058',
    customerName: 'Siemens Healthineers',
    customerNumber: '0SIMULATOR',
    department: 'Radiology',
    myEquipmentName: 'syngo-teamplay',
    state: 'green',
    materialNumber: '0000015'
  }
];

// new is the last field
export const CdMyEquipmentProfile: EquipmentMyProfile = {
  equipment: ['1', '2', '3', '5', '7', '8', '10', '11', '14', '15', '17', '20', '21', '27', '29', '31'],
  equipmentPiis: []
};

export const CdLastViewedEquipment: LastViewedEquipment = {
  key: '1',
  productName: 'Product name 12345680',
  siemensEquipmentId: 'Siemens ID 12345680'
};

export const CdLastViewedEquipments: LastViewedEquipment[] = [
  {
    ...CdLastViewedEquipment,
    key: '1',
    productName: 'Somatom Definition AS+',
    siemensEquipmentId: '64073'
  },
  {
    ...CdLastViewedEquipment,
    key: '2',
    productName: 'syngo.via',
    siemensEquipmentId: '46081'
  },
  {
    ...CdLastViewedEquipment,
    key: '3',
    productName: 'syngo.plaza',
    siemensEquipmentId: '40305'
  },
  {
    ...CdLastViewedEquipment,
    key: '4',
    productName: 'Artis Q.zen biplane',
    siemensEquipmentId: '153284'
  },
  {
    ...CdLastViewedEquipment,
    key: '5',
    productName: 'SOMATOM Force+',
    siemensEquipmentId: '400987'
  },
  {
    ...CdLastViewedEquipment,
    key: '6',
    productName: 'ACUSON X 300',
    siemensEquipmentId: '310104'
  }
];

export const CdEquipmentStatus0: EquipmentStatus = {
  equipmentKey: '1',
  status: '3'
};

export const CdEquipmentStatus1: EquipmentStatus = {
  equipmentKey: '2',
  status: '3'
};

export const CdEquipmentStatus2: EquipmentStatus = {
  equipmentKey: '3',
  status: '1'
};

export const CdEquipmentStatus3: EquipmentStatus = {
  equipmentKey: '4',
  status: '3'
};

export const CdEquipmentStatus4: EquipmentStatus = {
  equipmentKey: '5',
  status: '3'
};

export const CdEquipmentStatus5: EquipmentStatus = {
  equipmentKey: '6',
  status: '3'
};

export const CdEquipmentStatus6: EquipmentStatus = {
  equipmentKey: '7',
  status: '3'
};

export const CdEquipmentStatus7: EquipmentStatus = {
  equipmentKey: '8',
  status: '3'
};

export const CdEquipmentStatus8: EquipmentStatus = {
  equipmentKey: '9',
  status: '3'
};

export const CdEquipmentStatus9: EquipmentStatus = {
  equipmentKey: '10',
  status: '3'
};

export const CdEquipmentStatus10: EquipmentStatus = {
  equipmentKey: '11',
  status: '3'
};

export const CdEquipmentStatus11: EquipmentStatus = {
  equipmentKey: '12',
  status: '3'
};

export const CdEquipmentStatus12: EquipmentStatus = {
  equipmentKey: '13',
  status: '3'
};

export const CdEquipmentStatus13: EquipmentStatus = {
  equipmentKey: '14',
  status: '3'
};

export const CdEquipmentStatus14: EquipmentStatus = {
  equipmentKey: '15',
  status: '3'
};

export const CdEquipmentStatus15: EquipmentStatus = {
  equipmentKey: '16',
  status: '3'
};

export const CdEquipmentStatus16: EquipmentStatus = {
  equipmentKey: '17',
  status: '2'
};

export const CdEquipmentStatus17: EquipmentStatus = {
  equipmentKey: '18',
  status: '2'
};

export const CdEquipmentStatus18: EquipmentStatus = {
  equipmentKey: '19',
  status: '3'
};

export const CdEquipmentStatus19: EquipmentStatus = {
  equipmentKey: '20',
  status: '3'
};

export const CdEquipmentStatus20: EquipmentStatus = {
  equipmentKey: '21',
  status: '3'
};

export const CdEquipmentStatus21: EquipmentStatus = {
  equipmentKey: '22',
  status: '3'
};

export const CdEquipmentStatus22: EquipmentStatus = {
  equipmentKey: '23',
  status: '3'
};

export const CdEquipmentStatus23: EquipmentStatus = {
  equipmentKey: '24',
  status: '2'
};

export const CdEquipmentStatus24: EquipmentStatus = {
  equipmentKey: '25',
  status: '3'
};

export const CdEquipmentStatus25: EquipmentStatus = {
  equipmentKey: '26',
  status: '3'
};

export const CdEquipmentStatus26: EquipmentStatus = {
  equipmentKey: '27',
  status: '3'
};

export const CdEquipmentStatus27: EquipmentStatus = {
  equipmentKey: '28',
  status: '3'
};

export const CdEquipmentStatus28: EquipmentStatus = {
  equipmentKey: '29',
  status: '3'
};

export const CdEquipmentStatus29: EquipmentStatus = {
  equipmentKey: '30',
  status: '3'
};

export const CdEquipmentStatus30: EquipmentStatus = {
  equipmentKey: '31',
  status: '3'
};

export const CdEquipmentStatus31: EquipmentStatus = {
  equipmentKey: '32',
  status: '3'
};

export const CdEquipmentStatusForCustomer: EquipmentStatusForCustomer[] = [
  {
    customerId: '0SIMULATOR',
    statusList: [
      CdEquipmentStatus1,
      CdEquipmentStatus4,
      CdEquipmentStatus6,
      CdEquipmentStatus9,
      CdEquipmentStatus13,
      CdEquipmentStatus20,
      CdEquipmentStatus26,
      CdEquipmentStatus28,
      CdEquipmentStatus31
    ],
    lastUpdate: null
  },
  {
    customerId: '1SIMULATOR',
    statusList: [
      CdEquipmentStatus3,
      CdEquipmentStatus8,
      CdEquipmentStatus11,
      CdEquipmentStatus12,
      CdEquipmentStatus15,
      CdEquipmentStatus17,
      CdEquipmentStatus18,
      CdEquipmentStatus22,
      CdEquipmentStatus23,
      CdEquipmentStatus24,
      CdEquipmentStatus27,
      CdEquipmentStatus29
    ],
    lastUpdate: null
  },
  {
    customerId: '2SIMULATOR',
    statusList: [
      CdEquipmentStatus0,
      CdEquipmentStatus2,
      CdEquipmentStatus5,
      CdEquipmentStatus7,
      CdEquipmentStatus10,
      CdEquipmentStatus14,
      CdEquipmentStatus16,
      CdEquipmentStatus19,
      CdEquipmentStatus21,
      CdEquipmentStatus25,
      CdEquipmentStatus30
    ],
    lastUpdate: null
  }
];
