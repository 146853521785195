import { DigitalCompassGraph } from '../app/core/models/digital-compass/digital-compass-graph';
import { DigitalCompassOption } from '../app/core/models/digital-compass/digital-compass-option';
import { DigitalCompassOptionDetail } from '../app/core/models/digital-compass/digital-compass-option-detail';
import { DigitalCompass } from '../app/core/models/digital-compass/digital-compass';
import { DigitalCompassArticle } from '../app/core/models/digital-compass/digital-compass-article';
import { DigitalCompassFilter } from '../app/core/models/digital-compass/digital-compass-filter';
import { CdModalityList } from './cd-modalities.mock';

const cdClinicalFields = [
  "ANGIOGRAPHY",
  "CARDIOLOGY",
  "CARDIOVASCULAR",
  "CYBERSECURITY",
  "DUALENERGY",
  "FLUOROSCOPY",
  "GENERAL",
  "LOWDOSE",
  "NEUROLOGY",
  "ONCOLOGY",
  "ORTHOPEDICS",
  "PEDIATRICS",
  "RADIOGRAPHY",
  "SURGERY",
  "WOMENSHEALTH",
];

const cdDiseaseFields = [
  "ATRIAL_FIBRILLATION_AND_FLUTTER",
  "PROSTATE_CANCER",
  "HYPERTENSIVE_HEART_DISEASE",
  "ISCHEMIC_HEART_DISEASE",
  "PARKINSONS_DISEASE",
  "OTHER_CARDIOVASCULAR_AND_CIRCULATORY_DISEASES",
  "RHEUMATIC_HEART_DISEASE",
  "RHEUMATOID_ARTHRITIS",
  "ALZHEIMERS_DISEASE_AND_OTHER_DEMENTIAS",
  "CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE",
  "CORONARY_ARTERY_DISEASE",
];

const cdModalities = Array.from(new Set(CdModalityList.map(el => el.modalityDescription)));

const cdArticles: DigitalCompassArticle[] = [
  {
    source: 'Source',
    diseaseAreas: [cdDiseaseFields[0], cdDiseaseFields[1]],
    question: 'Question Text',
    citation: 'Citation Text',
    link: './',
    releaseDate: '2022-12-12',
    modalities: [cdModalities[0], cdModalities[1]],
    clinicalFields: [cdClinicalFields[0], cdClinicalFields[1]],
    showDisclaimer: true,
    showAdditionalImageContent: true,
    pictureId: '4',
  },
  {
    source: 'Source 2',
    diseaseAreas: [cdDiseaseFields[1], cdDiseaseFields[2]],
    question: 'Question Text 2',
    citation: 'Citation Text 2',
    link: './',
    releaseDate: '2022-11-12',
    modalities: [cdModalities[1], cdModalities[2]],
    clinicalFields: [cdClinicalFields[1], cdClinicalFields[2]],
    showDisclaimer: false,
    showAdditionalImageContent: false,
    pictureId: '5',
  }
];

const cdFilter: DigitalCompassFilter = {
  clinicalFields: cdClinicalFields,
  diseaseArea: cdDiseaseFields,
  modalities: cdModalities,
}

const cdGraph: DigitalCompassGraph = {
  entries: [
    {
      name: 'PARKINSONS_DISEASE',
      value: 12,
      diseaseArea: 'PARKINSONS_DISEASE',
      diseaseAreas: [],
      clinicalFields: [cdClinicalFields[0]],
    },
    {
      name: 'RHEUMATIC_HEART_DISEASE',
      value: 23,
      diseaseArea: 'RHEUMATIC_HEART_DISEASE',
      diseaseAreas: [],
      clinicalFields: [cdClinicalFields[1]],
    },
    {
      name: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
      value: 35,
      diseaseArea: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
      diseaseAreas: [],
      clinicalFields: [cdClinicalFields[1]],
    }
  ]
}

const cdOptions: DigitalCompassOption[] = [
  {
    marketingName: 'Marketing Name',
    marketingNameGUID: 'randomguid',
    localizedGUID: 'localizedguid',
    diseaseAreas: cdDiseaseFields,
    modalities: cdModalities,
    clinicalFields: cdClinicalFields,
    installedEquipmentOptions: [
      {
        materialNumber: '000000000010496180',
        serialNumber: 'CT0221-01',
      },
      {
        materialNumber: 'Material Number 2',
        serialNumber: 'XP1510-01',
      }
    ],
    description: 'desc',
    availableEquipmentOptions: [
      {
        materialNumber: '00000004',
        serialNumber: 'SY1190-01',
      },
    ],
    showAdditionalImageContent: true,
    imgLink: 'assets/mockimages/6.jpg',
  }
];

export const CdDigitalCompass: DigitalCompass = {
  filter: cdFilter,
  articles: cdArticles,
  graph: cdGraph,
  options: cdOptions,
};

export const CdDigitalCompassOptionDetail: DigitalCompassOptionDetail = {
  marketingName: 'Marketing Name',
  imgLink: 'assets/mockimages/2.jpg',
  whatOptionDoes: ['what option does 1', 'what option does 2'],
  authorPhotoLink: 'assets/mockimages/5.jpg',
  testimonialText: 'Testimonial Text',
  authorName: 'Author Name',
  authorTitle: 'Author Title',
  whenOptionUse: ['when option use 1', 'when option use 2'],
  cLevelOnePagerLink: 'cLevelOnePagerLink',
  matchingELearningLink: ['http://test.test', 'https://google.com/'],
  footNote: 'Foot Note Text',
};

export function generateDigitalCompassData({ articles, diseases, fields, graph, options, modalities, equipment }) {
  // // Return fixed mock data for debug
  // return CdDigitalCompass;

  const modalitiesOptions = options.data.map(el => el.modalities.map(m => m.modalities_id));
  const modalitiesArticles = articles.data.map(el => el.modalities.map(m => m.modalities_id));
  const modalitiesIds = Array.from(new Set(modalitiesOptions.concat(modalitiesArticles).flat()));

  const filterData: DigitalCompassFilter = {
    clinicalFields: fields.data.map(el => el.name),
    diseaseArea: diseases.data.filter(el => el.filter_visible).map(el => el.name),
    modalities: modalities.data.filter(m => modalitiesIds.includes(m.id)).map(el => el.modality_description),
  }

  const graphData: DigitalCompassGraph = {
    entries: graph.data.map(entry => {
      return {
        name: entry.disease_area_id.name,
        value: entry.value / 10,
        diseaseArea: entry.disease_area_id.name,
        diseaseAreas: diseases.data.filter(d => entry.clinical_diseases.map(el => el.clinical_diseases_id).includes(d.id)).map(el => el.name),
        clinicalFields: fields.data.filter(field => entry.clinical_fields.map(el => el.clinical_fields_id).includes(field.id)).map(el => el.name),
      }
    })
  }

  const articlesData: DigitalCompassArticle[] = articles.data.map(article => {
    return {
      source: article.source,
      diseaseAreas: diseases.data.filter(d => article.disease_areas.map(f => f.clinical_diseases_id).includes(d.id)).map(el => el.name),
      question: article.question,
      citation: article.citation,
      link: article.link,
      releaseDate: article.release_date,
      modalities: modalities.data.filter(m => article.modalities.map(el => el.modalities_id).includes(m.id)).map(el => el.modality_description),
      clinicalFields: fields.data.filter(field => article.clinical_fields.map(el => el.clinical_fields_id).includes(field.id)).map(el => el.name),
      showDisclaimer: article.show_disclaimer,
      showAdditionalImageContent: article.show_additional_image_content,
      pictureId: article.picture_id,
    }
  }).sort((a, b) => {
    if (a.releaseDate > b.releaseDate) {
      return -1;
    } else if (a.releaseDate < b.releaseDate) {
      return 1;
    } else {
      if (a.source === b.source) {
        return 0;
      } else if (a.source === 'Siemens Healthineers') {
        return 1;
      } else if (b.source === 'Siemens Healthineers') {
        return -1;
      } else {
        return a.source.localeCompare(b.source);
      }
    }
  });

  const optionsData: DigitalCompassOption[] = options.data.map(option => {
    return {
      marketingName: option.marketing_name,
      marketingNameGUID: option.id,
      localizedGUID: option.id,
      diseaseAreas: diseases.data.filter(d => option.clinical_disease.map(f => f.clinical_diseases_id).includes(d.id)).map(el => el.name),
      modalities: modalities.data.filter(m => option.modalities.map(el => el.modalities_id).includes(m.id)).map(el => el.modality_description),
      clinicalFields: fields.data.filter(field => option.clinical_fields.map(el => el.clinical_fields_id).includes(field.id)).map(el => el.name),
      description: option.description,
      installedEquipmentOptions: equipment.data.filter(eq => option.installed_equipment.map(el => el.equipment_id).includes(eq.id)).map(el => {
        return {
          materialNumber: el.material_number,
          serialNumber: el.serial_number,
        }
      }),
      availableEquipmentOptions: equipment.data.filter(eq => option.available_equipment.map(el => el.equipment_id).includes(eq.id)).map(el => {
        return {
          materialNumber: el.material_number,
          serialNumber: el.serial_number,
        }
      }),
      showAdditionalImageContent: option.show_additional_image_content,
      imgLink: option.img_link,
    }
  });

  const digitalCompassData: DigitalCompass = {
    filter: filterData,
    articles: articlesData,
    graph: graphData,
    options: optionsData,
  };

  return digitalCompassData;
}

export function generateDigitalCompassDetailData(optionId, options) {
  const option = options.data.find(el => el.id.toString() === optionId.toString());
  if (option) {
    const optionDetailData: DigitalCompassOptionDetail = {
      marketingName: option.marketing_name,
      imgLink: option.img_link,
      whatOptionDoes: splitStr(option.what_option_does),
      authorPhotoLink: option.author_photo_link,
      testimonialText: option.testimonial_text,
      authorName: option.author_name,
      authorTitle: option.author_title,
      whenOptionUse: splitStr(option.when_option_use),
      cLevelOnePagerLink: option.clevel_one_pager_link,
      matchingELearningLink:  splitStr(option.matching_e_learning_link),
      footNote: option.foot_note,
    };  
    return optionDetailData;
  } else {
    return {};
  }
}

function splitStr(str) {
  return (str || '').split('\n').filter(el => el !== '');
}