export const CdEnvironmentConfig = {
  // CHAT_ACCOUNT_KEY: null,
  // CURRENT_USER_STATIC_URL: 'https://sso.med.siemens.de/ssoweb/sso/editprofile.action?addDisplayMode=embedded',
  // FRONTEND_DEBUG: 'true',
  // LOGIN_URL: 'http://127.0.0.1:4200/rest/v1/login.jsp',
  // MY_HEALTHCARE_LINK: null,
  // ORDER_URL: 'https://bps-test.healthcare.siemens.com/spe/',
  // USER_ADMIN_LINK: null,
  CHAT_ACCOUNT_KEY: '6349',
  CURRENT_USER_STATIC_URL: 'https://my.healthcare.siemens.com/profile',
  ENVIRONMENT: '',
  EQUIPMENT_IMAGE_REPO:
    'assets/mockimages/',
  FRONTEND_DEBUG: 'false',
  GOOGLE_MAPS_API_URL:
    'https://maps.googleapis.com/maps/api/js?key=AIzaSyA7-1Ecs13SQbMJmdgpF35Tysn0pqUf1Mg&callback=initialize&channel=80',
  LOGIN_URL:
    'https://entitlement.siemens.com/GetAccess/Saml/IDP/SSO/Unsolicited?GA_SAML_SP=lifenet.siemens-healthineers.com&RelayState=https%3A%2F%2Flifenet.siemens-healthineers.com',
  MY_HEALTHCARE_LINK: 'https://my.healthcare.siemens.com/profile/services',
  ORDER_URL: 'https://bps-healthcare.siemens.com/spe/',
  REGISTRATION_COUNTRY_LIST:
    'AE,AR,AT,AU,BE,BG,BH,BO,BR,CA,CH,CL,CN,CO,CR,CZ,DE,DK,DO,EC,EE,EG,ES,FI,FR,GB,GL,GR,GT,HK,HO,HR,HU,IB,ID,IE,IL,IN,IT,JP,KR,LT,LU,LV,MA,MX,MY,NI,NL,NO,NZ,QA,OM,PA,PE,PH,PK,PL,PT,RO,RU,SA,SE,SG,SI,SK,SV,TH,TM,TR,TW,US,VE,VN,ZA,IS',
  TRAINING_IMAGE_REPO:
    'https://lifenet.siemens-healthineers.com/static/training_images/',
  USER_ADMIN_LINK:
    'https://my.healthcare.siemens.com/xiaWeb/auth/userAdministration/listUsers.jsf',
  // WALKME_CONFIGURATION_IDENTIFIER:
  //   'https://eu-cdn.walkme.com/users/c97a2ecef42240998dfbfd774130973c/walkme_c97a2ecef42240998dfbfd774130973c_https.js'
  WALKME_CONFIGURATION_IDENTIFIER:
    'https://eu-cdn.walkme.com/users/c97a2ecef42240998dfbfd774130973c/test/walkme_c97a2ecef42240998dfbfd774130973c_https.js',
  ADVISORY_UPLOAD_ALLOWED_LANGUAGES: 'EN,DE',
  DIGITAL_COMPASS_ARTICLE_IMAGE_REPO: 'assets/mockimages/publications/',
  DASHBOARD_URL: window.location.origin,
};
