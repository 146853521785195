import { Component } from '@angular/core';
import { MyEquipmentSwitchService } from './my-equipment-switch.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'hl-my-equipment-switch',
  templateUrl: './my-equipment-switch.component.html'
})
export class MyEquipmentSwitchComponent {

  isDisabled = true;

  constructor(private myEquipmentSwitchService: MyEquipmentSwitchService) { }

  get isOnlyMyEquipment$() {
    return this.myEquipmentSwitchService.showOnlyMine$.pipe(tap(() => this.isDisabled = false));
  }

  toggle(onlyMyEquipment: boolean) {
    this.myEquipmentSwitchService.toggle(onlyMyEquipment);
  }
}
