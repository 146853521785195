<div class="application-bar--compact">
  <div class="application-bar__container width-100">
    <div class="application-bar__logo">
      <div class="logo" data-cy="header-logo"></div>
    </div>
    <div class="application-bar__app-identifier" translate="LIFENET_INSTANCE" data-cy="header-tpf-country"></div>
    @if (showFilter) {
      <div class="application-bar__additional-functions hidden-md-down">
        @if (myFiltersFeatureToggle) {
          <hl-my-filters-switch (myFiltersClicked)="navigate('MY_FILTERS')"></hl-my-filters-switch>
        } @else {
          <hl-my-equipment-switch ></hl-my-equipment-switch>
        }
      </div>
    }
    <div class="application-bar__additional-functions hidden-md-up">
    </div>
    <div class="application-bar__meta-menu" style="flex-grow: 0">
      <hl-tool-bar class="hl-tool-bar" [myFiltersFeatureToggle]="myFiltersFeatureToggle" (myFiltersClicked)="navigate('MY_FILTERS')"></hl-tool-bar>
    </div>
    <div class="application-bar__user-identifier">
      <div class="user-identifier dropdown dropdown--right" [class.is-open]="isDropdownOpen">
        <button class="user-identifier__toggle-button dropdown__toggle" (click)="toggleDropdown()" #dropDownToggle data-cy="header-user-identifier">
          <div class="user-identifier__avatar">
            <img [attr.src]="avatarImage" alt="user avatar" />
          </div>
          <span class="user-identifier__details" data-cy="user-identifier-details">
              <span class="user-identifier__name"><hl-user-name configKey="HEADER_NAME_ORDER"></hl-user-name></span>
          </span>
          <i class="icon-arrow-down-small"></i>
        </button>
        <div class="menu dropdown__panel">
          @if (envConfig$ | async; as envConfig) {
            <a class="menu__item" target="_blank" rel="noopener noreferrer" data-cy="header-user-identifier-name" [href]="envConfig.CURRENT_USER_STATIC_URL">
              <hl-user-name></hl-user-name>
            </a>
            <a class="menu__item" translate="GENERIC_LABEL_MY_SIEMENS_HEALTHCARE" target="_blank" rel="noopener noreferrer"
              data-cy="header-my-services" [href]="envConfig.MY_HEALTHCARE_LINK"></a>
          }
          <a class="menu__item" (click)="navigateToTab()" translate="LABEL_MY_LIFENET_SETTINGS_MENU"></a>
          <a class="menu__item" (click)="logout()" translate="ABMELDEN" data-cy="logout-link"></a>
        </div>
      </div>
    </div>
  </div>

  <!-- Start of user Notifications settings -->
  <hl-overlay [closeOverlayAfterSave]="true" [closeUrl]="[]" #userSettingsOverlay (save)="userSettings.showDialogIfWasDataChanged($event)">
    <hl-user-settings #userSettings></hl-user-settings>
  </hl-overlay>
</div>

