import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MyFiltersService } from 'app/core/services/my-filters.service';

@Component({
  selector: 'hl-my-filters-switch',
  templateUrl: './my-filters-switch.component.html'
})
export class MyFiltersSwitchComponent implements OnInit, OnDestroy {

  private readonly unsubscribe$ = new Subject<void>();
  @Output() myFiltersClicked = new EventEmitter();

  myFiltersOverallSwitchForm = this.fb.group({
    overallSwitch: []
  });

  constructor(private fb: UntypedFormBuilder, private myFiltersService: MyFiltersService) { }

  ngOnInit() {
    this.myFiltersService.myFilters$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(({ overallSwitch }) => {
        this.myFiltersOverallSwitchForm.patchValue({ overallSwitch }, { emitEvent: false });
      });

    this.myFiltersOverallSwitchForm.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.myFiltersService.save(value);
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  navigateToMyFilters() {
    this.myFiltersClicked.emit();
  }
}
