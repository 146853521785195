import { Subject } from 'rxjs';
import { UserUtilService } from '../services/user/user-util.service';
import { UserRestService } from '../rest-services/user-rest.service';
import { CountryConfigRestService } from '../rest-services/country-config-rest.service';
import { Router } from '@angular/router';

import { startsWith } from 'lodash-es';
import { EquipmentRestService } from '../rest-services/equipment-rest.service';
import { StateService } from '../services/state.service';
import { LifeNetUtilService } from '../utils/life-net-util.service';
import { BrowserStateService } from '../services/browser-state.service';
import { ServiceMetricsRestService } from '../rest-services/service-metrics-rest.service';
import { takeUntil } from 'rxjs/operators';
import { ChangeDetectorRef, Directive, OnDestroy } from '@angular/core';
import { CheckPermissionOrRoleService } from '../auth-guards/check-permission-or-role.service';

@Directive()
export abstract class BaseMainNavRoleCheckDirective implements OnDestroy {
  navCollapsed: boolean;

  configService: CountryConfigRestService;
  userRestService: UserRestService;
  userUtilService: UserUtilService;
  equipmentRestService: EquipmentRestService;
  router: Router;
  stateService: StateService;
  lifeNetUtilService: LifeNetUtilService;
  browserStateService: BrowserStateService;
  metricsService: ServiceMetricsRestService;
  cdr: ChangeDetectorRef;

  headerTranslateLabel: string;
  currentStateName: string;
  currentBaseStateName: string;
  protected readonly unsubscribe$ = new Subject<void>();

  constructor(configService: CountryConfigRestService,
    userRestService: UserRestService,
    userUtilService: UserUtilService,
    metricsService: ServiceMetricsRestService,
    router: Router,
    stateService: StateService,
    equipmentRestService: EquipmentRestService,
    lifeNetUtilService: LifeNetUtilService,
    browserStateService: BrowserStateService,
    cdr: ChangeDetectorRef,
    protected checkPermissionOrRoleService: CheckPermissionOrRoleService) {
    this.configService = configService;
    this.userRestService = userRestService;
    this.userUtilService = userUtilService;
    this.equipmentRestService = equipmentRestService;
    this.metricsService = metricsService;
    this.router = router;
    this.stateService = stateService;
    this.lifeNetUtilService = lifeNetUtilService;
    this.browserStateService = browserStateService;
    this.cdr = cdr;
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  /**
   * @description Initialize of call stack
   */
  init() {
    this.initProperties();

    this.setStateName();
    this.checkPermissionOrRoleService.loadingPermissionForTabs();
  }

  destroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * @description Initialize view properties
   */
  initProperties() {
    this.checkPermissionOrRoleService.reportingLink = '';
    this.navCollapsed = true;

    // default set to dashboard
    this.headerTranslateLabel = 'MY_DASHBOARD';
    // please check description of this method
    this.currentStateName = this.stateService.getStateNameFromWindowLocation();
    this.currentBaseStateName = this.stateService.getCurrentBaseStateName();
  }

  setStateName() {
    this.stateService.getActiveStateName().pipe(takeUntil(this.unsubscribe$)).subscribe(stateName => {
      if (stateName) {
        this.currentStateName = stateName;
        this.currentBaseStateName = this.stateService.getCurrentBaseStateName();
      }
    });
  }

  /**
   *
   * @description
   * Go to the corresponding state (for e.g. equipment, tickets, etc)
   *
   * @param stateTo
   */
  stateGo(stateTo: string) {

    // translates the state to url by replacing '-' in the state with '/',
    // e.g. invoices-service to invoices/service.
    const state = this.stateService.getUrlFromStateName(stateTo);

    /**
     * if we are on same state or nested states with parent state instantiated,
     * no need to init it again.
     */
    if (!startsWith(this.currentStateName, stateTo)) {
      this.router.navigate([state]);
    }
  }
}
