import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuardService as AuthGuard } from './core/services/user/auth-guard.service';
import { PageNotFoundComponent } from './front-pages/page-not-found.component';
import { dashboardAuthGuard } from './core/auth-guards/dashboard/dashboard-auth-guard.service';
import { TeamplayWidgetComponent } from './dashboard/teamplay-widget/teamplay-widget.component';
import { featureGuard } from './core/auth-guards/feature.guard';
import { reportingGuard } from './core/auth-guards/reporting/reporting.guard';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    data: { stateName: 'dashboard', title: 'MY_DASHBOARD' },
    canActivate: [dashboardAuthGuard]
  },
  {
    path: 'activities',
    loadChildren: () => import('./activities/activities.module').then(m => m.ActivitiesModule),
    data: { stateName: 'activities', title: 'GEPLANTE_TERMINE' }
  },
  {
    path: 'appointments',
    loadChildren: () => import('./appointments/appointments.module').then(m => m.AppointmentsModule),
    data: { stateName: 'appointments', title: 'APPOINTMENTS_TITLE' }
  },
  {
    path: 'equipment',
    loadChildren: () => import('./equipment/equipment.module').then(m => m.EquipmentModule),
    data: { stateName: 'equipment', title: 'ALL_SIEMENS_EQUIPMENTS' },
    canActivate: [featureGuard('showEquipmentTab')]
  },
  {
    path: 'tickets',
    loadChildren: () => import('./tickets/tickets.module').then(m => m.TicketsModule),
    data: { stateName: 'tickets', title: 'ALL_SIEMENS_EQUIPMENTS' },
    canActivate: [featureGuard('showOpenTicketsTab')]
  },
  {
    path: 'contracts',
    loadChildren: () => import('./contracts/contracts.module').then(m => m.ContractsModule),
    data: { stateName: 'contracts', title: 'CONTRACT_TITLE' }
  },
  {
    path: 'administration',
    loadChildren: () =>
      import('./customer-administration/customer-administration.module').then(m => m.CustomerAdministrationModule),
    data: { stateName: 'administration', title: 'ADMINISTRATION' }
  },
  {
    path: 'partnerOrders',
    loadChildren: () => import('./partner-orders/partner-orders.module').then(m => m.PartnerOrdersModule),
    data: { stateName: 'partnerOrders', title: 'PARTNER_ORDERS' }
  },
  {
    path: 'impersonation',
    loadChildren: () =>
      import('./customer-impersonation/customer-impersonation.module').then(m => m.CustomerImpersonationModule),
    data: { stateName: 'impersonation', title: 'CUSTOMER_IMPERSONATION' },
    canActivate: [featureGuard('showImpersonationTab')]
  },
  {
    path: 'psr',
    loadChildren: () => import('./psr/psr.module').then(m => m.PsrModule),
    data: { stateName: 'psr', title: 'TAB_PSR' }
  },
  {
    path: 'invoices',
    loadChildren: () => import('./invoices/invoices.module').then(m => m.InvoicesModule),
    data: { stateName: 'invoices', title: 'MAIN_MENU_INVOICES' }
  },
  {
    path: 'reporting',
    loadChildren: () => import('./reporting/reporting.module').then(m => m.ReportingModule),
    data: { stateName: 'reporting', title: 'REPORTING' },
    canActivate: [reportingGuard()]
  },
  {
    path: 'metrics',
    loadChildren: () => import('./service-metrics/service-metrics.module').then(m => m.ServiceMetricsModule),
    data: { stateName: 'service-metrics', title: 'SERVICE-METRICS' },
    canActivate: [reportingGuard('service-metrics')]
  },

  {
    path: 'service-performance-metrics',
    loadChildren: () => import('./spr/spr.module').then(m => m.SprModule),
    data: { stateName: 'service-performance-metrics', title: 'SERVICE-METRICS' },
    canActivate: [featureGuard('showSprTab')]
  },

  {
    path: 'security',
    loadChildren: () => import('./security/security.module').then(m => m.SecurityModule),
    data: { stateName: 'security', title: 'SECURITY_TAB_NAME' },
    canActivate: [featureGuard('showSecurityTab')]
  },
  {
    path: 'updates',
    loadChildren: () => import('./system-updates/system-updates.module').then(m => m.SystemUpdatesModule),
    data: { stateName: 'updates', title: 'SYSTEM_UPDATES' },
    canActivate: [featureGuard('showSystemUpdatesTab')]
  },
  {
    path: 'advisories',
    loadChildren: () =>
      import('./security-advisories/security-advisories.module').then(m => m.SecurityAdvisoriesModule),
    data: { stateName: 'advisories', title: 'SECURITY_ADVISORIES' }
  },
  {
    path: 'overview',
    loadChildren: () => import('./security-overview/security-overview.module').then(m => m.SecurityOverviewModule),
    data: { stateName: 'overview', title: 'LABEL_CYBERSECURITY_OVERVIEW' },
    canActivate: [featureGuard('showCybersecurityOverview')]
  },
  {
    path: 'clinical-performance-companion',
    loadChildren: () => import('./digital-compass/digital-compass.module').then(m => m.DigitalCompassModule),
    data: { stateName: 'clinical-performance-companion', title: 'DIGITAL_COMPASS' },
    canActivate: [featureGuard('showDigitalCompassTab')]
  },
  {
    path: '',
    loadChildren: () => import('./support/support.module').then(m => m.SupportModule)
  },
  {
    path: 'teamplay',
    component: TeamplayWidgetComponent,
    data: { stateName: 'teamplay', title: 'MY_DASHBOARD' },
    canActivate: [featureGuard('showTeamplayWidget')]
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
