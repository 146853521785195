import { combineLatest, Observable } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import { map } from 'rxjs/operators';

declare module '@angular/router' {
  interface ActivatedRoute {
    get allParams(): Observable<Params>
  }
}

Object.defineProperty(ActivatedRoute.prototype, "allParams", {
  get (this: ActivatedRoute): Observable<Params> {
    return combineLatest(this.pathFromRoot.map(r => r.params)).pipe(
      map(allParams => allParams.reduce((acc, curr) => ({ ...acc, ...curr })))
    );
  },
  enumerable: false,
  configurable: true
});

export {}
