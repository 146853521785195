import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CacheableHttpClient } from '../services/cache/cacheable-http-client';
import { restEndPoint } from '../core-constants.service';
import { Messages } from '../models/messages';
import { MessagesViewModel } from '../view-models/messages-view-model';
import { map } from 'rxjs/operators';
import { MessageViewModel } from '../view-models/message-view-model';

@Injectable({providedIn: 'root'})
export class MessagesRestService {
    messagesRestName = restEndPoint + 'messages';
  constructor(private http: CacheableHttpClient) {
  }

  public getMessages(): Observable<MessagesViewModel> {
      return this.http.get<Messages>(this.messagesRestName, {cache: false}).pipe(
      map(response => new MessagesViewModel(response))
    );
  }

  public postViewedEventMessage(message: MessageViewModel): Observable<any> {
    const url = this.messagesRestName + '/markEventAsSeen';
    return this.http.post(url, {
      groupId: message.groupId,
      messageType: message.messageType,
      identifier: message.identifier
    });
  }
}
