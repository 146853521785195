import { LifeNetUtilService } from './core/utils/life-net-util.service';
import { Component, ElementRef, EventEmitter, HostListener, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { isEqual } from 'lodash-es';
import { UserUtilService } from './core/services/user/user-util.service';
import { ImpersonationCommunicationService } from './core/component-communication-services/impersonation/impersonation-communication.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { EnvironmentConfigRestService } from './core/rest-services/environment-config-rest.service';
import { OverlayComponent } from './shared/components/overlay/overlay.component';
import { environment } from '../environments/environment.prod';
import { CountryConfigRestService } from './core/rest-services/country-config-rest.service';
import { LIFENET_SETTINGS_FRAGMENT, LifenetUserSettings, UserSettingsComponent } from './user-settings/user-settings.component';
import { roles } from './core/core-constants.service';
import { EquipmentSRSRestService } from './equipment/equipment-srs-cache/equipment-srs-cache.service';

@Component({
  selector: 'hl-header',
  templateUrl: './header.component.html'
})

export class HeaderComponent implements OnInit, OnDestroy {

  @ViewChild('userSettingsOverlay') userSettingsOverlay: OverlayComponent;
  @ViewChild('userSettings') userSettings: UserSettingsComponent;
  @ViewChild('dropDownToggle') dropDownToggleEl: ElementRef;

  @Output()
  userLogsOut = new EventEmitter<any>();

  envConfig$: Observable<{ CURRENT_USER_STATIC_URL: string, MY_HEALTHCARE_LINK: string }>;

  isDropdownOpen = false;
  showUserSettingsInTabs = false;
  showMyCustomersTab = false;
  showFilter = false;
  myFiltersFeatureToggle = false;

  private readonly unsubscribe$ = new Subject<void>();

  avatarImage = environment.version + 'assets/images/no-avatar-image.png';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userUtilService: UserUtilService,
    private environmentConfigRestService: EnvironmentConfigRestService,
    private lifeNetUtilService: LifeNetUtilService,
    private equipmentSRSRestService: EquipmentSRSRestService,
    private impersonationCommunicationService: ImpersonationCommunicationService,
    private configService: CountryConfigRestService) {
  }

  ngOnInit() {
    this.init();
    this.registerEventListeners();
  }

  private init() {
    this.loadEnvironmentConfig();
    this.loadCustomerSettings();
    this.equipmentSRSRestService.loadSrsEquipmentStatuses();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private loadEnvironmentConfig() {
    this.envConfig$ = this.environmentConfigRestService.getEnvironmentConfig()
      .pipe(map(({CURRENT_USER_STATIC_URL, MY_HEALTHCARE_LINK}) => ({
        CURRENT_USER_STATIC_URL, MY_HEALTHCARE_LINK
      })));
  }

  private registerEventListeners() {
    this.impersonationCommunicationService.onCountryLanguageChange$
      .pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.init());
  }

  @HostListener('document:click.out-zone', ['$event'])
  clickout(event) {
    if (this.isDropdownOpen && !this.dropDownToggleEl.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false;
    }
  }

  logout() {
    this.userLogsOut.emit();
    this.lifeNetUtilService.invalidateSession();
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  navigate(fragment = 'MY_CUSTOMERS') {
    if (this.userSettings && this.showUserSettingsInTabs) {
      const navigationExtras: NavigationExtras = {
        fragment: LIFENET_SETTINGS_FRAGMENT + '=' + LifenetUserSettings[fragment]
      };
      this.router.navigate([this.router.url.split('?')[0]], navigationExtras);
    }
    this.userSettingsOverlay.show();
    this.userSettings.resetElementsInTabs();
  }

  navigateToTab() {
    const path = this.showUserSettingsInTabs && this.showMyCustomersTab ? 'MY_CUSTOMERS' : 'NOTIFICATIONS';
    this.navigate(path);
  }

  loadCustomerSettings() {
    const getUserRoles$ = this.userUtilService
      .checkUserRoles({
        impersonateUserRole: roles.impersonateUserRole,
        viewEquipmentRole: roles.viewEquipmentRole
      });
    combineLatest([this.configService.getConfig(), getUserRoles$, this.route.queryParams])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([config, userRoles, queryParams]) => {
        this.showFilter = userRoles.viewEquipmentRole;
        this.myFiltersFeatureToggle = isEqual(config.FEATURE_TOGGLE_MY_FILTERS, 'true');
        const myCustomerSettings = !config.TOGGLE_MY_CUSTOMER_SETTINGS || isEqual(config.TOGGLE_MY_CUSTOMER_SETTINGS, 'true');
        this.showMyCustomersTab = myCustomerSettings && !userRoles.impersonateUserRole;
        this.showUserSettingsInTabs = this.showMyCustomersTab || this.myFiltersFeatureToggle;
        this.handleQueryParams(queryParams);
      });
  }

  handleQueryParams(params) {
    if (params['settings'] === 'true') {
      setTimeout(() => {
        this.navigateToTab();
      }, 100);
    }
  }

}
