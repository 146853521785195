<hl-front-page>
  <div class="hero-block hero-block--white">
    <div class="row justify-content-center">
      <div class="col-sm-10">
        <div class="hero-block__panel">
          <h1 class="hero-block__title" [translate]="'LABEL_HEADLINE_WELCOME'" data-cy="headline-welcome"></h1>
          <p class="hero-block__description" [innerHtml]="'LABEL_TEXT_WELCOME' | translate"></p>
          <div class="hero-block__action-panel">
            <hr>
            <!-- <button class="button button--primary" [translate]="'LABEL_LOGIN'"
              (click)="redirectLogin()" data-cy="btn-login"></button>
            <button class="button" [translate]="'LABEL_REGISTER'"
              (click)="redirectRegister()"></button> -->
            <hl-select-mock-user></hl-select-mock-user>
            <hr class="hero-block__seperator-line">
          </div>
        </div>
      </div>
    </div>
    <div class="display-block">
      <div class="hero-block__image aspect-ratio-4x3 aspect-ratio-md-16x9 aspect-ratio-lg-3x1"
        [ngStyle]="{'background-image': welcomePageBgImageStyle, backgroundColor: 'inherit'}">
      </div>
    </div>
  </div>
</hl-front-page>
