import { BrowserModule, EventManager } from '@angular/platform-browser';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslateParser } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { WelcomeModule } from './welcome/welcome.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header.component';
import { ToolBarComponent } from './tool-bar.component';
import { ErrorInterceptor } from './core/interceptors/error-interceptor';
import { MessagingCenterComponent } from './messaging-center/messaging-center.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UserSettingsModule } from './user-settings/user-settings.module';
import { ConfigLoaderService } from './config-loader.service';
import { FrontPagesModule } from './front-pages/front-pages.module';
import { CancelRequestInterceptor } from './core/interceptors/cancel-request-interceptor';
import { CustomEventManager } from './core/event-manager/custom-event-manager';
import { QuillModule } from 'ngx-quill';
import { MyEquipmentSwitchModule } from './equipment/my-equipment-switch/my-equipment-switch.module';
import { MyFiltersSwitchModule } from './equipment/my-filters-switch/my-filters-switch.module';
import { SortablejsModule } from 'nxt-sortablejs';
import { MedalliaUtilService } from './core/utils/medallia-util.service';
import { RxStompService } from './core/stomp/rx-stomp.service';
import { rxStompServiceFactory } from './core/stomp/rx-stomp-service-factory';
import { EnvironmentConfigRestService } from './core/rest-services/environment-config-rest.service';
import { WindowService } from './core/window.service';
import { TranslateDebugParser } from './core/translate-debug/translate-debug.parser';
import { PortalsModule } from './shared-modules/portals.module';
import { MainNavGroupComponent } from './main-nav-group.component';
import { NumberFromIdPipe } from 'app/shared/pipes/number-from-id/number-from-id.pipe';
import { MockHttpBackendProvider } from '../cdmockdata/mock-http-backend';

export const createTranslateLoader = () => AppModule.translateHttpLoader;

export const initialConfigLoader = (http: HttpClient, config: ConfigLoaderService) => () =>
  config.load().then(response => {
    if (response) {
      AppModule.translateHttpLoader = new TranslateHttpLoader(http, response.translation, response.suffix);
    }
  });

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [AppComponent, HeaderComponent, ToolBarComponent, MainNavGroupComponent, MessagingCenterComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    SharedModule,
    WelcomeModule,
    FrontPagesModule,
    MyFiltersSwitchModule,
    MyEquipmentSwitchModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader
      },
      parser: { provide: TranslateParser, useClass: TranslateDebugParser }
    }),
    AppRoutingModule,
    BrowserAnimationsModule,
    UserSettingsModule,
    QuillModule.forRoot(),
    SortablejsModule.forRoot({}),
    PortalsModule
  ],
  providers: [
    MockHttpBackendProvider,
    ConfigLoaderService,
    {
      provide: APP_INITIALIZER,
      useFactory: initialConfigLoader,
      deps: [HttpClient, ConfigLoaderService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (medalliaUtilService: MedalliaUtilService) => () => medalliaUtilService.notifyMedallia(),
      deps: [MedalliaUtilService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CancelRequestInterceptor,
      multi: true
    },
    {
      provide: EventManager,
      useClass: CustomEventManager
    },
    {
      provide: RxStompService,
      useFactory: rxStompServiceFactory,
      deps: [EnvironmentConfigRestService, WindowService]
    },
    NumberFromIdPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  static translateHttpLoader: TranslateHttpLoader;
}
