import { RxStompService } from './rx-stomp.service';
import { appRxStompConfig } from './app-rx-stomp.config';
import { RxStomp } from '@stomp/rx-stomp';
import { EnvironmentConfigRestService } from '../rest-services/environment-config-rest.service';
import { WindowService } from '../window.service';
import { RxStompWrapperImpl } from './rx-stomp-wrapper-impl';

export const rxStompServiceFactory =
  (envConfig: EnvironmentConfigRestService, windowService: WindowService) => {
    const rxStomp = new RxStomp();
    envConfig.getEnvironmentConfig().subscribe(config => {
      const hostname = config.WEBSOCKET_STOMP_HOSTNAME ?? windowService.hostname;
      const protocol = windowService.protocol === 'https:' ? 'wss' : 'ws';
      const port = (hostname === '127.0.0.1' || hostname === 'localhost') ? ':4200' : '';

      rxStomp.configure(appRxStompConfig(`${protocol}://${hostname}${port}/rest/v1/stomp`));
    });
    return new RxStompService(new RxStompWrapperImpl(rxStomp));
  };
