import { WelcomeRoutingModule } from './welcome-routing.module';
import { NgModule } from '@angular/core';
import { WelcomeComponent } from './welcome.component';
import { FrontPagesModule } from '../front-pages/front-pages.module';
import { PipesModule } from '../core/pipes.module';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SelectMockUserComponent } from '../../cdmockdata/select-mock-user.component';

@NgModule({
  imports: [
    WelcomeRoutingModule,
    FrontPagesModule,
    TranslateModule,
    PipesModule,
    CommonModule
  ],
  declarations: [WelcomeComponent, SelectMockUserComponent]
})
export class WelcomeModule {
}
