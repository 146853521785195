import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { UserUtilService } from '../../services/user/user-util.service';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { AuthService } from '../../../auth.service';
import { Observable, of } from 'rxjs';
import { roles } from '../../core-constants.service';

export const dashboardAuthGuard: CanActivateFn = () => {
  const router = inject(Router);
  const userUtilService = inject(UserUtilService);
  const authService = inject(AuthService);

  return userUtilService.getUser().pipe(
    take(1),
    tap(user => authService.setAuthenticatedUser(user)),
    switchMap(user => !!user ? proceedBasedOnUserRoles(userUtilService, router) : navigateToWelcomePageAndReturnFalse(router)),
    catchError(() => {
      authService.setAuthenticatedUser(null);
      return navigateToWelcomePageAndReturnFalse(router);
    }));
}

const proceedBasedOnUserRoles = (userUtilService: UserUtilService, router: Router): Observable<boolean> => {
  return userUtilService.checkUserRoles(roles).pipe(
    map(roleResp => {
      if (isSecurityAdvisoryViewXiaUser(roleResp)) {
        router.navigate(['/advisories'], {replaceUrl: true}).then(r => r);
        return false;
      }
      return true;
    })
  );
}

const isSecurityAdvisoryViewXiaUser = (roleResp) => {
  const rolesSet = Object.keys(roleResp).filter(key => roleResp[key]);
  return (rolesSet.length === 2 && rolesSet.includes('userRole') && rolesSet.includes('securityAdvisoryViewXiaRole'));
}

const navigateToWelcomePageAndReturnFalse = (router: Router): Observable<boolean> => {
  router.navigate(['/welcome']);
  return of(false);
}
