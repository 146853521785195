@if (isAuthorized && config && config.messagesLimit > 0) {
  <div class="meta-menu__item messaging-center" [class.is-open]="isDropdownOpen" data-cy="header-messaging-icon">
    <button class="meta-menu__link" (click)="toggleDropdown()" href="javascript:void(0)" #dropDownToggle>
      <i class="icon-bell"></i>
      @if (unseenMessages.length > 0) {
        <span class="label label--superscript messaging-center__notification-label">{{ unseenMessages.length }}</span>
      }
    </button>
    <div class="messaging-center__panel">
      @if (!isLoaded) {
        <a class="menu__item messaging-center__item">
          <hl-progress-indicator [loading]="!isLoaded"></hl-progress-indicator>
        </a>
      } @else {
        <div>
          <div class="messaging-center__header" data-cy="messaging-center-header">
            <span id="numberOfNewEventsDetailed"
              [innerHTML]="'LABEL_NUMBER_OF_NEW_EVENTS_DETAILED' | translate | replace: '#COUNT#/' + unseenMessages.length"
              class="messaging-center__new-event-label"></span>
            <button class="button button--control button--small messaging-center__reload-button" (click)="refresh()"><i class="icon-reload"></i>
            </button>
          </div>
          @if (unseenMessages.length !== 0) {
            <div class="messaging-center__group-header" [translate]="'LABEL_NEW_EVENTS'"></div>
          }
          <div class="collection">
            @for (message of unseenMessages; track message; let i = $index) {
              @if (i < config.messagesLimit && isSystemUpdate(message)) {
                <div class="collection-item list-item messaging-center__item" (click)="handleNavigation($event, message)">
                  <div class="collection__content">
                    <div title={{message.title}}>{{ message.title }}</div>
                    <div class="font-size-xsmall">
                      <span [translate]="'CATEGORY'"></span>:
                      <span class="label-status-{{message.updateStatusColor}}">{{ message.categoryKey | translate }}</span>
                    </div>
                  </div>
                </div>
              }
              @if (i < config.messagesLimit && isPreliminaryInvoiceNotification(message)) {
                <div class="collection-item list-item messaging-center__item" (click)="handleNavigation($event, message)">
                  <div class="collection__content">
                    <div title={{message.title}}>
                      <span [translate]="'LABEL_PRELIM_INVOICES_NOTIFICATION_TITLE'"></span>
                      <span>: {{ message.title }}</span>
                    </div>
                    <div class="font-size-xsmall">
                      <span [translate]="'LABEL_PRELIM_INVOICES_NOTIFICATION_STATUS'"></span>:
                      <span
                        class="{{message.messageStatus | preliminaryInvoiceStatusColorPipe}}">{{ message.messageStatus | preliminaryInvoiceStatusLabelPipe | translate }}</span>
                    </div>
                  </div>
                </div>
              }
              @if (i < config.messagesLimit && isSubprocessorNotification(message)) {
                <div class="collection-item list-item messaging-center__item" (click)="handleNavigation($event, message)">
                  <div class="collection__content">
                    @if (isSubprocessorCountryNotification(message)) {
                      <div [title]="'LABEL_MY_NOTIFICATION_SUBPROCESSOR_LIST_UPDATED' | translate | replace:'#COUNTRY#/' + user.country">
                        <span [innerHTML]="'LABEL_MY_NOTIFICATION_SUBPROCESSOR_LIST_UPDATED' | translate | replace:'#COUNTRY#/' + user.country"></span>
                      </div>
                    } @else {
                      <div [title]="'LABEL_MY_NOTIFICATION_SUBPROCESSOR_LIST_HQ_UPDATED' | translate">
                        <span [innerHTML]="'LABEL_MY_NOTIFICATION_SUBPROCESSOR_LIST_HQ_UPDATED' | translate"></span>
                      </div>
                    }
                    <div class="font-size-xsmall ">
                      <span [translate]="'CATEGORY'"></span>:
                      <span class="label-status-green">{{ 'LABEL_MY_NOTIFICATION_DOCUMENT_UPDATE' | translate }}</span>
                    </div>
                  </div>
                </div>
              }
              @if (i < config.messagesLimit && isSecurityAdvisoryNotification(message)) {
                <div class="collection-item list-item messaging-center__item" (click)="handleNavigation($event, message)">
                  <div class="collection__content">
                    <div class="overflow-text" title={{message.title}}>{{ message.title }}</div>
                    <ul class="bare-list info-list label-status-white">
                      <li class="info-list__item"><i class="icon-qr-bar-code"></i> {{ message.advisoryId }}</li>
                      @if (message.cvssScore) {
                        <li class="info-list__item"><i class="icon-favorite"></i> {{ message.cvssScore }}</li>
                      }
                    </ul>
                  </div>
                </div>
              }
              @if (i < config.messagesLimit && isAdvisoryCybersecurityNotification(message)) {
                <div class="collection-item list-item messaging-center__item" (click)="handleNavigation($event, message)">
                  <div class="collection__content">
                    <div class="overflow-text" title={{message.title}}>{{ message.title }}</div>
                    <div class="overflow-text label-status-white">{{ message.description }}</div>
                  </div>
                </div>
              }
              @if (i < config.messagesLimit && isSurveyNotification(message)) {
                <div class="collection-item list-item messaging-center__item" (click)="handleNavigation($event, message)">
                  <div class="collection__content">
                    <div class="overflow-text" title="{{'LABEL_SURVEY_NOTIFICATION_TITLE' | translate}} {{message.identifier}}">
                      {{ 'LABEL_SURVEY_NOTIFICATION_TITLE' | translate }} {{ message.identifier }}
                    </div>
                    <div class="font-size-xsmall overflow-text label-status-white" title="{{'LABEL_SURVEY_NOTIFICATION_DESCRIPTION' | translate}}"
                      [translate]="'LABEL_SURVEY_NOTIFICATION_DESCRIPTION'"></div>
                  </div>
                </div>
              }
              @if (i < config.messagesLimit && isDocumentNotification(message)) {
                <div class="collection-item list-item messaging-center__item">
                  <div class="collection__content">
                    <div class="overflow-text">
                      {{ 'External document updated' }} {{ message.identifier }}
                    </div>
                    <div class="font-size-xsmall">
                      <span [translate]="'CATEGORY'"></span>:
                      <span class="label-status-green">{{ message.messageType }}</span>
                    </div>
                  </div>
                </div>
              }
            }
          </div>
          @if (seenMessages.length !== 0 && config.messagesLimit > unseenMessages.length) {
            <div class="messaging-center__group-header" [translate]="'LABEL_EARLIER_EVENTS'"></div>
          }
          <div class="collection">
            @for (message of seenMessages; track message; let i = $index) {
              @if (i < config.messagesLimit - unseenMessages.length && isSystemUpdate(message)) {
                <div class="collection-item list-item messaging-center__item" (click)="handleNavigation($event, message)">
                  <div class="collection__content">
                    <div title={{message.title}}>{{ message.title }}</div>
                    <div class="font-size-xsmall">
                      <span [translate]="'CATEGORY'"></span>:
                      <span class="label-status-{{message.updateStatusColor}}">{{ message.categoryKey | translate }}</span>
                    </div>
                  </div>
                </div>
              }
              @if (i < config.messagesLimit - unseenMessages.length && isPreliminaryInvoiceNotification(message)) {
                <div class="collection-item list-item messaging-center__item" (click)="handleNavigation($event, message)">
                  <div class="collection__content">
                    <div title={{message.title}}>
                      <span [translate]="'LABEL_PRELIM_INVOICES_NOTIFICATION_TITLE'"></span>
                      <span>: {{ message.title }}</span>
                    </div>
                    <div class="font-size-xsmall">
                      <span [translate]="'LABEL_PRELIM_INVOICES_NOTIFICATION_STATUS'"></span>:
                      <span
                        class="{{message.messageStatus | preliminaryInvoiceStatusColorPipe}}">{{ message.messageStatus | preliminaryInvoiceStatusLabelPipe | translate }}</span>
                    </div>
                  </div>
                </div>
              }
              @if (i < config.messagesLimit - unseenMessages.length && isSubprocessorNotification(message)) {
                <div class="collection-item list-item messaging-center__item" (click)="handleNavigation($event, message)">
                  <div class="collection__content">
                    @if (isSubprocessorCountryNotification(message)) {
                      <div [title]="'LABEL_MY_NOTIFICATION_SUBPROCESSOR_LIST_UPDATED' | translate | replace:'#COUNTRY#/' + user.country">
                        <span
                          [innerHTML]="'LABEL_MY_NOTIFICATION_SUBPROCESSOR_LIST_UPDATED' | translate | replace:'#COUNTRY#/' + user.country"></span>
                      </div>
                    } @else {
                      <div [title]="'LABEL_MY_NOTIFICATION_SUBPROCESSOR_LIST_HQ_UPDATED' | translate">
                        <span [innerHTML]="'LABEL_MY_NOTIFICATION_SUBPROCESSOR_LIST_HQ_UPDATED' | translate"></span>
                      </div>
                    }
                    <div class="font-size-xsmall">
                      <span [translate]="'CATEGORY'"></span>:
                      <span class="label-status-green">{{ 'LABEL_MY_NOTIFICATION_DOCUMENT_UPDATE' | translate }}</span>
                    </div>
                  </div>
                </div>
              }
              @if (i < config.messagesLimit && isSecurityAdvisoryNotification(message)) {
                <div class="collection-item list-item messaging-center__item" (click)="handleNavigation($event, message)">
                  <div class="collection__content">
                    <div class="overflow-text" title={{message.title}}>{{ message.title }}</div>
                    <ul class="bare-list info-list label-status-white">
                      <li class="info-list__item"><i class="icon-qr-bar-code"></i> {{ message.advisoryId }}</li>
                      @if (message.cvssScore) {
                        <li class="info-list__item"><i class="icon-favorite"></i> {{ message.cvssScore }}</li>
                      }
                    </ul>
                  </div>
                </div>
              }
              @if (i < config.messagesLimit && isAdvisoryCybersecurityNotification(message)) {
                <div class="collection-item list-item messaging-center__item" (click)="handleNavigation($event, message)">
                  <div class="collection__content">
                    <div class="overflow-text" title={{message.title}}>{{ message.title }}</div>
                    <div class="overflow-text label-status-white">{{ message.description }}</div>
                  </div>
                </div>
              }
              @if (i < config.messagesLimit && isSurveyNotification(message)) {
                <div class="collection-item list-item messaging-center__item" (click)="handleNavigation($event, message)">
                  <div class="collection__content">
                    <div class="overflow-text" title="{{'LABEL_SURVEY_NOTIFICATION_TITLE' | translate}} {{message.identifier}}">
                      {{ 'LABEL_SURVEY_NOTIFICATION_TITLE' | translate }} {{ message.identifier }}
                    </div>
                    <div class="font-size-xsmall overflow-text label-status-white" title="{{'LABEL_SURVEY_NOTIFICATION_DESCRIPTION' | translate}}"
                      [translate]="'LABEL_SURVEY_NOTIFICATION_DESCRIPTION'"></div>
                  </div>
                </div>
              }
              @if (i < config.messagesLimit && isDocumentNotification(message)) {
                <div class="collection-item list-item messaging-center__item">
                  <div class="collection__content">
                    <div class="overflow-text">
                      {{ 'External document updated' }} {{ message.identifier }}
                    </div>
                    <div class="font-size-xsmall">
                      <span [translate]="'CATEGORY'"></span>:
                      <span class="label-status-green">{{ message.messageType }}</span>
                    </div>
                  </div>
                </div>
              }
            }
          </div>
        </div>
      }
    </div>
  </div>
}

<hl-overlay #advisoryNotificationOverlay [closeUrl]="[]">
  <div class="scrollable-content scrollable-content--with-pagination">
    <div class="margin-bottom-1">
      <p class="lead margin-bottom-0 margin-top-0">{{ selectedMessage?.title }}</p>
    </div>
    <section class="dataview overflow-hidden">
      <div class="row">
        <div class="col-12" [innerHTML]="selectedMessage?.description | toHtml"></div>
      </div>
    </section>
  </div>
</hl-overlay>
