import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { WindowService } from '../window.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TranslateDebugService {

  private readonly STORAGE_KEY = 'ngx-translate-debug';
  private readonly isSsr: boolean;
  private isEnabled: boolean;

  constructor(
    private injector: Injector,
    private windowService: WindowService,
    @Inject(PLATFORM_ID) platformId: object
  ) {
    this.isSsr = isPlatformServer(platformId);
    this.isEnabled = !this.isSsr && this.checkEnabledFromStorage();
  }

  get isDebugEnabled(): boolean {
    return this.isEnabled;
  }

  toggleDebug(): void {
    if (!this.isSsr) {
      this.isEnabled = !this.isEnabled;
      this.writeEnabledToStorage(this.isEnabled);
      this.triggerTranslationReload();
    }
  }

  private checkEnabledFromStorage(): boolean {
    return this.storage.getItem(this.STORAGE_KEY) === 'true';
  }

  private writeEnabledToStorage(enabled: boolean): void {
    this.storage.setItem(this.STORAGE_KEY, enabled ? 'true' : 'false');
  }

  private get storage(): Storage {
    return this.windowService.nativeWindow.localStorage;
  }

  private triggerTranslationReload(): void {
    const translateService = this.injector.get(TranslateService);

    translateService.onLangChange.emit({
      lang: translateService.currentLang,
      translations: translateService.translations
    });
  }

}
