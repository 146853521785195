import { NgModule } from '@angular/core';
import { SharedModule } from 'app/shared/shared.module';
import { MyFiltersSwitchComponent } from './my-filters-switch.component';

@NgModule({
  imports: [SharedModule],
  declarations: [MyFiltersSwitchComponent],
  exports: [MyFiltersSwitchComponent]
})
export class MyFiltersSwitchModule {}
