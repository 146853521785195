import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { UserUtilService } from './user-util.service';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable({providedIn: 'root'})
export class AuthGuardService  {

  constructor(public userUtilService: UserUtilService,
    public router: Router) {
  }

  canActivate(): Observable<UrlTree> {
    return this.userUtilService.getUser()
      .pipe(
        map(user => !!user),
        map(isAuthenticated => this.getUrlTree(isAuthenticated)),
        catchError(() => of(this.getUrlTree(false)))
      );
  }

  private getUrlTree(isAuthenticated: boolean) {
    return this.router.parseUrl(isAuthenticated ? '/404' : '/welcome');
  }
}
