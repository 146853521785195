import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { isEqual } from 'lodash-es';
import { map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { CountryConfigRestService } from '../../rest-services/country-config-rest.service';
import { ServiceMetricsRestService } from '../../rest-services/service-metrics-rest.service';
import { AuthorizationService } from '../authorization.service';
import { DASHBOARD } from '../feature.guard';


export function reportingGuard(mode: 'reporting' | 'service-metrics' = 'reporting'): CanActivateFn {
  return () => {

    const dashboardUrl = inject(Router).parseUrl(DASHBOARD);
    const config$ = inject(CountryConfigRestService).getConfig();
    const isServiceMetricsAvailable$ = inject(ServiceMetricsRestService).isServiceMetricsAvailable();
    const hasSprPermission$ = inject(AuthorizationService).showSprTab;

    const getResult = (isReportingAvailable: UrlTree | boolean, hasSprPermission: boolean) =>
      (isReportingAvailable && hasSprPermission) ? dashboardUrl : isReportingAvailable;

    const serviceMetricsGuard = (isServiceMetricsAvailable: boolean, hasSprPermission: boolean) => {
      const isReportingAvailable = isServiceMetricsAvailable ? true : dashboardUrl;
      return getResult(isReportingAvailable, hasSprPermission);
    };

    const reportingGuardImpl = (config: Record<string, string>, isServiceMetricsAvailable: boolean, hasSprPermission: boolean) => {
      const ownReports = isEqual(config.LIFENET_OWN_REPORTS_AVAILABLE, 'true') || dashboardUrl
      const isReportingAvailable = isServiceMetricsAvailable ? dashboardUrl : ownReports;
      return getResult(isReportingAvailable, hasSprPermission);
    };

    return combineLatest([config$, isServiceMetricsAvailable$, hasSprPermission$]).pipe(
      map(([config, {available: isServiceMetricsAvailable}, hasSprPermission]) => {
        if (isServiceMetricsAvailable && hasSprPermission) {
          return dashboardUrl;
        }
        return mode === 'reporting'
          ? reportingGuardImpl(config, isServiceMetricsAvailable, hasSprPermission)
          : serviceMetricsGuard(isServiceMetricsAvailable, hasSprPermission);
      })
    );
  }
}
