@if (permissions$ | async; as permissions) {
  <div class="meta-menu">
    <ul class="bare-list meta-menu__list">
      <hl-messaging-center></hl-messaging-center>
      @if (permissions.canShowTranslationKeys) {
        <li class="meta-menu__item hidden-lg-down" data-cy="header-switch-view-translate-keys">
          <button (click)="toggleShowTranslationKeys($event)" class="meta-menu__link">
            <i aria-hidden="true" class="icon-text-check"></i>
          </button>
        </li>
      }
      <li class="meta-menu__item hidden-lg-down" data-cy="header-contact-info">
        <button class="meta-menu__link" (click)="showContactInfoModal($event)">
          <i aria-hidden="true" class="icon-email"></i>
        </button>
      </li>
      @if (permissions.canShowArakhRedirect) {
        <li class="meta-menu__item hidden-lg-down" data-cy="header-arakh-redirect">
          <button id="openArakh" class="meta-menu__link" (click)="redirectToArakh($event)">
            <i aria-hidden="true" class="icon-tool"></i>
          </button>
        </li>
      }
      @if (showGlobeIcon()) {
        <li class="meta-menu__item hidden-lg-down" data-cy="header-switch-language-modal">
          <button class="meta-menu__link" (click)="showSwitchLanguageModal($event)">
            <i aria-hidden="true" class="icon-world"></i>
          </button>
        </li>
      }
      @if (permissions.canImpersonateToEquipment) {
        <li class="meta-menu__item hidden-lg-down">
          <button class="meta-menu__link" (click)="showSelectEquipmentModal($event)">
            <i aria-hidden="true" class="icon-search-toolbar"></i>
          </button>
        </li>
      }
      <li class="meta-menu__item dropdown dropdown--right hidden-xl-up margin-right-0" [class.is-open]="isDropdownOpen">
        <button class="button button--control icon-dots-ellipsis-horizontal" (click)="toggleDropdown()" #dropDownToggle></button>
        <div class="dropdown__panel">
          <ul class="menu">
            @if (permissions.canShowTranslationKeys) {
              <li class="menu__item">
                <button (click)="toggleShowTranslationKeys($event)" class="meta-menu__link">
                  <i aria-hidden="true" class="icon-text-check"></i>
                  <span class="meta-menu__title" translate="LABEL_TOGGLE_TRANSLATE_KEY"></span>
                </button>
              </li>
            }
            <li class="menu__item hidden-lg-up">
              @if (myFiltersFeatureToggle) {
                <hl-my-filters-switch (myFiltersClicked)="navigateToMyFilters()"></hl-my-filters-switch>
              } @else {
                <hl-my-equipment-switch></hl-my-equipment-switch>
              }
            </li>
            <li class="menu__item">
              <button class="meta-menu__link" (click)="showContactInfoModal($event)">
                <i aria-hidden="true" class="icon-email"></i>
                <span class="meta-menu__title" [translate]="'HEADER_KONTAKT'"></span>
              </button>
            </li>
            @if (showGlobeIcon()) {
              <li class="menu__item">
                <button class="meta-menu__link" (click)="showSwitchLanguageModal($event)">
                  <i aria-hidden="true" class="icon-world"></i>
                  <span class="meta-menu__title">{{ 'LABEL_COUNTRY_AND_LANGUAGE' | translate }}</span>
                </button>
              </li>
            }
            @if (permissions.canImpersonateToEquipment) {
              <li class="menu__item">
                <button class="meta-menu__link" (click)="showSelectEquipmentModal($event)">
                  <i aria-hidden="true" class="icon-search-toolbar"></i>
                  <span class="meta-menu__title" [translate]="'IMPERSONATE_TO_EQUIPMENT_TITLE'"></span>
                </button>
              </li>
            }
          </ul>
        </div>
      </li>
    </ul>
  </div>
}

<hl-switch-language-modal #changeLanguageCountryModal [user]="user" [countries]="countries" [languages]="languages"
  [selectedCountry]="selectedCountry" [selectedLocale]="selectedLocale"></hl-switch-language-modal>
<hl-additional-contacts #contactModal></hl-additional-contacts>
<hl-select-equipment-modal *hlItemShowHideHandler="'config';config:'TOGGLE_EQUIPMENT_IMPERSONATION'" [user]="user"
  #selectEquipmentModal></hl-select-equipment-modal>
