import { combineLatest, Observable, Subject } from 'rxjs';
import { ImpersonationCommunicationService } from './core/component-communication-services/impersonation/impersonation-communication.service';
import { SelectOption } from './core/models/select-option';
import { ImpersonationRestService } from './core/rest-services/impersonation-rest.service';
import { User } from './core/models/user';
import { UserUtilService } from './core/services/user/user-util.service';
import { ImpersonationUtilsService } from './core/utils/impersonation-utils.service';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { isEqual, union } from 'lodash-es';
import { DropdownOptions } from './core/models/dropdown-options';
import { AdditionalContactsComponent } from './shared/components/additional-contacts/additional-contacts.component';
import { SwitchLanguageModalComponent } from './shared/modal-popup/switch-language-modal/switch-language-modal.component';
import { FilterUtilService } from './core/utils/filter-util.service';
import { SelectEquipmentModalComponent } from './shared/modal-popup/select-equipment-modal/select-equipment-modal.component';
import { roles } from './core/core-constants.service';
import { CountryConfigRestService } from './core/rest-services/country-config-rest.service';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { TranslateDebugService } from './core/translate-debug/translate-debug.service';
import { WindowService } from './core/window.service';

@Component({
  selector: 'hl-tool-bar',
  templateUrl: './tool-bar.component.html'
})
export class ToolBarComponent implements OnInit, OnDestroy {

  @ViewChild('contactModal')
  contactModalView: AdditionalContactsComponent;

  @ViewChild('changeLanguageCountryModal')
  switchLanguageModalComponent: SwitchLanguageModalComponent;

  @ViewChild('selectEquipmentModal')
  selectEquipmentModalComponent: SelectEquipmentModalComponent;

  @ViewChild('dropDownToggle') dropDownToggleEl: ElementRef;

  @Input()
  myFiltersFeatureToggle = false;

  @Output() myFiltersClicked = new EventEmitter();

  selectedCountry: string;
  selectedLocale: string;
  countries: DropdownOptions[];
  languages: SelectOption[];

  user: User = null;

  isDropdownOpen = false;
  permissions$: Observable<{ canImpersonateToEquipment: boolean, canShowTranslationKeys: boolean, canShowArakhRedirect: boolean }>;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private impersonationUtilService: ImpersonationUtilsService,
    private impersonationCommunicationService: ImpersonationCommunicationService,
    private userUtilService: UserUtilService,
    private impersonationRestService: ImpersonationRestService,
    private filterUtilService: FilterUtilService,
    private configService: CountryConfigRestService,
    private translateDebug: TranslateDebugService,
    private windowService: WindowService
  ) {
  }

  @HostListener('document:click.out-zone', ['$event'])
  clickout(event) {
    if (
      this.isDropdownOpen && !this.dropDownToggleEl.nativeElement.contains(event.target)
    ) {
      this.isDropdownOpen = false;
    }
  }

  ngOnInit() {
    this.init();

    // Note:- Only one time event listeners be registered
    this.registerEventListeners();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  init() {
    this.userUtilService.getUser()
      .pipe(
        tap(user => this.user = user),
        switchMap(() => this.checkAndSetCountryLanguageSwitchBasedOnUserAndImpersonation())
      )
      .subscribe();
    this.checkUserRoles();
  }

  checkUserRoles() {
    const rolesToCheck$ = this.userUtilService.checkUserRoles({
      canViewEquipment: roles.viewEquipmentRole,
      canImpersonate: roles.impersonateUserRole,
      isCountryAdmin: roles.countryAdminRole,
      isMasterAdmin: roles.masterAdminRole
    });
    const config$ = this.configService.getConfig();

    this.permissions$ = combineLatest([rolesToCheck$, config$]).pipe(
      map(([rolesToCheck, config]) => ({
        canImpersonateToEquipment: rolesToCheck.canImpersonate && rolesToCheck.canViewEquipment && isEqual(config.TOGGLE_EQUIPMENT_IMPERSONATION, 'true'),
        canShowTranslationKeys: (rolesToCheck.isMasterAdmin || rolesToCheck.isCountryAdmin) && isEqual(config.TOGGLE_SHOW_TRANSLATION_KEYS, 'true'),
        canShowArakhRedirect: (rolesToCheck.isMasterAdmin || rolesToCheck.isCountryAdmin) && isEqual(config.TOGGLE_SHOW_ARAKH_REDIRECT_ICON, 'true')
      })),
      takeUntil(this.unsubscribe$)
    );
  }

  registerEventListeners() {
    this.impersonationCommunicationService.onCountryLanguageChange$
      .pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.init());
  }

  checkAndSetCountryLanguageSwitchBasedOnUserAndImpersonation() {
    // get list of currently impersonated customers from the data share service
    return this.impersonationRestService
      .getImpersonatedCustomers()
      .pipe(tap(customerResponse => {
        const distinctListOfImpersonatedCountries = this.getListOfImpersonatedCustCountries(
          customerResponse
        );

        // list of allowed languages for the country assigned to user
        const listOfLanguagesForUserCountry = this.impersonationUtilService.getListOfAllowedLanguagesForUserCountry(
          this.user.countries,
          this.user.country
        );

        if (this.user.countries.length >= 1) {
          this.setCountryAndLocaleAndLanguages();
        }

        if (
          listOfLanguagesForUserCountry &&
          listOfLanguagesForUserCountry.length >= 1 &&
          distinctListOfImpersonatedCountries
        ) {
          this.countries = this.impersonationUtilService.generateCountryTranslationMapping(
            distinctListOfImpersonatedCountries,
            false
          );
        }
      }));
  }

  getListOfImpersonatedCustCountries(data) {
    const impersonatedCustomerCountries = this.filterUtilService.getListOfPropertyValuesFromListOfObject(
      data.customers,
      'country'
    );
    const impersonatedCustomerGroupsCountries = this.filterUtilService.getListOfPropertyValuesFromListOfObject(
      data.customerGroups,
      'country'
    );

    return union(
      impersonatedCustomerCountries,
      impersonatedCustomerGroupsCountries
    );
  }

  /**
   *
   * @description
   * set the selected country, locale and list of languages.
   *
   * Note: This should be called only during initial load and there after
   * selection of locale value should be made based on users default language in the
   * countries list -> /users/self
   */
  setCountryAndLocaleAndLanguages() {
    this.selectedCountry = this.user.country;
    this.selectedLocale = this.impersonationUtilService.getLocaleWithFallbackCheck(
      this.user.language,
      this.user.country,
      this.user.countries
    );
    this.languages = this.impersonationUtilService.generateLanguageTranslationMappingAndAddDefaultLang(
      this.user.countries,
      this.selectedCountry
    );
  }

  showGlobeIcon(): boolean {
    return (
      (this.countries && this.countries.length > 1) ||
      (this.languages && this.languages.length > 1)
    );
  }

  showContactInfoModal(event: Event) {
    event.preventDefault();
    this.contactModalView.show();
  }

  redirectToArakh(event: Event) {
    event.preventDefault();
    window.open('https://fleet-test.siemens-healthineers.com/arakh/login', '_blank');
  }

  showSwitchLanguageModal(event: Event) {
    event.preventDefault();
    this.switchLanguageModalComponent.show();
  }

  showSelectEquipmentModal(event: Event) {
    event.preventDefault();
    this.selectEquipmentModalComponent.show();
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  toggleShowTranslationKeys(event?: Event) {
    event?.preventDefault();
    this.translateDebug.toggleDebug();
    this.windowService.nativeWindow.location.reload();
  }

  navigateToMyFilters() {
    this.myFiltersClicked.emit();
  }
}
