import { SprDashboardWidgets, SprWidget, SprWidgetDetails } from "app/core/models/spr/configuration-spr";
import { SprDashboardFilter } from "app/core/models/spr/filter/spr-filter";
import { TubeGuardDetails } from "app/core/models/spr/tube-guard-details";
import { ChartTypes, KpiTypes } from "app/spr/configuration/spr-configuration-constants";
import { TimeSpan } from "app/spr/spr-filter-constants";
import * as _ from 'lodash';

export const CdDashboardSprFilter: SprDashboardFilter = {
  startDate: '2022-01-01',
  endDate: '2022-12-12',
  timeSpan: TimeSpan.TWELVE_MONTHS,
  filters: [
    {
      name: "modalityCodes",
      type: "",
      values: [
        {
          title: "Angiography",
          value: "01",
        },
        {
          title: "Radiography",
          value: "RAD",
        },
        {
          title: "Syngo",
          value: "SY"
        }
      ],
    }
  ]
}

export const CdSprWidgetDonut: SprWidget = {
  id: 1,
  name: 'Donut',
  type: ChartTypes.DONUT,
  kpis: [],
  state: null,
  modificationDate: '2022-05-05',
  allowedCustomers: [],
  calculatedData: [
    {
      name: '',
      series: [
        {
          name: 'test',
          value: 10
        },
        {
          name: 'test2',
          value: 2
        },
        {
          name: '',
          value: '59.2%'
        },
      ]
    }
  ],
}

export const CdSprWidgetNumber: SprWidget = {
  id: 2,
  name: 'Number',
  type: ChartTypes.NUMBER,
  kpis: [
    {
      duration: null,
      quantity: { id: 1, name: 'Total Down' },
      virtualKpi: null,
      kpiOrder: 1,
      opposite: false,
    },
    {
      duration: null,
      quantity: { id: 2, name: 'Partial Down' },
      virtualKpi: null,
      kpiOrder: 2,
      opposite: false,
    },
    {
      duration: null,
      quantity: { id: 3, name: 'Proactive' },
      virtualKpi: null,
      kpiOrder: 3,
      opposite: false,
    },
    {
      duration: null,
      quantity: { id: 4, name: 'Weekend Fix' },
      virtualKpi: null,
      kpiOrder: 4,
      opposite: false,
    }
  ],
  state: null,
  modificationDate: '2022-04-04',
  allowedCustomers: [],
  calculatedData: [
    {
      name: '',
      series: [
        {
          name: 'Total Down',
          value: 225
        },
        {
          name: 'Partial Down',
          value: 430
        },
        {
          name: 'Proactive',
          value: 129
        },
        {
          name: 'Weekend Fix',
          value: 44
        },
      ]
    }
  ],
}

export const CdSprWidgetLine: SprWidget = {
  id: 3,
  name: 'Line',
  type: ChartTypes.LINE,
  kpis: [
    {
      duration: null,
      quantity: { id: 1, name: 'test' },
      virtualKpi: null,
      kpiOrder: 1,
      opposite: false,
    },
  ],
  state: null,
  modificationDate: '2022-05-05',
  allowedCustomers: [],
  calculatedData: [
    {
      name: 'test',
      series: [
        {
          name: '1',
          value: 10
        },
        {
          name: '2',
          value: 2
        },
        {
          name: '3',
          value: 3
        },
        {
          name: '4',
          value: 34
        },
        {
          name: '5',
          value: 15
        },
      ]
    }
  ],
}

export const CdSprWidgetBar: SprWidget = {
  id: 4,
  name: 'Bar',
  type: ChartTypes.BAR,
  kpis: [
    {
      duration: null,
      quantity: { id: 1, name: 'test' },
      virtualKpi: null,
      kpiOrder: 1,
      opposite: false,
    },
  ],
  state: null,
  modificationDate: '2022-05-05',
  allowedCustomers: [],
  calculatedData: [
    {
      name: 'test',
      series: [
        {
          name: '',
          value: 10
        },
        {
          name: 'test2',
          value: 2
        },
        {
          name: 'test3',
          value: 3
        },
        {
          name: 'test4',
          value: 34
        },
        {
          name: 'test5',
          value: 15
        },
      ]
    },
    {
      name: 'test2',
      series: [
        {
          name: 'test',
          value: 50
        },
        {
          name: 'test2',
          value: 40
        },
        {
          name: 'test3',
          value: 76
        },
      ]
    }
  ],
}

export const CdTubeDetails: TubeGuardDetails = {
  name: "TubeGuard",
  items: [
    {
      equipmentKey: 'eqkey',
      functionalLocation: 'funcLocat',
      productName: 'prName',
      serialNumber: '123123',
      modalityDesc: 'Modalityy',
      lastCheckedDate: '2022-04-04',
      tubes: [
        {
          plane: 'A',
          marker: 1,
        },
        {
          plane: 'B',
          marker: 2,
        },
      ],
      serviceActions: [
        {
          date: '2022-05-05',
          eventInstruction: 'Check',
          ticketNumber: '6646345345',
        },
        {
          date: '2022-06-06',
          eventInstruction: 'Replace',
          ticketNumber: '555444333',
        }
      ]
    },
  ]
}

export const CdSprDashboardWidgets: SprDashboardWidgets = {
  filter: CdDashboardSprFilter,
  graphs: [
    CdSprWidgetDonut,
    CdSprWidgetNumber,
    CdSprWidgetLine,
    CdSprWidgetBar,
  ],
}

export const CdSprDashboardDetails: SprWidgetDetails = {
  ...CdSprWidgetLine,
  inactiveEquipments: [],
};

const convertCalculatedData = (widget) => {
  let calculatedData = widget.calculated_data;
  if (widget.type === 'line') {
    calculatedData = widget.calculated_data.map(cd => {
      const seriesLength = cd.series.length;
      return {
        "name": cd.name,
        "series": cd.series.map((cds, i) => {
          const today = new Date();
          const newDate = new Date(today.setMonth(today.getMonth() - seriesLength + i));
          const ymd = newDate.toISOString().split('T')[0].split('-');
          return {
            name: `${ymd[0]}-${ymd[1]}`,
            value: cds.value,
          }
        }),
      };
    });
  }
  Object.freeze(calculatedData);
  return calculatedData;
}

const generateFilter = ({ equipment, modalities, details = null, widget = null, detailsType = null, customers = [] }) => {
  let modalitiesIds;

  if (details) {
    modalitiesIds = Array.from(new Set(details.map(el => el.modality.id)))
  } else {
    modalitiesIds = Array.from(new Set(equipment.map(el => el.modality)))
  }

  let equipmentFiltered = equipment;
  if (detailsType === 'overview') {
    equipmentFiltered = equipment;  
  } else if (detailsType === 'LD') {
    equipmentFiltered = equipment.filter(e => e.serial_number.startsWith('LD'))
  } else {
    const modality = modalities.find(m => m.modality_code === detailsType);
    if (modality && modality.id) {
      equipmentFiltered = (equipment || []).filter(e => e.modality.toString() === modality.id.toString());
    }
  }

  const filters = [
    {
      name: "modalityCodes",
      type: "SelectedOption",
      values: modalities.filter(m => modalitiesIds.includes(m.id)).map(m => {
        return {
          title: m.modality_description,
          value: m.modality_code,
        }
      }),
    }
  ];

  if (customers.length) {
    const customersEq = customers.map(c => {
      return {
        title: `${c.customer_name},${c.customer_id}`,
        values: equipmentFiltered.filter(eq => eq.customer_name.toString() === c.id.toString()).map(e => {
          return { 
            title: `${e.product_name},${e.serial_number}`,
            value: e.siemens_id,
          }
        }),
      }
    });

    filters.push(
      {
        name: "customers",
        type: "SelectedOption",
        values: customersEq.filter(c => c.values.length),
      }
    );
  }

  if (widget) {
    const kpisFilter = [];
    (widget.kpis || []).forEach(kpi => {
      if (kpi.duration) {
        kpisFilter.push({ title: KpiTypes.DURATION, value: kpi.duration.id.toString() })
      }
      if (kpi.quantity) {
        kpisFilter.push({ title: KpiTypes.QUANTITY, value: kpi.quantity.id.toString() })
      }
      if (kpi.virtualKpi) {
        kpisFilter.push({ title: KpiTypes.VIRTUAL, value: kpi.virtualKpi.id.toString() })
      }
    })
    if (kpisFilter.length) {
      filters.push({
        name: "kpi",
        type: "SelectedOption",
        values: kpisFilter,
      })
    }
  }

  const filter: SprDashboardFilter = {
    startDate: '2021-06-01',
    endDate: new Date().toISOString().split('T')[0],
    timeSpan: TimeSpan.TWELVE_MONTHS,
    filters: filters,
  }

  return filter;
}

export function generateSprDashboard({ widgets, equipment, modalities, customers }) {
  const result = {
    filter: generateFilter({ equipment: equipment.data, modalities: modalities.data, details: null, detailsType: 'overview', customers: customers.data }),
    graphs: widgets.data.map(el => {
      return {
        ...el,
        calculatedData: convertCalculatedData(el),
        state: null,
        modificationDate: new Date().toISOString().split('T')[0],
        allowedCustomers: [],
        graphConfigurations: [],
      };
    }),
  }
  return result;
}

export function generateSprDashboardDetails({ widgets, equipment, modalities, graphId, tickets, detailsType, details, customers }) {
  let equipmentFiltered = equipment.data;
  let detailsData;
  const widget = widgets.data.find(el => el.id.toString() === graphId);
  const detailsFiltered = details.data.filter(d => (d.widget.id.toString() === graphId));

  if (detailsType === 'overview') {
    if (detailsFiltered.length && (widget.type === 'donut' || widget.type === 'bar')) {
      detailsData = detailsFiltered.map(el => {
        const sumSeries = {};
        (el.data || []).forEach((d, i) => {
          d.series.forEach(s => {
            if (!sumSeries[s.name]) {
              sumSeries[s.name] = 0;
            }
            sumSeries[s.name] += s.value;
          })
        });
        const sum = Object.keys(sumSeries).map(key => sumSeries[key]).reduce((pv, cv) => pv + cv, 0);
        const series = Object.keys(sumSeries).map(key => {
          return {
            name: key,
            value: widget.type === 'donut' ? Math.round(sumSeries[key] / sum * 100) : sumSeries[key],
          }
        });
        return {
          name: el.modality.modality_description,
          series: series
        }
      });
    }
  } else {
    const modality = modalities.data.find(m => m.modality_code === detailsType);

    if (detailsType === 'LD') {
      equipmentFiltered = equipment.data.filter(e => e.serial_number.startsWith('LD'));
    } else {
      equipmentFiltered = equipment.data.filter(e => e.modality.toString() === modality.id.toString());
    }

    const detailsEl = details.data.find(d => (d.widget.id.toString() === graphId) && d.modality.id.toString() === modality.id.toString());   
    if (detailsEl) {
      detailsData = detailsEl.data;
    }
  }

  const equipmentKeys = equipmentFiltered.map(el => el.equipment_key.toString());
  const filteredTickets = tickets.data.filter(ticket => equipmentKeys.includes(ticket.equipment_key));
  const groupedTickets = _.groupBy(filteredTickets, 'equipment_key');
  const equipmentWithTicketNumbers = Object.keys(groupedTickets).map(key => {
    return {
      productName: equipmentFiltered.find(eq => eq.equipment_key.toString() === key.toString()).product_name,
      siemensEquipmentId: groupedTickets[key][0]['siemens_equipment_id'],
      equipmentKey: key,
      ticketNumbers: groupedTickets[key].map(e => e.ticket_number).join(', '),
    }
  });

  const equipmentWithTicketNumbersIds = equipmentWithTicketNumbers.map(el => el.equipmentKey.toString());
  const inactiveEquipments = equipmentFiltered.filter(eq => !equipmentWithTicketNumbersIds.includes(eq.equipment_key)).map(eq => {
    return {
      productName: eq.product_name,
      siemensEquipmentId: eq.siemens_id,
      equipmentKey: eq.equipment_key,
      installedOn: eq.installed_on,
    }
  });

  if (detailsData) {
    widget.calculated_data = detailsData;
  }

  const result: SprWidgetDetails = {
    ...widget,
    calculatedData: convertCalculatedData(widget),
    state: null,
    modificationDate: new Date().toISOString().split('T')[0],
    allowedCustomers: [],
    graphConfigurations: [],
    inactiveEquipments: inactiveEquipments,
    equipmentWithTicketNumbers: equipmentWithTicketNumbers,
    filter: generateFilter({ equipment: equipment.data, modalities: modalities.data, details: detailsFiltered, widget: widget, detailsType: detailsType, customers: customers.data }),
  };

  return result;
}
