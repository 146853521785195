import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { includes } from 'lodash-es';
import { LogService } from '../services/log/log.service';
import { ToasterService } from '../component-communication-services/toaster/toaster.service';
import { HttpIgnoredErrors } from './http-ignored-errors';
import { HttpCacheService } from '../services/cache/http-cache.service';
import { WindowService } from '../window.service';
import { DeviceDetectionService } from '../services/device-detection.service';
import { Location } from '@angular/common';

const orderToOrderRequest = 'siemens.com/spe/data';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  window: any;

  constructor(private router: Router,
    private location: Location,
    private log: LogService,
    private toast: ToasterService,
    private httpCacheService: HttpCacheService,
    private httpIgnoredErrors: HttpIgnoredErrors,
    private deviceDetectionService: DeviceDetectionService,
    windowService: WindowService
  ) {
    this.window = windowService.nativeWindow;
  }

  private catchErrors() {
    return (response: HttpErrorResponse) => {
      this.handleResponse(response);
      return throwError(() => response);
    };
  }

  handleResponse(response: HttpErrorResponse) {
    if (this.httpIgnoredErrors.shallBeIgnored(response)) {
      return;
    }
    this.handleResponseError(response);
  }

  private handleResponseError(response: HttpErrorResponse) {
    switch (response.status) {
      case 401:
        this.handleResponse401(response);
        break;

      case 403:
        this.handleResponse403(response);
        break;

      case 404:
        this.handleResponse404(response);
        break;

      // default case is anything else with status 400 or 500
      default:
        // show default error message
        let errorMessageKey = null;

        // special cases for performance report and excel report
        if (includes(response.url, 'AsPdf')) {
          errorMessageKey = 'FRONTEND_SERVICE_PERFORMANCE_REPORT_ERROR_MESSAGE';
        } else if (includes(response.url, 'generateExcel')) {
          errorMessageKey = 'FRONTEND_EXCEL_REPORT_ERROR_MESSAGE';
        }

        const handleUrls = [
          /activities\/.*\/reschedule/,
          /activities\/.*\/pmSchedule/,
          /customers/,
          /equipments\/.*\/deactivate/,
          /equipments\/.*\/components/,
          /equipments\/.*\/recommendation/,
          /equipments\/.*\/quote/,
          /equipments\/.*\/trial/,
          /favorites/,
          /iccode/,
          /messages\/markEventAsSeen/,
          /partnerOrders\/.*\/close/,
          /partnerOrders\/.*\/withdraw/,
          /psr/,
          /reports\/education/,
          /reports\/uptime/,
          /reports\/servicePerformance/,
          /reports\/remoteActivity/,
          /saOrder/,
          /system-updates\/exportExcel/,
          /tickets\/create/,
          /tickets\/update/,
          /tickets\/close/,
          /users\/self\/accept/,
          /users\/locale/
        ];

        for (const handleUrl of handleUrls) {
          if (response && response.url && response.url.match(handleUrl)) {
            errorMessageKey = 'FRONTEND_GENERIC_ERROR_MESSAGE';
          }
        }
        if (errorMessageKey !== null) {
          this.log.warn('error status: ' + response.statusText);
          this.toast.showTranslatedErrorToaster(errorMessageKey);
        }
        break;
    }
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(this.catchErrors()));
  }

  private handleResponse401(response: HttpErrorResponse) {
    // The support and faq page will not work in the mobile app.
    if (this.deviceDetectionService.isMobileApp() && response.error && response.error.redirectToLogin) {
      this.window.location.href = response.error.loginUrl;
      return;
    }
    const allowed = [/^\/welcome$/, /^\/support/, /^\/faq$/];
    if (allowed.every(regex => !this.location.path().match(regex))) {
      if (response.error && response.error.redirectToLogin) {
        this.window.location.href = response.error.loginUrl;
      } else {
        this.router.navigate(['/welcome']);
      }
    }
  }

  private handleResponse403(response: HttpErrorResponse) {
    // here the lifeNetUtilService cannot be used as this yields a circular dependency
    // check for self in url to find errors from /user/self or /user/self/roles
    // then logout session and show error message
    if (includes(response.url, 'self')) {
      // here the lifeNetUtilService cannot be used as this yields a circular dependency
      this.httpCacheService.clearAll();
      this.router.navigate(['/403']);
    }
  }

  private handleResponse404(response: HttpErrorResponse) {
    // handle order to order not found
    if (includes(response.url, orderToOrderRequest)) {
      this.log.warn('Order to order file not found');
    } else {
      this.toast.emitFrontendGenericErrorMessage();
    }
  }

}
