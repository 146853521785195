import { IMessage, StompHeaders } from '@stomp/stompjs';
import { Observable } from 'rxjs';
import { RxStompWrapper } from './rx-stomp-wrapper';
import { RxStomp } from '@stomp/rx-stomp';

export class RxStompWrapperImpl extends RxStompWrapper {

  constructor(private rxStomp: RxStomp) {
    super();
  }

  activate(): void {
    this.rxStomp.activate();
  }

  watch(destination: string, headers?: StompHeaders): Observable<IMessage> {
    return this.rxStomp.watch(destination, headers);
  }

  active(): boolean {
    return this.rxStomp.active;
  }
}
