import { Ticket } from 'app/core/models/tickets/ticket';
import { TicketSalutation } from '../app/core/models/tickets/ticket-salutation';
import { TicketTitle } from '../app/core/models/tickets/ticket-title';
import { ModalityTypeTicketTypeRel } from '../app/core/models/tickets/modality-type-ticket-type-rel';
import { TicketProblemSeverity } from '../app/core/models/tickets/ticket-problem-severity';
import { setDateTime, setMomentUTC } from './date-time.mock';
import { EventLog } from '../app/core/models/event-log';

// const dateNow = new Date();

// function setDateTime($addition, $hour = 0, $minutes = 0) {
//   return new Date(
//     dateNow.getFullYear(),
//     dateNow.getMonth(),
//     dateNow.getDate() + $addition,
//     $hour,
//     $minutes
//   ).toISOString();
// }

// function setMomentUTC(date: string) {
//   return moment(date)
//     .tz('UTC')
//     .format('DD.MM.YYYY HH:mm:ss zz');
// }

interface TicketNotifStatus {
  id: string;
  value: string[];
}

export const CdNotifyStatus: TicketNotifStatus[] = [
  {
    id: '1', // isOpen
    value: ['1', '2', '8', '9', '11', '13', '14', '18', '19', '20', '27']
  },
  {
    id: '2', // isClosed
    value: ['3', '4', '5', '6', '7', '10', '12', '15', '16', '17', '21', '22', '23', '24', '25', '26', '28', '29', '30']
  }
];
// new items after completeDate
export const CdTicket: Ticket = {
  customerId: '',
  ticketKey: '',
  ticketNumber: '12345678',
  equipmentKey: '',
  siemensEquipmentId: '',
  creatorForeName: 'John',
  creatorLastName: 'Doe',
  contactForeName: 'John',
  contactLastName: 'Doe',
  ticketCreationTimestamp: '',
  typeID: '',
  titleDescription: '',
  taskID: '',
  typeDescription: 'Technical Support',
  taskDescription: '',
  problemSeverityDescription: '',
  salutationDescription: '',
  dangerForPatient: false,
  priorityID: '',
  problemSeverityID: '',
  problemSeverity: '',
  myEquipmentName: '',
  plannedStartDatetime: '2018-12-01T12:00:00',
  plannedEndDatetime: '2018-12-01T18:00:00',
  solutionExists: false,
  customerNumber: '',
  attachments: null,
  device: '',
  longText: '',
  solutionText: '',
  customerIncidentId: '12345678',
  eventLogs: [],
  description: 'US ticket Description',
  ticketStatus: '',
  ticketStatusDescription: '',
  completedDate: '',
  locationName: 'string',
  city: 'string',
  street: 'string',
  state: 'string',
  zipCode: 'string'
};

// new first item
export const CdEventLog: EventLog = {
  taskID: '',
  creationDateTime: '2016-04-11T10:20:00',
  endDateTime: '',
  taskDescription: '',
  serviceEngineer: '',
  longText: '',
  toggleStatus: false
};

export const CdTicketList: Ticket[] = [
  {
    ...CdTicket,
    siemensEquipmentId: '64073',
    ticketKey: '1',
    ticketNumber: '4003934506',
    equipmentKey: '1',
    description: 'Activation Utilization Management',
    ticketCreationTimestamp: setDateTime(-15, 10, 0),
    plannedStartDatetime: setDateTime(-15, 10, 0),
    plannedEndDatetime: setDateTime(-15, 16, 0),
    longText: `${setMomentUTC(setDateTime(-15, 11, 0))}
      Contract is signed and valid, please activate UM on the system`,
    problemSeverity: '3',
    problemSeverityID: '3',
    problemSeverityDescription: 'Operational',
    taskDescription: 'Feedback from CSE',
    ticketStatus: '1',
    myEquipmentName: 'Somatom Definition AS+',
    eventLogs: [
      {
        ...CdEventLog,
        endDateTime: setDateTime(-15, 10, 20),
        taskDescription: 'Action planning'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-15, 10, 25),
        taskDescription: 'On-site action scheduled'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-15, 13, 17),
        taskDescription: 'CSE on his way to the customer'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-15, 13, 54),
        taskDescription: 'Technician on site'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-15, 15, 20),
        taskDescription: 'Feedback from CSE'
      }
    ]
  },
  {
    ...CdTicket,
    siemensEquipmentId: '74014',
    ticketKey: '2',
    ticketNumber: '4003940306',
    equipmentKey: '3',
    description: 'Ignition interference -> CAN24/7 error',
    ticketCreationTimestamp: setDateTime(-1, 10, 0),
    plannedStartDatetime: setDateTime(-1, 10, 0),
    plannedEndDatetime: setDateTime(-1, 16, 0),
    longText: `${setMomentUTC(setDateTime(-1, 11, 0))}
      Image artifacts
    `,
    taskDescription: 'Feedback from CSE',
    problemSeverity: '1',
    problemSeverityID: '1',
    problemSeverityDescription: 'Down',
    ticketStatus: '1',
    myEquipmentName: 'MR 2',
    eventLogs: [
      {
        ...CdEventLog,
        endDateTime: setDateTime(-1, 10, 32),
        taskDescription: 'Action planning'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-1, 13, 10),
        taskDescription: 'Technician on site'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-1, 15, 39),
        taskDescription: 'Feedback from CSE'
      }
    ]
  },
  {
    ...CdTicket,
    siemensEquipmentId: '74014',
    ticketKey: '3',
    ticketNumber: '4003955183',
    equipmentKey: '3',
    description: 'Exchange of defective HDD',
    ticketCreationTimestamp: setDateTime(-230, 13, 21),
    plannedStartDatetime: setDateTime(-230, 13, 21),
    plannedEndDatetime: setDateTime(-230, 15, 0),
    longText: `${setMomentUTC(setDateTime(-230, 14, 21))}
      Indicator LED shows defective HDD Bay0-1
      `,
    problemSeverity: '2',
    problemSeverityID: '2',
    problemSeverityDescription: 'Needs attention',
    ticketStatus: '2',
    taskDescription: 'Feedback from CSE',
    myEquipmentName: 'MR 2',
    eventLogs: [
      {
        ...CdEventLog,
        endDateTime: setDateTime(-230, 13, 21),
        taskDescription: 'Action planning'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-230, 13, 45),
        taskDescription: 'Remote action'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-230, 14, 30),
        taskDescription: 'Feedback from CSE'
      }
    ]
  },
  {
    ...CdTicket,
    siemensEquipmentId: '74014',
    ticketKey: '4',
    ticketNumber: '4003987364',
    equipmentKey: '3',
    description: 'Patient 0357456 is not loaded in MMReading Patient Browser',
    ticketCreationTimestamp: setDateTime(-112, 14, 26),
    plannedStartDatetime: setDateTime(-112, 14, 26),
    plannedEndDatetime: setDateTime(-112, 18, 0),
    longText: `${setMomentUTC(setDateTime(-112, 15, 26))}
      On client MD035 the patient 0357456 cannot be loaded in the patient browser when using MMReading`,
    problemSeverity: '2',
    problemSeverityID: '2',
    problemSeverityDescription: 'Needs attention',
    ticketStatus: '2',
    taskDescription: 'Feedback from CSE',
    myEquipmentName: 'MR 2',
    eventLogs: [
      {
        ...CdEventLog,
        endDateTime: setDateTime(-112, 14, 36),
        taskDescription: 'Action planning'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-112, 16, 26),
        taskDescription: 'Technician on site'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-112, 17, 58),
        taskDescription: 'Feedback from CSE'
      }
    ]
  },
  {
    ...CdTicket,
    siemensEquipmentId: '40305',
    ticketKey: '5',
    ticketNumber: '4003928330',
    equipmentKey: '6',
    description: 'Biopsie Error8 no movement',
    ticketCreationTimestamp: setDateTime(-301, 12, 47),
    plannedStartDatetime: setDateTime(-301, 12, 47),
    plannedEndDatetime: setDateTime(-301, 18, 0),
    taskDescription: 'Feedback from CSE',
    problemSeverity: '2',
    problemSeverityID: '2',
    problemSeverityDescription: 'Needs attention',
    ticketStatus: '2',
    myEquipmentName: 'syngo 2',
    eventLogs: [
      {
        ...CdEventLog,
        endDateTime: setDateTime(-201, 13, 26),
        taskDescription: 'Action planning'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-301, 13, 34),
        taskDescription: 'On-site action scheduled'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-301, 14, 55),
        taskDescription: 'CSE on his way to the customer'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-301, 14, 55),
        taskDescription: 'Technician on site'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-301, 17, 30),
        taskDescription: 'Feedback from CSE'
      }
    ]
  },
  {
    ...CdTicket,
    siemensEquipmentId: '40305',
    ticketKey: '6',
    ticketNumber: '4003915546',
    equipmentKey: '6',
    description: 'Does not send patient data to PACS',
    ticketCreationTimestamp: setDateTime(-161, 16, 37),
    plannedStartDatetime: setDateTime(-161, 16, 37),
    plannedEndDatetime: setDateTime(-160, 18, 0),
    taskDescription: 'Feedback from CSE',
    problemSeverity: '2',
    problemSeverityID: '2',
    problemSeverityDescription: 'Needs attention',
    ticketStatus: '2',
    myEquipmentName: 'syngo 2',
    eventLogs: [
      {
        ...CdEventLog,
        endDateTime: setDateTime(-161, 16, 47),
        taskDescription: 'Action planning'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-161, 16, 55),
        taskDescription: 'On-site action scheduled'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-160, 7, 55),
        taskDescription: 'CSE on his way to the customer'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-160, 8, 17),
        taskDescription: 'Technician on site'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-130, 10, 55),
        taskDescription: 'Feedback from CSE'
      }
    ]
  },
  {
    ...CdTicket,
    siemensEquipmentId: '24514',
    ticketKey: '7',
    ticketNumber: '4003968758',
    equipmentKey: '12',
    description: 'No connection with camera',
    ticketCreationTimestamp: setDateTime(-256, 17, 25),
    plannedStartDatetime: setDateTime(-256, 17, 25),
    plannedEndDatetime: setDateTime(-255, 19, 0),
    taskDescription: 'Feedback from CSE',
    problemSeverity: '2',
    problemSeverityID: '2',
    problemSeverityDescription: 'Needs attention',
    ticketStatus: '2',
    myEquipmentName: 'Ysio 1',
    eventLogs: [
      {
        ...CdEventLog,
        endDateTime: setDateTime(-256, 17, 25),
        taskDescription: 'Support is processing request'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-255, 17, 43),
        taskDescription: 'Remote action'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-255, 18, 10),
        taskDescription: 'Feedback from CSE '
      }
    ]
  },
  {
    ...CdTicket,
    siemensEquipmentId: '1050',
    ticketKey: '8',
    ticketNumber: '4003928661',
    equipmentKey: '9',
    description: 'Incorrect time display Biograph mCT -S(40) 4R',
    ticketCreationTimestamp: setDateTime(-14, 11, 0),
    plannedStartDatetime: setDateTime(-14, 11, 0),
    plannedEndDatetime: setDateTime(-13, 12, 0),
    taskDescription: 'Feedback from CSE',
    problemSeverity: '3',
    problemSeverityID: '3',
    problemSeverityDescription: 'Operational',
    ticketStatus: '1',
    myEquipmentName: 'MI 1',
    eventLogs: [
      {
        ...CdEventLog,
        endDateTime: setDateTime(-14, 11, 0),
        taskDescription: 'Support is processing request'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-14, 11, 22),
        taskDescription: 'On-site action scheduled'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-13, 7, 58),
        taskDescription: 'CSE on his way to the customer'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-13, 8, 33),
        taskDescription: 'Technician on site'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-13, 11, 23),
        taskDescription: 'Feedback from CSE '
      }
    ]
  },
  {
    ...CdTicket,
    siemensEquipmentId: '11044',
    ticketKey: '9',
    ticketNumber: '4003995093',
    equipmentKey: '8',
    description: 'Reset display time',
    ticketCreationTimestamp: setDateTime(-15, 17, 35),
    plannedStartDatetime: setDateTime(-15, 17, 35),
    plannedEndDatetime: setDateTime(-15, 19, 0),
    taskDescription: 'Feedback from CSE',
    problemSeverity: '3',
    problemSeverityID: '3',
    problemSeverityDescription: 'Operational',
    ticketStatus: '1',
    myEquipmentName: 'MI 2',
    eventLogs: [
      {
        ...CdEventLog,
        endDateTime: setDateTime(-15, 17, 50),
        taskDescription: 'Action planning'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-15, 18, 12),
        taskDescription: 'Remote action'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-15, 18, 55),
        taskDescription: 'Feedback from CSE'
      }
    ]
  },
  {
    ...CdTicket,
    siemensEquipmentId: '82052',
    ticketKey: '10',
    ticketNumber: '4003917275',
    equipmentKey: '10',
    description: 'Set up sensibility levels',
    ticketCreationTimestamp: setDateTime(-43, 15, 0),
    plannedStartDatetime: setDateTime(-43, 15, 0),
    plannedEndDatetime: setDateTime(-43, 17, 0),
    taskDescription: 'Feedback from CSE',
    problemSeverity: '2',
    problemSeverityID: '2',
    problemSeverityDescription: 'Needs attention',
    ticketStatus: '2',
    myEquipmentName: 'Artis 1',
    eventLogs: [
      {
        ...CdEventLog,
        endDateTime: setDateTime(-43, 15, 17),
        taskDescription: 'Action planning'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-43, 15, 24),
        taskDescription: 'Remote action'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-43, 15, 51),
        taskDescription: 'Feedback from CSE '
      }
    ]
  },
  {
    ...CdTicket,
    siemensEquipmentId: '153284',
    ticketKey: '11',
    ticketNumber: '4003943073',
    equipmentKey: '11',
    description: 'PROA No update installation since 10 day',
    ticketCreationTimestamp: setDateTime(-12, 8, 15),
    plannedStartDatetime: setDateTime(-12, 8, 15),
    plannedEndDatetime: setDateTime(-12, 17, 0),
    taskDescription: 'Action planning',
    problemSeverity: '3',
    problemSeverityID: '3',
    problemSeverityDescription: 'Operational',
    ticketStatus: '1',
    myEquipmentName: 'Artis 2',
    eventLogs: [
      {
        ...CdEventLog,
        endDateTime: setDateTime(-12, 8, 15),
        taskDescription: 'Proactive error analysis'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-12, 9, 27),
        taskDescription: 'Internal technical clearance '
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-12, 10, 13),
        taskDescription: 'Support processing'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-12, 10, 55),
        taskDescription: 'Internal technical clearance  '
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-12, 26, 15),
        taskDescription: 'Action planning'
      }
    ]
  },
  {
    ...CdTicket,
    siemensEquipmentId: '135787',
    ticketKey: '12',
    ticketNumber: '4003967964',
    equipmentKey: '12',
    description: 'Activate Utilization Management',
    ticketCreationTimestamp: setDateTime(-95, 7, 58),
    plannedStartDatetime: setDateTime(-95, 7, 58),
    plannedEndDatetime: setDateTime(-94, 12, 0),
    longText: `${setMomentUTC(setDateTime(-95, 8, 58))}
      contract is signed and valid, please activate UM on the system`,
    taskDescription: 'Feedback from CSE',
    problemSeverity: '3',
    problemSeverityID: '3',
    problemSeverityDescription: 'Operational',
    ticketStatus: '2',
    myEquipmentName: 'Artis 3',
    eventLogs: [
      {
        ...CdEventLog,
        endDateTime: setDateTime(-95, 9, 58),
        taskDescription: 'Action planning'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-95, 10, 3),
        taskDescription: 'Remote action'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-94, 10, 56),
        taskDescription: 'Feedback from CSE'
      }
    ]
  },
  {
    ...CdTicket,
    siemensEquipmentId: '135787',
    ticketKey: '13',
    ticketNumber: '4003934798',
    equipmentKey: '12',
    description: 'Carbon headshell needed',
    ticketCreationTimestamp: setDateTime(-10, 15, 45),
    plannedStartDatetime: setDateTime(-10, 15, 45),
    plannedEndDatetime: setDateTime(-10, 18, 0),
    taskDescription: 'Support is processing request',
    problemSeverity: '3',
    problemSeverityID: '3',
    problemSeverityDescription: 'Operational',
    ticketStatus: '1',
    myEquipmentName: 'Artis 3',
    eventLogs: [
      {
        ...CdEventLog,
        endDateTime: setDateTime(-10, 16, 52),
        taskDescription: 'Support is processing request'
      }
    ]
  },
  {
    ...CdTicket,
    siemensEquipmentId: '400987',
    ticketKey: '14',
    ticketNumber: '4003933382',
    equipmentKey: '13',
    description: 'Maintenance appointment needed',
    ticketCreationTimestamp: setDateTime(-6, 14, 55),
    plannedStartDatetime: setDateTime(-6, 14, 55),
    plannedEndDatetime: setDateTime(-6, 18, 0),
    longText: `${setMomentUTC(setDateTime(-6, 15, 55))}
      Please suggest three time frames for maintenance in between May 9th and 13th`,
    taskDescription: 'Feedback from CSE',
    problemSeverity: '3',
    problemSeverityID: '3',
    problemSeverityDescription: 'Operational',
    ticketStatus: '2',
    myEquipmentName: 'CT 1',
    eventLogs: [
      {
        ...CdEventLog,
        endDateTime: setDateTime(-6, 16, 53),
        taskDescription: 'Support is processing request'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-6, 17, 12),
        taskDescription: 'Feedback from CSE'
      }
    ]
  },
  {
    ...CdTicket,
    siemensEquipmentId: '400987',
    ticketKey: '15',
    ticketNumber: '4003845658',
    equipmentKey: '13',
    description: 'Request of Cardiac Scan Protocol',
    ticketCreationTimestamp: setDateTime(-61, 16, 45),
    plannedStartDatetime: setDateTime(-61, 16, 45),
    plannedEndDatetime: setDateTime(-60, 12, 0),
    taskDescription: 'Feedback from CSE',
    problemSeverity: '3',
    problemSeverityID: '3',
    problemSeverityDescription: 'Operational',
    ticketStatus: '2',
    myEquipmentName: 'CT 1',
    eventLogs: [
      {
        ...CdEventLog,
        endDateTime: setDateTime(-61, 16, 48),
        taskDescription: 'Support is processing request'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-61, 16, 52),
        taskDescription: 'Action planning'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-60, 8, 35),
        taskDescription: 'CSE on his way to the customer'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-60, 8, 55),
        taskDescription: 'Technician on site'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-60, 11, 35),
        taskDescription: 'Feedback from CSE'
      }
    ]
  },
  {
    ...CdTicket,
    siemensEquipmentId: '77710',
    ticketKey: '16',
    ticketNumber: '4003986669',
    equipmentKey: '14',
    description: 'Artefacts in specific images',
    ticketCreationTimestamp: setDateTime(-123, 16, 35),
    plannedStartDatetime: setDateTime(-123, 16, 35),
    plannedEndDatetime: setDateTime(-123, 19, 0),
    taskDescription: 'Feedback from CSE',
    problemSeverity: '2',
    problemSeverityID: '2',
    problemSeverityDescription: 'Needs attention',
    ticketStatus: '2',
    myEquipmentName: 'CT 2',
    eventLogs: [
      {
        ...CdEventLog,
        endDateTime: setDateTime(-123, 16, 37),
        taskDescription: 'Support is processing request'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-123, 16, 42),
        taskDescription: 'Action planning'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-123, 17, 15),
        taskDescription: 'Support is processing request'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-123, 17, 54),
        taskDescription: 'Feedback from CSE '
      }
    ]
  },
  {
    ...CdTicket,
    siemensEquipmentId: '400987',
    ticketKey: '17',
    ticketNumber: '4003956026',
    equipmentKey: '14',
    description: 'Fan noise too loud while scanning',
    ticketCreationTimestamp: setDateTime(-48, 14, 14),
    plannedStartDatetime: setDateTime(-48, 14, 14),
    plannedEndDatetime: setDateTime(-48, 19, 0),
    taskDescription: 'Feedback from CSE',
    problemSeverity: '3',
    problemSeverityID: '3',
    problemSeverityDescription: 'Operational',
    ticketStatus: '2',
    myEquipmentName: 'US 1',
    eventLogs: [
      {
        ...CdEventLog,
        endDateTime: setDateTime(-48, 16, 3),
        taskDescription: 'Action planning'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-48, 17, 49),
        taskDescription: 'On-site action planning'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-48, 18, 4),
        taskDescription: 'CSE on his way to the customer'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-48, 18, 5),
        taskDescription: 'Technician on site'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-48, 18, 9),
        taskDescription: 'Action planning'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-48, 18, 9),
        taskDescription: 'Feedback from CSE  '
      }
    ]
  },
  {
    ...CdTicket,
    siemensEquipmentId: '153284',
    ticketKey: '18',
    ticketNumber: '4003946587',
    equipmentKey: '17',
    description: 'QC out of Range on multiple assays',
    ticketCreationTimestamp: setDateTime(-3, 16, 35),
    plannedStartDatetime: setDateTime(-3, 16, 35),
    plannedEndDatetime: setDateTime(-3, 18, 0),
    longText: `${setMomentUTC(setDateTime(-3, 17, 35))}
      Probe test failed`,
    taskDescription: 'Feedback from CSE',
    problemSeverity: '2',
    problemSeverityID: '2',
    problemSeverityDescription: 'Needs attention',
    ticketStatus: '1',
    myEquipmentName: 'Artis 3',
    eventLogs: [
      {
        ...CdEventLog,
        endDateTime: setDateTime(-3, 16, 48),
        taskDescription: 'Feedback from CSE'
      }
    ]
  },
  {
    ...CdTicket,
    siemensEquipmentId: '400987',
    ticketKey: '19',
    ticketNumber: '4008085642',
    equipmentKey: '17',
    description: 'Failed daily cleaning multiple times',
    ticketCreationTimestamp: setDateTime(-2, 14, 14),
    plannedStartDatetime: setDateTime(-2, 14, 14),
    plannedEndDatetime: setDateTime(-2, 19, 0),
    longText: `${setMomentUTC(setDateTime(-2, 15, 14))}
      Unable to clear cuvette jam`,
    taskDescription: 'Feedback from CSE',
    problemSeverity: '2',
    problemSeverityID: '2',
    problemSeverityDescription: 'Needs attention',
    ticketStatus: '1',
    myEquipmentName: 'US 1',
    eventLogs: [
      {
        ...CdEventLog,
        endDateTime: setDateTime(-2, 14, 20),
        taskDescription: 'Action planning'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-2, 14, 28),
        taskDescription: 'On-site action scheduled'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-2, 15, 53),
        taskDescription: 'CSE on his way to the customer'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-2, 16, 28),
        taskDescription: 'Technician on site'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-2, 17, 30),
        taskDescription: 'Feedback from CSE'
      }
    ]
  },
  {
    ...CdTicket,
    siemensEquipmentId: '310856',
    ticketKey: '20',
    ticketNumber: '4008110032',
    equipmentKey: '18',
    description: 'Failed daily cleaning multiple times',
    ticketCreationTimestamp: setDateTime(-1, 14, 14),
    plannedStartDatetime: setDateTime(-1, 14, 14),
    plannedEndDatetime: setDateTime(0, 18, 0),
    longText: `${setMomentUTC(setDateTime(-1, 15, 14))}
      Unable to clear cuvette jam`,
    taskDescription: 'Feedback from CSE',
    problemSeverity: '2',
    problemSeverityID: '2',
    problemSeverityDescription: 'Needs attention',
    ticketStatus: '1',
    myEquipmentName: 'Vista',
    eventLogs: [
      {
        ...CdEventLog,
        endDateTime: setDateTime(-1, 16, 47),
        taskDescription: 'Action planning'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-1, 16, 55),
        taskDescription: 'On-site action scheduled'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(0, 7, 55),
        taskDescription: 'CSE on his way to the customer'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(0, 8, 17),
        taskDescription: 'Technician on site'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(0, 8, 20),
        taskDescription: 'Feedback from CSE'
      }
    ]
  },
  {
    ...CdTicket,
    siemensEquipmentId: '96264',
    ticketKey: '21',
    ticketNumber: '4008009432',
    equipmentKey: '27',
    description: 'Error Waiting for ACK ResetQueue Request',
    ticketCreationTimestamp: setDateTime(-211, 9, 4),
    plannedStartDatetime: setDateTime(-211, 9, 4),
    plannedEndDatetime: setDateTime(-209, 18, 0),
    longText: `${setMomentUTC(setDateTime(-211, 10, 4))}
      Remote resolution not successful, CSE dispatched to check onsite`,
    taskDescription: 'Feedback from CSE',
    problemSeverity: '1',
    problemSeverityID: '1',
    problemSeverityDescription: 'Down',
    ticketStatus: '2',
    myEquipmentName: 'RAD-MaxSys03',
    eventLogs: [
      {
        ...CdEventLog,
        endDateTime: setDateTime(-211, 17, 25),
        taskDescription: 'Support is processing request'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-211, 17, 43),
        taskDescription: 'Remote action'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-211, 18, 10),
        taskDescription: 'Feedback from CSE '
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-210, 11, 0),
        taskDescription: 'Support is processing request'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-210, 11, 22),
        taskDescription: 'On-site action scheduled'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-209, 7, 58),
        taskDescription: 'CSE on his way to the customer'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-209, 8, 33),
        taskDescription: 'Technician on site'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-209, 11, 23),
        taskDescription: 'Feedback from CSE '
      }
    ]
  },
  {
    ...CdTicket,
    siemensEquipmentId: '83070',
    ticketKey: '22',
    ticketNumber: '4002109439',
    equipmentKey: '22',
    description: 'Following the change of the perox lamp, has no cloud in the white blood cells.',
    ticketCreationTimestamp: setDateTime(-115, 12, 56),
    plannedStartDatetime: setDateTime(-115, 12, 56),
    plannedEndDatetime: setDateTime(-115, 19, 0),
    longText: `${setMomentUTC(setDateTime(-115, 13, 56))}
      Resolved by resuming the Perox lamp change procedure.
      Functional apparatus.`,
    problemSeverity: '2',
    problemSeverityID: '2',
    problemSeverityDescription: 'Needs attention',
    ticketStatus: '2',
    taskDescription: 'Feedback from CSE',
    myEquipmentName: 'AD 01',
    eventLogs: [
      {
        ...CdEventLog,
        endDateTime: setDateTime(-115, 17, 50),
        taskDescription: 'Action planning'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-115, 18, 12),
        taskDescription: 'Remote action'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-115, 18, 55),
        taskDescription: 'Feedback from CSE'
      }
    ]
  },
  {
    ...CdTicket,
    siemensEquipmentId: '83070',
    ticketKey: '23',
    ticketNumber: '4003106439',
    equipmentKey: '22',
    description: 'not connected, failure reset',
    ticketCreationTimestamp: setDateTime(-89, 18, 34),
    plannedStartDatetime: setDateTime(-89, 18, 34),
    plannedEndDatetime: setDateTime(-77, 18, 0),
    longText: `${setMomentUTC(setDateTime(-89, 19, 34))}
      Repair of the PC / ADVIA communication cable
      Control of the new communication cable (to be replaced by the customer)`,
    taskDescription: 'Feedback from CSE',
    problemSeverity: '3',
    problemSeverityID: '3',
    problemSeverityDescription: 'Operational',
    ticketStatus: '2',
    myEquipmentName: 'AD 01',
    eventLogs: [
      {
        ...CdEventLog,
        endDateTime: setDateTime(-88, 15, 17),
        taskDescription: 'Action planning'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-88, 15, 24),
        taskDescription: 'Remote action'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-88, 15, 51),
        taskDescription: 'Feedback from CSE '
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-77, 8, 15),
        taskDescription: 'Proactive error analysis'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-77, 9, 27),
        taskDescription: 'Internal technical clearance '
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-77, 10, 13),
        taskDescription: 'Support processing'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-77, 10, 55),
        taskDescription: 'Internal technical clearance  '
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-77, 16, 15),
        taskDescription: 'Action planning'
      }
    ]
  },
  {
    ...CdTicket,
    siemensEquipmentId: '1037',
    ticketKey: '24',
    ticketNumber: '4007104039',
    equipmentKey: '30',
    description: 'Quick controls are too low. New values have been entered for INR. No calibration now anymore',
    ticketCreationTimestamp: setDateTime(-147, 6, 47),
    plannedStartDatetime: setDateTime(-147, 6, 47),
    plannedEndDatetime: setDateTime(-147, 12, 0),
    longText: `${setMomentUTC(setDateTime(-147, 7, 47))}
      After recalibration, both controls C1 / C2 are both in the range of% and INR`,
    taskDescription: 'Feedback from CSE',
    problemSeverity: '2',
    problemSeverityID: '2',
    problemSeverityDescription: 'Needs attention',
    ticketStatus: '2',
    myEquipmentName: 'Atellica-1600',
    eventLogs: [
      {
        ...CdEventLog,
        endDateTime: setDateTime(-147, 9, 58),
        taskDescription: 'Action planning'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-147, 10, 3),
        taskDescription: 'Remote action'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-147, 10, 56),
        taskDescription: 'Feedback from CSE'
      }
    ]
  },
  {
    ...CdTicket,
    siemensEquipmentId: '1037',
    ticketKey: '25',
    ticketNumber: '4008218631',
    equipmentKey: '30',
    description: 'After too low recovery of Innovin # 549707 with CiTrol 1 # 548045 in%. INR is good. Citrol 2 # 5482',
    ticketCreationTimestamp: setDateTime(-221, 15, 3),
    plannedStartDatetime: setDateTime(-221, 15, 3),
    plannedEndDatetime: setDateTime(-220, 12, 0),
    longText: `${setMomentUTC(setDateTime(-221, 16, 3))}
      recalibration`,
    taskDescription: 'Support is processing request',
    problemSeverity: '2',
    problemSeverityID: '2',
    problemSeverityDescription: 'Needs attention',
    ticketStatus: '2',
    myEquipmentName: 'Atellica-1600',
    eventLogs: [
      {
        ...CdEventLog,
        endDateTime: setDateTime(-220, 8, 50),
        taskDescription: 'Support is processing request'
      }
    ]
  },
  {
    ...CdTicket,
    siemensEquipmentId: '94811',
    ticketKey: '26',
    ticketNumber: '4004076346',
    equipmentKey: '24',
    description: 'Reagent barcode reader errors and split substrate diaphragm',
    ticketCreationTimestamp: setDateTime(-173, 10, 3),
    plannedStartDatetime: setDateTime(-173, 10, 3),
    plannedEndDatetime: setDateTime(-172, 12, 0),
    longText: `${setMomentUTC(setDateTime(-173, 11, 3))}
      Replaced split diaphragm and ,faulty reagent Barcode Scanner.`,
    taskDescription: 'Feedback from CSE',
    problemSeverity: '2',
    problemSeverityID: '2',
    problemSeverityDescription: 'Needs attention',
    ticketStatus: '2',
    myEquipmentName: 'LAB-Immu',
    eventLogs: [
      {
        ...CdEventLog,
        endDateTime: setDateTime(-173, 16, 48),
        taskDescription: 'Support is processing request'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-173, 16, 52),
        taskDescription: 'Action planning'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-172, 8, 35),
        taskDescription: 'CSE on his way to the customer'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-172, 8, 55),
        taskDescription: 'Technician on site'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-172, 11, 35),
        taskDescription: 'Feedback from CSE'
      }
    ]
  },
  {
    ...CdTicket,
    siemensEquipmentId: '94811',
    ticketKey: '27',
    ticketNumber: '4004154850',
    equipmentKey: '24',
    description: 'Failed Daily maintenance',
    ticketCreationTimestamp: setDateTime(-4, 12, 45),
    plannedStartDatetime: setDateTime(-4, 12, 45),
    plannedEndDatetime: setDateTime(-4, 18, 0),
    longText: `${setMomentUTC(setDateTime(-4, 13, 45))}
      Remote resolution not successful, CSE dispatched to check onsite`,
    taskDescription: 'Feedback from CSE',
    problemSeverity: '2',
    problemSeverityID: '2',
    problemSeverityDescription: 'Needs attention',
    ticketStatus: '1',
    myEquipmentName: 'LAB-Immu',
    eventLogs: [
      {
        ...CdEventLog,
        endDateTime: setDateTime(-4, 16, 37),
        taskDescription: 'Support is processing request'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-4, 16, 42),
        taskDescription: 'Action planning'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-4, 17, 15),
        taskDescription: 'Support is processing request'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-4, 17, 54),
        taskDescription: 'Feedback from CSE '
      }
    ]
  },
  {
    ...CdTicket,
    siemensEquipmentId: '1069',
    ticketKey: '28',
    ticketNumber: '4004634861',
    equipmentKey: '31',
    description: 'System is not mixing the reagents.',
    ticketCreationTimestamp: setDateTime(-43, 18, 0),
    plannedStartDatetime: setDateTime(-43, 18, 0),
    plannedEndDatetime: setDateTime(-42, 18, 0),
    longText: `${setMomentUTC(setDateTime(-43, 19, 0))}
      Rebooted IM2 Module`,
    taskDescription: 'Feedback from CSE',
    problemSeverity: '2',
    problemSeverityID: '2',
    problemSeverityDescription: 'Needs attention',
    ticketStatus: '2',
    myEquipmentName: 'Atellica-UAS',
    eventLogs: [
      {
        ...CdEventLog,
        endDateTime: setDateTime(-42, 16, 3),
        taskDescription: 'Action planning'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-42, 17, 49),
        taskDescription: 'On-site action planning'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-42, 18, 4),
        taskDescription: 'CSE on his way to the customer'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-42, 18, 5),
        taskDescription: 'Technician on site'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-42, 18, 9),
        taskDescription: 'Action planning'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-42, 18, 9),
        taskDescription: 'Feedback from CSE  '
      }
    ]
  },
  {
    ...CdTicket,
    siemensEquipmentId: '1069',
    ticketKey: '29',
    ticketNumber: '4006129062',
    equipmentKey: '31',
    description: 'Water leak',
    ticketCreationTimestamp: setDateTime(-311, 10, 26),
    plannedStartDatetime: setDateTime(-311, 10, 26),
    plannedEndDatetime: setDateTime(-311, 18, 0),
    longText: `${setMomentUTC(setDateTime(-311, 11, 26))}
      Defect check valve`,
    taskDescription: 'Feedback from CSE',
    problemSeverity: '2',
    problemSeverityID: '2',
    problemSeverityDescription: 'Needs attention',
    ticketStatus: '2',
    myEquipmentName: 'Atellica-UAS',
    eventLogs: [
      {
        ...CdEventLog,
        endDateTime: setDateTime(-311, 16, 48),
        taskDescription: 'Feedback from CSE'
      }
    ]
  },
  {
    ...CdTicket,
    siemensEquipmentId: '34866',
    ticketKey: '30',
    ticketNumber: '4003531172',
    equipmentKey: '32',
    description: 'Upload is inactive',
    ticketCreationTimestamp: setDateTime(-180, 11, 14),
    plannedStartDatetime: setDateTime(-180, 11, 14),
    plannedEndDatetime: setDateTime(-180, 18, 0),
    longText: `${setMomentUTC(setDateTime(-180, 12, 14))}
      Remote solution successful`,
    taskDescription: 'Technician on site',
    problemSeverity: '2',
    problemSeverityID: '2',
    problemSeverityDescription: 'Needs attention',
    ticketStatus: '2',
    myEquipmentName: 'syngo-teamplay',
    eventLogs: [
      {
        ...CdEventLog,
        endDateTime: setDateTime(-180, 12, 20),
        taskDescription: 'Action planning'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-180, 14, 28),
        taskDescription: 'On-site action scheduled'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-180, 15, 53),
        taskDescription: 'CSE on his way to the customer'
      },
      {
        ...CdEventLog,
        endDateTime: setDateTime(-180, 16, 28),
        taskDescription: 'Technician on site'
      }
    ]
  }
];

export const CdTicketSalutation: TicketSalutation = {
  salutationId: '1',
  salutationDescription: 'Miss'
};

export const CdTicketSalutations: TicketSalutation[] = [
  { salutationId: '1', salutationDescription: 'Miss' },
  { salutationId: '2', salutationDescription: 'Mr.' },
  { salutationId: '3', salutationDescription: 'Mrs.' },
  { salutationId: '4', salutationDescription: 'Ms.' }
];

export const CdTicketTitle: TicketTitle = {
  titleId: '123',
  titleDescription: 'descript'
};

export const CdTicketTitles: TicketTitle[] = [
  { titleId: '1', titleDescription: 'Dr.' },
  { titleId: '2', titleDescription: 'Prof.' }
];

export const CdTicketTypedModalities: ModalityTypeTicketTypeRel[] = [
  {
    modalityType: 'INVITRO',
    ticketTypesSevRel: [
      {
        ticketType: {
          typeId: '1',
          typeDescription: 'Reactive Service'
        },
        problemSeverity: ['1', '2']
      },
      {
        ticketType: {
          typeId: '7',
          typeDescription: 'Inquiry'
        },
        problemSeverity: ['33']
      },
      {
        ticketType: {
          typeId: '6',
          typeDescription: 'Message'
        },
        problemSeverity: ['31', '32']
      },
      {
        ticketType: {
          typeId: '11',
          typeDescription: 'Reactive Service-Technical Support'
        },
        problemSeverity: ['1', '2']
      }
    ]
  },
  {
    modalityType: 'MULTIVENDOR',
    ticketTypesSevRel: [
      {
        ticketType: {
          typeId: '13',
          typeDescription: 'Reactive Service-Field Service'
        },
        problemSeverity: ['1', '2']
      }
    ]
  },
  {
    modalityType: 'SYNGO',
    ticketTypesSevRel: [
      {
        ticketType: {
          typeId: '11',
          typeDescription: 'Reactive Service-Technical Support'
        },
        problemSeverity: ['1', '2']
      }
    ]
  },
  {
    modalityType: 'DEFAULT',
    ticketTypesSevRel: [
      {
        ticketType: {
          typeId: '6',
          typeDescription: 'Message'
        },
        problemSeverity: ['31', '32']
      },
      {
        ticketType: {
          typeId: '11',
          typeDescription: 'Reactive Service-Technical Support'
        },
        problemSeverity: ['1', '2']
      },
      {
        ticketType: {
          typeId: '12',
          typeDescription: 'Reactive Service-Application Support'
        },
        problemSeverity: ['1', '2']
      }
    ]
  }
];

export const CdTicketProblemSevereties: TicketProblemSeverity[] = [
  {
    problemSeverityId: '1',
    problemSeverityDescription: 'System Not Operational',
    modality: null,
    systemBrokenFlag: false
  },
  {
    problemSeverityId: '2',
    problemSeverityDescription: 'System Partially Operational',
    modality: null,
    systemBrokenFlag: false
  },
  {
    problemSeverityId: '3',
    problemSeverityDescription: 'Scheduled Service',
    modality: null,
    systemBrokenFlag: false
  },
  {
    problemSeverityId: '30',
    problemSeverityDescription: 'Inquiry',
    modality: null,
    systemBrokenFlag: false
  },
  {
    problemSeverityId: '31',
    problemSeverityDescription: 'Urgent Message',
    modality: null,
    systemBrokenFlag: false
  },
  {
    problemSeverityId: '32',
    problemSeverityDescription: 'Non-Urgent Message',
    modality: null,
    systemBrokenFlag: false
  },
  {
    problemSeverityId: '33',
    problemSeverityDescription: 'Inquiry-Low Priority',
    modality: null,
    systemBrokenFlag: false
  },
  {
    problemSeverityId: '5',
    problemSeverityDescription: 'In Progress- System Down',
    modality: null,
    systemBrokenFlag: false
  },
  {
    problemSeverityId: '6',
    problemSeverityDescription: 'In Progress- Partially Down',
    modality: null,
    systemBrokenFlag: false
  },
  {
    problemSeverityId: '7',
    problemSeverityDescription: 'In Progress- Scheduled Service',
    modality: null,
    systemBrokenFlag: false
  },
  {
    problemSeverityId: '8',
    problemSeverityDescription: 'Pending Closure',
    modality: null,
    systemBrokenFlag: false
  },
  {
    problemSeverityId: '9',
    problemSeverityDescription: 'Completed',
    modality: null,
    systemBrokenFlag: false
  }
];
