<form [formGroup]="myFiltersOverallSwitchForm" class="toggle-switch my-equipment-switch">
  <input type="checkbox"
         id="overall-toggle"
         class="toggle-switch__checkbox my-equipment-switch__checkbox" 
         formControlName="overallSwitch"
         data-cy="header-my-filter-switch">
  <label class="toggle-switch__label my-equipment-switch__label">
    <div>
      <a (click)="navigateToMyFilters()" class="widget__link" data-cy="header-my-filters-link">
        <i class="icon-arrow-right-medium"></i>
        <span translate="LABEL_MY_FILTERS_SETTINGS_MENU" class="my-equipment-switch__description"></span>
      </a>
    </div>
  </label>
</form>
