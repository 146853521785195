import { WindowService } from '../core/window.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../environments/environment.prod';
import { EnvironmentConfigRestService } from '../core/rest-services/environment-config-rest.service';
import { CountryConfigRestService } from '../core/rest-services/country-config-rest.service';
import { FrontPageComponent } from '../front-pages/front-page.component';

@Component({
  selector: 'hl-welcome',
  templateUrl: './welcome.component.html'
})
export class WelcomeComponent implements OnInit {
  @ViewChild(FrontPageComponent) frontPage: FrontPageComponent;

  registerLink: string;
  welcomePageBgImageLink = environment.version + 'assets/images/welcome-bg.png';
  welcomePageBgImageStyle = 'url("' + this.welcomePageBgImageLink + '")';

  private window = this.windowService.nativeWindow;

  constructor(private windowService: WindowService,
    private environmentConfigRestService: EnvironmentConfigRestService,
    private configService: CountryConfigRestService) {
  }

  ngOnInit() {
    this.configService.getConfig().subscribe((config) => {
      // register link
      this.registerLink = config.LINK_REGISTER_WELCOME;
    });
  }

  redirectLogin() {
    // redirect to login page
    this.environmentConfigRestService.getEnvironmentConfig().subscribe((configResponse) => {
      this.window.location.href = configResponse.LOGIN_URL;
    });
  }

  redirectRegister() {
    this.frontPage.redirectRegister();
  }

}
