import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ImpersonationCommunicationService } from './core/component-communication-services/impersonation/impersonation-communication.service';
import { combineLatest, Subscription } from 'rxjs';
import { UserRestService } from './core/rest-services/user-rest.service';
import { UserUtilService } from './core/services/user/user-util.service';
import { CountryConfigRestService } from './core/rest-services/country-config-rest.service';
import { BaseMainNavRoleCheckDirective } from './core/base-class/base-main-nav-role-check';
import { EquipmentRestService } from './core/rest-services/equipment-rest.service';
import { StateService } from './core/services/state.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LifeNetUtilService } from './core/utils/life-net-util.service';
import { BrowserStateService } from './core/services/browser-state.service';
import { ServiceMetricsRestService } from './core/rest-services/service-metrics-rest.service';
import { TicketsUtilService } from './core/services/tickets/tickets-util.service';
import { filter, take, takeUntil } from 'rxjs/operators';
import { CheckPermissionOrRoleService } from './core/auth-guards/check-permission-or-role.service';
import { CreateTicketComponent } from './tickets/create-ticket/create-ticket.component';
import { ReportingModalComponent } from './shared/modal-popup/reporting-modal/reporting-modal.component';
import { AuthorizationService } from './core/auth-guards/authorization.service';

@Component({
  selector: 'hl-main-nav-group',
  templateUrl: './main-nav-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainNavGroupComponent extends BaseMainNavRoleCheckDirective
  implements OnInit, OnDestroy, AfterViewChecked {

  @ViewChild('createTicketModal') createTicketModal: CreateTicketComponent;
  @ViewChild('reportModal') reportModal: ReportingModalComponent;

  authorization: AuthorizationService;

  isMobileView = false;
  selectedEquipment: string;
  splitUrl = [];

  // subscription
  showCreateTicketModalSubscription: Subscription;
  openCreateTicketModal = false;
  arePermissionsLoaded = false;
  isNavbarOpen = false;
  isEvolutionGroupLoaded = false;

  showDigitalCompassTab = false;
  showPSRTab = false;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setMobileView();
    this.isNavbarOpen = false;
  }

  @HostListener('document:click.out-zone', ['$event'])
  clickout(event) {
    if (this.isNavbarOpen && (event.target.classList.contains('tabs__item') ||
      event.target.classList.contains('menu__item'))) {
      this.isNavbarOpen = false;
    }
  }

  constructor(
    configService: CountryConfigRestService,
    userRestService: UserRestService,
    userUtilService: UserUtilService,
    metricsService: ServiceMetricsRestService,
    router: Router,
    state: StateService,
    equipmentRestService: EquipmentRestService,
    lifeNetUtilService: LifeNetUtilService,
    private route: ActivatedRoute,
    private impersonationCommunicationService: ImpersonationCommunicationService,
    private ticketsUtilService: TicketsUtilService,
    browserStateService: BrowserStateService,
    cdr: ChangeDetectorRef,
    checkPermissionOrRoleService: CheckPermissionOrRoleService
  ) {
    super(
      configService,
      userRestService,
      userUtilService,
      metricsService,
      router,
      state,
      equipmentRestService,
      lifeNetUtilService,
      browserStateService,
      cdr,
      checkPermissionOrRoleService
    );
    this.authorization = checkPermissionOrRoleService.authorizationService;
  }

  ngOnInit() {
    this.setMobileView();
    // all initialization is done in Base Class
    this.init();
    // get selected equipment to select correct equipment in create ticket
    this.setPropertiesFromURLQuery();
    // Note:- Only one time event listeners be registered
    this.registerEventListeners();

    this.registerListenerForLoadingRolePermission();
  }

  setPropertiesFromURLQuery() {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      takeUntil(this.unsubscribe$)
    ).subscribe((event: NavigationEnd) => {
      this.setSelectedEquipment(event.url);
    });

    const ticketRole$ = this.checkPermissionOrRoleService.authorizationService.createTicketRole$;
    combineLatest([this.route.queryParams, ticketRole$, this.equipmentRestService.getEquipment()])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([params, createTicketRole, equipments]) => {
        const createTicketModal = params['createTicket'];
        const equipmentIdentifier = params['equipmentIdentifier'];
        if (!createTicketModal || !createTicketRole) {
          return;
        }
        this.openCreateTicketModal = true;
        if (equipmentIdentifier) {
          const equipment = equipments.find(eq => eq.siemensId === equipmentIdentifier);

          if (equipment) {
            this.selectedEquipment = equipment.key;
            this.cdr.detectChanges();
            this.createTicketModal.preselectEquipmentInDeeplink(this.selectedEquipment);
          }
        }
        this.tryOpenCreateTicketModal();
      });
  }

  setSelectedEquipment(url: string) {
    this.splitUrl = url.split('/');
    if (this.splitUrl.length > 2 && this.splitUrl[1] === 'equipment') {
      this.selectedEquipment = this.splitUrl[2];
      this.cdr.detectChanges();
    } else {
      this.selectedEquipment = '';
      this.cdr.detectChanges();
    }
    if (this.openCreateTicketModal && this.selectedEquipment) {
      this.createTicketModal.preselectEquipmentInDeeplink(this.selectedEquipment);
    }
  }

  tryOpenCreateTicketModal() {
    if (this.openCreateTicketModal) {
      try {
        this.createTicketModal.show();
        this.openCreateTicketModal = false;
      } catch (e) {
      }
    }
  }

  private registerListenerForLoadingRolePermission(): void {
    this.checkPermissionOrRoleService.isLoadedSubject.pipe(takeUntil(this.unsubscribe$)).subscribe(isLoaded => {
      if (isLoaded) {
        this.loadEvolutionGroup();
      }

      this.arePermissionsLoaded = isLoaded;
    });
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    if (this.showCreateTicketModalSubscription) {
      this.showCreateTicketModalSubscription.unsubscribe();
    }
    this.checkPermissionOrRoleService.destroy();
    super.destroy();
  }

  /**
   *
   * @description
   * Register all events which are broad casted, emitted
   */
  private registerEventListeners() {
    this.impersonationCommunicationService.onCountryLanguageChange$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.checkPermissionOrRoleService.isLoaded = false;
        this.arePermissionsLoaded = false;
        this.init();
      }
    );

    this.stateService.getActiveRouteTitle().subscribe(titleName => {
      if (titleName) {
        this.headerTranslateLabel = titleName;
      }
    });

    // BugFix 5156: avoid create ticket modal reappearing by adding 'take' function with '1' arg
    // in order to emit the event only once
    this.showCreateTicketModalSubscription = this.ticketsUtilService.onShowCreateTicketModal$.pipe(take(1)).subscribe(
      equipmentKey => {
        this.createTicketModal.showModalAndSetFields(equipmentKey);
      }
    );
  }

  private setMobileView() {
    this.isMobileView = window.innerWidth < 768;
  }

  isGroupTabActive(groupName: string): boolean {
    let stateNames = [];
    if (groupName === 'service') {
      stateNames = ['tickets', 'activities', 'appointments', 'contracts', 'reporting', 'metrics', 'service-performance-metrics', 'partnerOrders', 'invoices'];
    } else if (groupName === 'cybersecurity') {
      stateNames = ['security', 'advisories', 'updates', 'overview'];
    } else if (groupName === 'evolution') {
      stateNames = ['clinical-performance-companion', 'psr'];
    }

    return stateNames.some(name => name === this.currentBaseStateName);
  }

  toggleNavbar() {
    this.isNavbarOpen = !this.isNavbarOpen;
  }

  loadEvolutionGroup() {
    const { showDigitalCompassTab, showPSRTab,  } = this.checkPermissionOrRoleService.authorizationService;
    combineLatest([showDigitalCompassTab, showPSRTab])
      .pipe(take(1))
      .subscribe(([showDigitalCompassTab, showPSRTab]) => {
        this.isEvolutionGroupLoaded = false;
        this.showDigitalCompassTab = showDigitalCompassTab;
        this.showPSRTab = showPSRTab;
        this.isEvolutionGroupLoaded = true;
      });
  }
}
