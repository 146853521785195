import { HttpErrorResponse } from '@angular/common/http';
import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { isEqual } from 'lodash-es';
import moment from 'moment';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil, withLatestFrom } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { ConfigLoaderService } from './config-loader.service';
import { AnnouncementLocalStorageService } from './core/announcement-local-storage.service';
import { AddRootClassesService } from './core/component-communication-services/add-root-classes/add-root-classes.service';
import { addedRootClasses, localeSplitter } from './core/core-constants.service';
import { User } from './core/models/user';
import { BusinessEventsRestService } from './core/rest-services/business-events-rest.service';
import { CountryConfigRestService } from './core/rest-services/country-config-rest.service';
import { EnvironmentConfigRestService } from './core/rest-services/environment-config-rest.service';
import { UserAuditTrailRestService } from './core/rest-services/user-audit-trail-rest.service';
import { UserRestService } from './core/rest-services/user-rest.service';
import { EquipmentStatusWebsocketService } from './core/services/equipment/equipment-status-websocket.service';
import { MarketingConsentService } from './core/services/marketing-consent.service';
import { TicketsWebsocketService } from './core/services/tickets/tickets-websocket.service';
import { UserUtilService } from './core/services/user/user-util.service';
import { DomUtilService } from './core/utils/dom-util.service';
import { ImpersonationUtilsService } from './core/utils/impersonation-utils.service';
import { LifeNetUtilService } from './core/utils/life-net-util.service';
import { WalkMeUtilService } from './core/utils/walk-me-util.service';
import { WindowService } from './core/window.service';
import { HeaderComponent } from './header.component';
import { IcCodeModalComponent } from './shared/modal-popup/ic-code-modal/ic-code-modal.component';
import { IntroFeatureModalComponent } from './shared/modal-popup/intro-feature-modal/intro-feature-modal.component';
import { NotificationsSubscriptionModalComponent } from './shared/modal-popup/notifications-subscription-modal/notifications-subscription-modal.component';
import { TermsAndConditionModalComponent } from './shared/modal-popup/terms-and-condition-modal/terms-and-condition-modal.component';

@Component({
  selector: 'hl-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  @ViewChild('termsAndConditionModal') termsAndConditionModal: TermsAndConditionModalComponent;
  @ViewChild('icCodeModal') icCodeModal: IcCodeModalComponent;
  @ViewChild('introFeatureModal') introFeatureModal: IntroFeatureModalComponent;
  @ViewChild('notificationsSubscriptionModal') notificationsSubscriptionModal: NotificationsSubscriptionModalComponent;
  @ViewChild(HeaderComponent) headerComponent: HeaderComponent;
  private readonly unsubscribe$ = new Subject<void>();

  isAuthenticatedAndLoaded = false;

  translationFileLoaded = false;

  isInternalUser = false;

  readonly announcementLocalStorageService = inject(AnnouncementLocalStorageService);

  constructor(
    private translate: TranslateService,
    private addRootClassService: AddRootClassesService,
    private environmentConfigRestService: EnvironmentConfigRestService,
    private windowService: WindowService,
    private userUtilService: UserUtilService,
    private lifeNetUtilService: LifeNetUtilService,
    private impersonationUtilService: ImpersonationUtilsService,
    private route: ActivatedRoute,
    private auditTrailService: UserAuditTrailRestService,
    private countryConfigRestService: CountryConfigRestService,
    private configLoaderService: ConfigLoaderService,
    private authService: AuthService,
    private userRestService: UserRestService,
    private walkMeUtilService: WalkMeUtilService,
    private ticketsWebsocketService: TicketsWebsocketService,
    private equipmentStatusWebsocketService: EquipmentStatusWebsocketService,
    private businessEventsRestService: BusinessEventsRestService,
    private marketingConsentService: MarketingConsentService
  ) {
    console.log('to be removed');
  }

  private static shouldShowNotificationDialogForThisCountry(config) {
    return !config.SUBPROCESSOR_FEATURE_AVAILABLE || isEqual(config.SUBPROCESSOR_FEATURE_AVAILABLE, 'true');
  }

  ngOnInit() {
    this.marketingConsentService.initMarketingConsent();

    this.authService.isUserAuthenticated.pipe(takeUntil(this.unsubscribe$))
      .subscribe(isAuth => {
        if (isAuth) {
          // this language will be used as a fallback when a translation isn't found in the current language
          let locale = this.configLoaderService.getDefaultTranslateFile();
          this.translate.setDefaultLang(locale);
          this.windowService.setPopupsAllowed(true);
          combineLatest([
            this.authService.lastAuthenticatedUser,
            this.environmentConfigRestService.getEnvironmentConfig()])
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
              next: ([userResponse, configResponse]) => {
                this.isInternalUser = userResponse.isInternalUser;
                if (userResponse.country && userResponse.language && userResponse.countries) {
                  locale =
                    this.impersonationUtilService.getLocaleWithFallbackCheck(userResponse.language,
                      userResponse.country, userResponse.countries);
                  this.lifeNetUtilService.setConfigurationFile(userResponse.country);
                  this.lifeNetUtilService.setTranslationFile(locale);
                  locale = this.configLoaderService.getTranslateFile(locale);

                  this.isAuthenticatedAndLoaded = true;
                  this.addRootClassService.emitToAddRootClass(addedRootClasses.authorized);
                  this.showWalkMe(userResponse);
                }

                moment.locale(userResponse.language);

                this.translate.getTranslation(locale).pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
                  this.translationFileLoaded = true;
                  this.checkTermsNCondition(userResponse);
                });
                this.checkIfIcCodeOrLifenetSettingFragmentsWasLoaded();

                this.windowService.consoleLogEnabled = configResponse.FRONTEND_DEBUG || false;

                this.setupWebsocketWatchers();
              },
              error: (error: HttpErrorResponse) => {
                // to handle case when user is authenticated but not authorized
                if (isEqual(error.status, 403)) {
                  this.isAuthenticatedAndLoaded = true;
                }
              }
            });
        } else {
          this.userRestService.getUser().pipe(takeUntil(this.unsubscribe$)).subscribe((user) => {
            if (user) {
              this.authService.setAuthenticatedUser(user);
            }
          });
        }
      });
  }

  private setupWebsocketWatchers() {
    this.equipmentStatusWebsocketService.activateWebsocketEquipmentStatusIfToggleIsActive();
    this.ticketsWebsocketService.activateWebsocketTicketsStatus();
  }

  showWalkMe(userResponse: User) {
    const locale =
      this.impersonationUtilService.getLocaleWithFallbackCheck(userResponse.language,
        userResponse.country, userResponse.countries);

    // Those three lines are important for case when some en locale for country is missing, and en_GLN locale is used.
    const localeSplit = locale.split(localeSplitter);
    const languageCode = localeSplit[0];
    const languageCountryCode = localeSplit[1];
    window['gid'] = userResponse.gid;
    this.walkMeUtilService.setLanguageAndCountry(window, languageCode, languageCountryCode);
    DomUtilService.replaceUnsupportedFont(languageCode);
  }

  logout() {
    this.isAuthenticatedAndLoaded = false;
    this.announcementLocalStorageService.deleteAllAnnouncementVariables();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Check for terms and condition modal
   */
  checkTermsNCondition(user: User) {
    if (user.termsAccepted) {
      this.addRootClassService.emitToAddRootClass(addedRootClasses.termsAndConditions);
      this.showIntroModal();
    } else {
      this.termsAndConditionModal.show();
    }
  }

  checkIfIcCodeOrLifenetSettingFragmentsWasLoaded() {
    this.route.fragment.subscribe((fragment: string) => {
      if (fragment) {
        if (this.icCodeModal.shouldShow(fragment)) {
          this.icCodeModal.show();
        }

        if (fragment.match('lifenetSettings') && this.headerComponent && !this.headerComponent.userSettingsOverlay.isShown) {
          this.headerComponent.navigate();
        }
      }
    });
  }

  showIntroModal() {
    this.userUtilService.showIntroModal().pipe(takeUntil(this.unsubscribe$)).subscribe(response => {
      if (response) {
        this.introFeatureModal.show();
      } else {
        this.showNotificationsSubscription();
      }
    });
  }

  showNotificationsSubscription() {
    if (this.isAuthenticatedAndLoaded) {
      this.auditTrailService.showNotificationsSubscription()
        .pipe(
          withLatestFrom(this.countryConfigRestService.getConfig()),
          takeUntil(this.unsubscribe$)
        )
        .subscribe(([auditTrail, config]) => {
          if (AppComponent.shouldShowNotificationDialogForThisCountry(config) && !auditTrail.initialMyNotificationsShown) {
            this.notificationsSubscriptionModal.showNotificationsOverlay = true;
            this.notificationsSubscriptionModal.show();
          } else if (!this.isInternalUser && isEqual(config.FEATURE_TOGGLE_MARKETING_CONSENT, 'true')) {
            this.checkUserMarketingConsent();
          }
        });
    }
  }

  checkUserMarketingConsent() {
    this.businessEventsRestService.getMarketingConsent().pipe(takeUntil(this.unsubscribe$)).subscribe((isMarketingConsent) => {
      if (!isMarketingConsent) {
        this.notificationsSubscriptionModal.showNotificationsOverlay = true;
        this.notificationsSubscriptionModal.show();
      }
    });

  }
}
